import { theme } from 'theme'

export const userWalletSVG = (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 10.5C17.5 10.0027 17.3025 9.52581 16.9508 9.17417C16.5992 8.82254 16.1223 8.625 15.625 8.625H12.5C12.5 9.28804 12.2366 9.92393 11.7678 10.3928C11.2989 10.8616 10.663 11.125 10 11.125C9.33696 11.125 8.70107 10.8616 8.23223 10.3928C7.76339 9.92393 7.5 9.28804 7.5 8.625H4.375C3.87772 8.625 3.40081 8.82254 3.04917 9.17417C2.69754 9.52581 2.5 10.0027 2.5 10.5M17.5 10.5V15.5C17.5 15.9973 17.3025 16.4742 16.9508 16.8258C16.5992 17.1775 16.1223 17.375 15.625 17.375H4.375C3.87772 17.375 3.40081 17.1775 3.04917 16.8258C2.69754 16.4742 2.5 15.9973 2.5 15.5V10.5M17.5 10.5V8M2.5 10.5V8M17.5 8C17.5 7.50272 17.3025 7.02581 16.9508 6.67417C16.5992 6.32254 16.1223 6.125 15.625 6.125H4.375C3.87772 6.125 3.40081 6.32254 3.04917 6.67417C2.69754 7.02581 2.5 7.50272 2.5 8M17.5 8V5.5C17.5 5.00272 17.3025 4.52581 16.9508 4.17417C16.5992 3.82254 16.1223 3.625 15.625 3.625H4.375C3.87772 3.625 3.40081 3.82254 3.04917 4.17417C2.69754 4.52581 2.5 5.00272 2.5 5.5V8"
      stroke={theme.colors.darkGray}
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export const userWalletDisabledSVG = (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.69688 5.1008C1.67731 5.23216 1.66732 5.36555 1.66732 5.49996V15.5C1.66732 16.2183 1.95266 16.9071 2.46057 17.415C2.96848 17.923 3.65736 18.2083 4.37565 18.2083H14.8044L13.1377 16.5416H4.37565C4.09938 16.5416 3.83443 16.4319 3.63908 16.2365C3.44373 16.0412 3.33399 15.7762 3.33399 15.5V10.5C3.33399 10.2237 3.44373 9.95874 3.63908 9.76339C3.83443 9.56804 4.09938 9.45829 4.37565 9.45829H6.05437L4.38771 7.79163H4.37565C4.01458 7.79163 3.66094 7.86373 3.33399 7.99996C3.33399 7.72369 3.44373 7.45874 3.63908 7.26339C3.6762 7.22628 3.71582 7.19225 3.75756 7.16148L1.69688 5.1008ZM11.596 9.10735C11.6429 8.9523 11.6673 8.78989 11.6673 8.62496C11.6673 8.16472 12.0404 7.79163 12.5007 7.79163H15.6257C15.9867 7.79163 16.3404 7.86373 16.6673 7.99996C16.6673 7.72369 16.5576 7.45874 16.3622 7.26339C16.1669 7.06804 15.9019 6.95829 15.6257 6.95829H9.44693L7.78026 5.29163H15.6257C15.9867 5.29163 16.3404 5.36373 16.6673 5.49996C16.6673 5.22369 16.5576 4.95874 16.3622 4.76339C16.1669 4.56804 15.9019 4.45829 15.6257 4.45829H6.94693L5.28026 2.79163H15.6257C16.3439 2.79163 17.0328 3.07697 17.5407 3.58488C18.0486 4.09279 18.334 4.78166 18.334 5.49996V15.5C18.334 15.6094 18.3274 15.7182 18.3143 15.8257L16.6673 14.1787V10.5C16.6673 10.2237 16.5576 9.95874 16.3622 9.76339C16.1669 9.56804 15.9019 9.45829 15.6257 9.45829H13.2281C13.1462 9.77546 13.018 10.079 12.8475 10.3589L11.596 9.10735Z"
      fill={theme.colors.softBlack}
      fill-opacity="0.3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.32806 1.7857C1.00263 2.11114 1.00263 2.63878 1.32806 2.96421L17.1614 18.7975C17.4868 19.123 18.0145 19.123 18.3399 18.7975C18.6653 18.4721 18.6653 17.9445 18.3399 17.619L2.50657 1.7857C2.18114 1.46027 1.6535 1.46027 1.32806 1.7857Z"
      fill={theme.colors.softBlack}
      fill-opacity="0.3"
    />
  </svg>
)

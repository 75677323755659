import { styled, theme } from '../theme'

interface InputProps {
  circularBorder?: boolean
  isCombineRight?: boolean
  isCombineLeft?: boolean
  isDesktop?: boolean
  isLaptop?: boolean
}

export const SingleSelectorWrapper = styled.div<InputProps>`
  .ant-input-affix-wrapper {
    line-height: 26px;
    font-size: 13px;
    font-weight: 400;
    ${(props) => props.isDesktop && !props.isLaptop && `border-left-color: transparent;`}
     ${(props) => !props.isDesktop && props.isLaptop && `border-left-color: transparent;`}
     ${(props) => !props.isDesktop && !props.isLaptop && `border-left-color: theme.colors.lightGray;`}
    border-right-color: ${(props) =>
      props.isCombineRight || (!props.isDesktop && !props.isLaptop) ? props.theme.colors.lightGray : 'transparent'};
    border-left-color: ${(props) =>
      props.isCombineLeft || (!props.isDesktop && !props.isLaptop) ? props.theme.colors.lightGray : 'transparent'};
    box-shadow: none;
    border-radius: 0;

    ${(props) =>
      (props.circularBorder || props.isCombineRight) && !props.isDesktop && !props.isLaptop && `border-radius: 48px;`}
      ${(props) => props.isCombineRight && `border-radius: 0 48px 48px 0`};
      ${(props) => props.isCombineLeft && `border-radius: 48px 0 0 48px`};
      ${(props) => !props.isDesktop && !props.isLaptop && `border-radius: 48px;`}
  }

  .ant-input-affix-wrapper:hover {
    border-color: ${theme.colors.primary};
  }

  .ant-input-affix-wrapper-focused:hover,
  :focus,
  :focus-within,
  :active {
    border-color: ${theme.colors.primary};
  }

  .ant-input:focus,
  .ant-input:active {
    border-left-width: 1px;
    border-color: ${theme.colors.primary};
    box-shadow: none !important;
  }

  .ant-input {
    border-radius: 0px;
  }

  .ant-input-affix-wrapper > .ant-input-suffix > .ant-input-clear-icon > .anticon-close-circle > svg {
    width: 12px;
    height: 12px;
    margin-bottom: -1px;
  }
`

export const TextAreaWrapper = styled.div`
  textarea {
    border-radius: 9px !important;
  }
`

export const InputWrapper = styled.div`
  .ant-input {
    border-radius: 20px;
    min-height: 37px;
  }
`

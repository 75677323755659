import { Row } from 'antd'
import styled from 'styled-components'
import { theme } from 'theme'

export const BalanceTextWrapper = styled(Row)`
  align-items: flex-end;
  gap: 8px;
`

export const BulletPointWrapper = styled(Row)`
  gap: 8px;
`

export const Bullet = styled.div`
  width: 4px;
  height: 4px;
  margin-left: 4px;
  margin-top: 6px;
  background-color: ${theme.colors.darkGray};
  border-radius: 4px;
`
export const BulletPointText = styled.span`
  flex: 1;
  color: ${theme.colors.darkGray};
`

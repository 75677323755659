import { FileOutlined, SendOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Row, Table } from 'antd'
import { ChatContainer, ChatHistory, FileLink, InputContainer, LoadingDot, MessageBubble } from 'atom/report/ChatModal'
import configProvider from 'config'
import { createDownloadableCSV } from 'helpers/jsonToSCV'
import { useReportTranslation } from 'hooks/translation/useReportTranslation'
import { Message } from 'models/reports'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import CaseConverter from 'services/data-provider/case-converter'
import { GetReportThroughAI } from 'services/data-provider/reports'

interface ChatModalProps {
  onCancel: () => void
}
interface TypewriterProps {
  text: string
  delay: number
}

const Typewriter: React.FC<TypewriterProps> = ({ text, delay }) => {
  const [currentText, setCurrentText] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)

  // Typing logic goes here
  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex])
        setCurrentIndex((prevIndex) => prevIndex + 1)
      }, delay)

      return () => clearTimeout(timeout)
    }
  }, [currentIndex, delay, text])

  return <span dangerouslySetInnerHTML={{ __html: currentText }} />
}

export const ChatModal: React.FC<ChatModalProps> = ({ onCancel }) => {
  const {
    chatTitle,
    chatTypePlaceholder,
    chatFailedText,
    chatNoDataText,
    chatSomethingWentWrong,
    chatPreviewText,
    chatIntroText,
  } = useReportTranslation()
  const [messages, setMessages] = useState<Message[]>([
    {
      data: chatIntroText,
      isUser: false,
      timestamp: new Date(),
      success: true,
    },
  ])
  const [inputText, setInputText] = useState('')
  const chatHistoryRef = useRef<HTMLDivElement>(null)
  const [activeLoadingDot, setActiveLoadingDot] = useState(0)

  const [messageLoading, setMessageLoading] = useState(false)

  const typeDelay = 30
  const previewRows = 5

  // Scroll to bottom whenever messages change
  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight
    }
  }, [messages])

  // Animation for the loading dots
  useEffect(() => {
    let interval: NodeJS.Timeout

    if (messageLoading) {
      interval = setInterval(() => {
        setActiveLoadingDot((prevDot) => (prevDot + 1) % 3)
      }, 500)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [messageLoading])

  const handleSend = async () => {
    try {
      if (!inputText.trim()) return

      const newMessage: Message = {
        data: inputText,
        success: true,
        isUser: true,
        timestamp: new Date(),
      }

      setMessages((prev) => [...prev, newMessage])
      setInputText('')

      // API call to get the response from the AI
      setMessageLoading(true)
      const responseFromAI = await GetReportThroughAI(inputText, configProvider.config.mockEnabled)

      // let response: Message = {
      //   ...responseFromAI,
      //   data: responseFromAI.data as string,
      //   isUser: false,
      //   timestamp: new Date(),
      // }

      let response: Message[] = []

      if (responseFromAI.data.length === 0 || !responseFromAI.data) {
        response.push({
          ...responseFromAI,
          data: chatNoDataText,
          isUser: false,
          timestamp: new Date(),
        })
      } else if (responseFromAI.isFile) {
        const convertedData = CaseConverter.convertArrayToTitleCase(responseFromAI.data as any[])
        if (responseFromAI.data.length > 1) {
          const csvUrl = createDownloadableCSV(convertedData)
          response = [
            {
              data: chatPreviewText,
              isFile: false,
              isUser: false,
              success: true,
              timestamp: new Date(),
            },
            {
              data: convertedData,
              isFile: false,
              isUser: false,
              isTable: true,
              success: true,
              timestamp: new Date(),
            },
            {
              ...responseFromAI,
              data: csvUrl,
              isFile: true,
              isUser: false,
              timestamp: new Date(),
            },
          ]
        } else if (responseFromAI.data.length === 1) {
          if (Object.keys(convertedData[0]).length > 1) {
            response = [
              {
                data: chatPreviewText,
                isFile: false,
                isUser: false,
                success: true,
                timestamp: new Date(),
              },
              {
                data: convertedData,
                isFile: false,
                isUser: false,
                isTable: true,
                success: true,
                timestamp: new Date(),
              },
            ]
          } else {
            response.push({
              ...responseFromAI,
              data: Object.keys(convertedData[0])
                .map((key) => `${key}: ${convertedData[0][key]}`)
                .join(', '),
              isFile: false,
              isUser: false,
              timestamp: new Date(),
            })
          }
        }
      } else {
        response.push({
          ...responseFromAI,
          data: responseFromAI.data as string,
          isUser: false,
          timestamp: new Date(),
        })
      }
      setMessages((prev) => [...prev, ...response])

      const fileName = `${configProvider.config.network.toUpperCase()}_Report_${moment().format(
        'YYYY-MM-DD_HH-mm-ss',
      )}.csv`

      const lastMessage = response[response.length - 1]
      let delayTime = (lastMessage.isFile ? fileName.length : lastMessage.data.length) * typeDelay
      if (responseFromAI.sql_query) {
        setTimeout(() => {
          setMessages((prev) => [
            ...prev,
            {
              data: responseFromAI.sql_query || '',
              isFile: false,
              success: true,
              isUser: false,
              timestamp: new Date(),
            },
          ])
        }, delayTime)
      }
      // If the response is not successful, add a message to the chat history
      if (!responseFromAI.success) {
        let failedDelay = chatFailedText.length * typeDelay
        setTimeout(() => {
          setMessages((prev) => [
            ...prev,
            ...response,
            {
              data: chatFailedText,
              success: false,
              isUser: false,
              timestamp: new Date(),
            },
          ])
        }, delayTime + (responseFromAI.sql_query ? failedDelay : 0))
      }
    } catch (e) {
      console.error(e)
      setMessages((prev) => [
        ...prev,
        {
          data: chatSomethingWentWrong,
          success: false,
          isUser: false,
          timestamp: new Date(),
        },
      ])
    } finally {
      setMessageLoading(false)
    }
  }

  const renderMessageContent = (message: Message, isUser: boolean) => {
    if (message.isTable) {
      return (
        <Table
          columns={Object.keys(message.data[0]).map((key) => ({
            title: key,
            dataIndex: key,
            key: key,
          }))}
          dataSource={(message.data as any[]).slice(0, previewRows)}
          size="small"
          pagination={false}
          className="preview-table"
        />
      )
    }
    if (message.isFile && message.data) {
      const fileName = `${configProvider.config.network.toUpperCase()}_Report_${moment().format(
        'YYYY-MM-DD_HH-mm-ss',
      )}.csv`
      return (
        <FileLink href={message.data as string} target="_blank" rel="noopener noreferrer" download={fileName}>
          <FileOutlined />
          {isUser ? fileName : <Typewriter text={fileName} delay={typeDelay} />}
        </FileLink>
      )
    }
    return isUser ? message.data : <Typewriter text={(message.data as string) || ''} delay={typeDelay} />
  }

  return (
    <Modal
      title={<div className="heading-05-regular">{chatTitle}</div>}
      closable={true}
      visible={true}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ padding: '16px 24px' }}
      className="remove-bottom-border"
      width={600}
    >
      <ChatContainer>
        <ChatHistory ref={chatHistoryRef}>
          {messages.map((message, index) => (
            <MessageBubble key={index} isUser={message.isUser} isTable={message.isTable}>
              {renderMessageContent(message, message.isUser)}
            </MessageBubble>
          ))}
          {messageLoading && (
            <MessageBubble isUser={false}>
              <Row align="middle" justify="center" style={{ gap: '8px', paddingTop: 2, paddingBottom: 2 }}>
                {[0, 1, 2].map((dotIndex) => (
                  <LoadingDot key={dotIndex} active={dotIndex === activeLoadingDot} />
                ))}
              </Row>
            </MessageBubble>
          )}
        </ChatHistory>

        <InputContainer>
          <Input
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onPressEnter={handleSend}
            placeholder={chatTypePlaceholder}
            suffix={
              <Button
                type="primary"
                disabled={!inputText.trim() || messageLoading}
                icon={<SendOutlined />}
                onClick={handleSend}
              />
            }
          />
        </InputContainer>
      </ChatContainer>
    </Modal>
  )
}

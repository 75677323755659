import { useState } from 'react'
import { Button, Form, Input, Modal, Select } from 'antd'
import { HeadingWrapper, ModalBodyWrapper } from '../../../atom/plans'
import { FooterLayout } from '../../../atom/user-edit'
import { InputWrapper } from '../../../atom/chargers'
import { MasterAccountRef } from '../../../models/master-account'
import { TenantAccount } from '../../../models/tenant'
import { useTenantTranslation } from '../../../hooks/translation/useTenantTranslation'
import { theme } from '../../../theme'
import { useGeneralTranslation } from '../../../hooks/translation/useGeneralTranslation'
import { useUserTranslation } from '../../../hooks/translation/useUserTranslation'

interface props {
  account: MasterAccountRef | undefined
  remittanceEmails: string[] | undefined
  remittanceFrequency: string | null | undefined
  onCancel: () => void
  setRemittanceEmails: (remittanceEmails: string[]) => void
  setRemittanceFrequency: (remittanceFrequency: string) => void
  setMasterAccount: (masterAccount: MasterAccountRef | undefined) => void
}

const EditAccountInfoModal: React.FC<props> = ({
  account,
  remittanceEmails,
  remittanceFrequency,
  onCancel,
  setRemittanceEmails,
  setRemittanceFrequency,
  setMasterAccount,
}) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const { cancelText, confirmText } = useGeneralTranslation()
  const { noneText } = useUserTranslation()
  const {
    accountText,
    remittanceEmailText,
    remittanceFrequencyText,
    editAccountInfoText,
    commaDelimitedText,
    monthlyText,
    quarterlyText,
    annuallyText,
    noteText,
  } = useTenantTranslation()

  const afterClose = () => form.resetFields()

  const onOk = () => {
    setLoading(true)
    if (account) {
      form.validateFields().then((values: TenantAccount) => {
        const { accountName, remittanceEmails, remittanceFrequency } = values
        const remittanceEmailsArr = remittanceEmails.split(',')
        console.log('remittanceEmails', accountName, remittanceEmails, remittanceEmailsArr, remittanceFrequency)
        const masterAccountObj: MasterAccountRef = {
          id: account.id,
          name: accountName,
          remittanceEmails: remittanceEmailsArr,
          remittanceFrequency: remittanceFrequency,
        }
        setRemittanceEmails(remittanceEmailsArr)
        setRemittanceFrequency(remittanceFrequency)
        setMasterAccount(masterAccountObj)
        setLoading(false)
        onCancel()
      })
    } else {
      setLoading(false)
      onCancel()
    }
  }

  const initialValues = {
    accountName: account?.name,
    remittanceEmails: remittanceEmails?.join(','),
    remittanceFrequency: remittanceFrequency,
  }

  return (
    <Modal
      title={<HeadingWrapper>{editAccountInfoText}</HeadingWrapper>}
      visible={true}
      onCancel={onCancel}
      onOk={onOk}
      width={569}
      confirmLoading={loading}
      afterClose={afterClose}
      footer={
        <FooterLayout>
          <Button key="back" loading={loading} onClick={onCancel}>
            {cancelText}
          </Button>
          <Button key="submit" type="primary" loading={loading} onClick={onOk}>
            {confirmText}
          </Button>
        </FooterLayout>
      }
    >
      <ModalBodyWrapper>
        <Form form={form} initialValues={initialValues}>
          <InputWrapper>
            <Form.Item
              name="accountName"
              label={accountText}
              rules={[{ required: false }]}
              style={{ marginLeft: '80px' }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="remittanceEmails"
              label={
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}>
                  <div>{remittanceEmailText}</div>
                  <div style={{ fontSize: '11px', color: theme.colors.grey5 }}>{commaDelimitedText}</div>
                </div>
              }
              rules={[{ required: false }]}
              style={{ marginLeft: '14px' }}
            >
              <Input.TextArea disabled={false} style={{ height: theme.space[5], marginBottom: '15px' }} />
            </Form.Item>
            <Form.Item name="remittanceFrequency" label={remittanceFrequencyText} rules={[{ required: false }]}>
              <Select allowClear>
                <Select.Option key="None" value="not_applicable" label="None">
                  {noneText}
                </Select.Option>
                <Select.Option key="Monthly" value="monthly" label="Monthly">
                  {monthlyText}
                </Select.Option>
                <Select.Option key="Quarterly" value="quarterly" label="Quarterly">
                  {quarterlyText}
                </Select.Option>
                <Select.Option key="Annually" value="annually" label="Annually">
                  {annuallyText}
                </Select.Option>
              </Select>
            </Form.Item>
            <p className="ant-form-item-explain" style={{ marginBottom: 0 }}>
              {noteText}
            </p>
          </InputWrapper>
        </Form>
      </ModalBodyWrapper>
    </Modal>
  )
}

export default EditAccountInfoModal

import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Row, Select, Space, Table } from 'antd'
import { editGreySVG } from 'assets/svg/edit'
import { trashcanSVG } from 'assets/svg/trashcan'
import { SelectionInfoContainer } from 'atom/box'
import { ButtonModal } from 'atom/button'
import { EditButtonWrapper } from 'atom/users-page-redesign-styles'
import { CancelModal } from 'components/modal/CancelModal'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { useNotifications } from 'hooks/useNotification'
import { useEffect, useState } from 'react'
import { CreateUserRFID, DeleteUserRFIDs, GetUserRFID, UpdateUserRFID } from 'services/data-provider/users'
import { useAppState } from 'state'
import { ButtonTextSpacing, FooterLayout, Heading } from '../../../atom/user-edit'
import { useUserTranslation } from '../../../hooks/translation/useUserTranslation'
import { SwtchError } from '../../../models/error'
import { AugmentedUser, RFID, RFIDRequest } from '../../../models/user'

interface props {
  selectedUser: AugmentedUser
  onCancel: () => void
}

export const UserRFIDForm: React.FC<props> = ({ onCancel, selectedUser }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const { saveText, updateText } = useUserTranslation()
  const { openSuccessNotification, openErrorNotification } = useNotifications()
  const { isMockUpEnabled } = useAppState()

  const afterClose = () => {
    form.resetFields()
  }
  const {
    rfidTitleText,
    rfidUserText,
    rfidAddRFIDText,
    rfidAddRFIDPlaceholderText,
    rfidAddText,
    rfidAssignedRFIDText,
    rfidRemoveRFIDText,
    rfidRemoveRFIDWarningText,
    rfidRemoveMultipleRFIDText,
    rfidRFIDremovedText,
    rfidSelectedText,
    rfidRFIDaddedText,
  } = useUserTranslation()

  const { removeText, cancelText, somethingWentWrongText } = useGeneralTranslation()

  const [showRemoveRFIDModal, setShowRemoveRFIDModal] = useState(false)
  const [selectedRFIDs, setSelectedRFIDs] = useState<string[]>([])

  const [rfidsToRemove, setRfidsToRemove] = useState<RFID[]>([])
  const [rfidsToModify, setRfidsToModify] = useState<RFID[]>([])

  const [addRFIDError, setAddRFIDError] = useState('')
  const [editRFIDError, setEditRFIDError] = useState<{ [id: string]: string }>({})

  const [editingRFID, setEditingRFID] = useState<{
    [id: string]: string | undefined
  }>({})

  const [showEditRFID, setShowEditRFID] = useState<{
    [id: string]: boolean
  }>({})
  const [rfids, setRfids] = useState<RFID[]>([])
  const [rfidSearch, setRfidSearch] = useState('')

  const disableSave =
    !Object.values(editingRFID).some((rfid) => rfid !== undefined) || Object.values(showEditRFID).some((rfid) => rfid)

  useEffect(() => {
    setRfidsToRemove(rfidsToModify)
  }, [rfidsToModify])

  useEffect(() => {
    if (selectedUser) {
      getRFID()
    }
  }, [selectedUser])

  const formatRFID = (rfid: string) => {
    return rfid.replaceAll(':', '')
  }

  const handleChange = (value: string[]) => {
    setAddRFIDError('')
    setRfidSearch('')
    setSelectedRFIDs(value.map(formatRFID))
  }

  const handleRFIDChange = (record: RFID, selected: boolean) => {
    if (selected) {
      setRfidsToModify([...rfidsToModify, record])
    } else {
      setRfidsToModify(rfidsToModify.filter((r) => r.id !== record.id))
    }
  }

  const handleRemoveRFIDModal = () => setShowRemoveRFIDModal(!showRemoveRFIDModal)

  const cancelModifyRFID = () => setRfidsToModify([])

  const removeSingleRFID = (rfid: RFID) => () => {
    setRfidsToRemove([rfid])
    handleRemoveRFIDModal()
  }

  const handleShowRFIDEdit = (id: number) => () => {
    setShowEditRFID({ ...showEditRFID, [id]: !showEditRFID[id] })
  }

  const handleRFIDEdit = (id: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingRFID({ ...editingRFID, [id]: e.target.value })
  }

  const cancelRFIDEdit = (id: number) => () => {
    setEditingRFID({ ...editingRFID, [id]: undefined })
    setEditRFIDError({ ...editRFIDError, [id]: undefined })
    setShowEditRFID({ ...showEditRFID, [id]: false })
  }

  const confirmRFIDEdit = (id: number) => () => {
    setShowEditRFID({ ...showEditRFID, [id]: false })
    setEditingRFID({ ...editingRFID, [id]: formatRFID(editingRFID[id] || '') })
  }

  const getRFID = async () => {
    try {
      setLoading(true)
      const rfids = await GetUserRFID(selectedUser.id, isMockUpEnabled)
      setRfids(rfids)
      //Remove rfids that are already assigned to the user
      let remainingRFIDS = [...selectedRFIDs]
      rfids.forEach(({ rfidNumber }) => {
        remainingRFIDS = remainingRFIDS.filter((rfid) => rfid !== rfidNumber)
      })
      setSelectedRFIDs(remainingRFIDS)
    } catch (error) {
      const errorMessage = (error as SwtchError)?.cause?.message || somethingWentWrongText
      openErrorNotification(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const updateRFID = async () => {
    try {
      setLoading(true)
      const response = await UpdateUserRFID(
        selectedUser.id,
        Object.entries(editingRFID)
          .map(([id, rfid_number]) => ({ id: Number(id), rfid_number }))
          .filter(({ rfid_number }) => rfid_number !== undefined) as RFIDRequest[],
        isMockUpEnabled,
      )
      openSuccessNotification(response.message)
      onCancel()
    } catch (error) {
      const errorMessage = (error as SwtchError)?.cause?.message || ''
      const errorMessages = errorMessage.split(', ')
      const regex = /RFID (.*?): /

      let showEditRFIDWithErrors: { [id: string]: boolean } = {}
      let editingRFIDWithErrors: { [id: string]: string } = {}

      errorMessages.forEach((error) => {
        const match = error.match(regex)
        const rfidNumber = match?.[1]
        const rfidId = Object.keys(editingRFID).find((id) => editingRFID[id] === rfidNumber)
        if (rfidNumber && rfidId) {
          showEditRFIDWithErrors[rfidId] = true
          editingRFIDWithErrors[rfidId] = error
        }
      })

      setRfids(
        rfids.map((rfid) => ({
          ...rfid,
          rfidNumber: !editingRFIDWithErrors[rfid.id] ? editingRFID[rfid.id] || rfid.rfidNumber : rfid.rfidNumber,
        })),
      )

      setShowEditRFID(showEditRFIDWithErrors)
      setEditRFIDError(editingRFIDWithErrors)
    } finally {
      setLoading(false)
    }
  }

  const removeRFIDs = async () => {
    try {
      setLoading(true)
      await DeleteUserRFIDs(
        selectedUser.id,
        rfidsToRemove.map(({ id }) => id),
        isMockUpEnabled,
      )
      openSuccessNotification(`${rfidsToRemove.length} ${rfidRFIDremovedText}`)
    } catch (error) {
      const errorMessage = (error as SwtchError)?.cause?.message || somethingWentWrongText
      openErrorNotification(errorMessage)
    } finally {
      setLoading(false)
      setRfidsToRemove([])
      setRfidsToModify([])
      handleRemoveRFIDModal()
      await getRFID()
    }
  }

  const addRFIDs = async () => {
    try {
      let rfidsToAdd = [...selectedRFIDs, rfidSearch].filter((rfid) => !!rfid)
      await CreateUserRFID(
        selectedUser.id,
        rfidsToAdd.map((rfid_number) => ({ rfid_number })),
        isMockUpEnabled,
      )
      openSuccessNotification(`${rfidsToAdd.length} ${rfidRFIDaddedText}`)
    } catch (error) {
      const errorMessage = (error as SwtchError)?.cause?.message || somethingWentWrongText
      setAddRFIDError(errorMessage)
    } finally {
      await getRFID()
    }
  }

  const columns = [
    {
      title: 'RFID',
      width: '80%',
      key: 'rfid',
      sorter: (a: any, b: any) => a.rfidNumber.localeCompare(b.rfidNumber),
      render: (record: RFID) =>
        showEditRFID[record.id] && !rfidsToModify.length ? (
          <Form.Item
            validateStatus={editRFIDError[record.id] ? 'error' : ''}
            help={editRFIDError[record.id] ?? undefined}
            className="editable-input-inside-table"
          >
            <Input
              value={editingRFID[record.id] || record.rfidNumber}
              onChange={handleRFIDEdit(record.id)}
              suffix={
                <Row>
                  <Button type="ghost" onClick={confirmRFIDEdit(record.id)} style={{ padding: '0px', height: 'unset' }}>
                    <CheckOutlined />
                  </Button>
                  <Button
                    type="ghost"
                    onClick={cancelRFIDEdit(record.id)}
                    style={{ padding: '0px', marginLeft: '10px', height: 'unset' }}
                  >
                    <CloseOutlined />
                  </Button>
                </Row>
              }
            />
          </Form.Item>
        ) : (
          editingRFID[record.id] || record.rfidNumber
        ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: RFID) => (
        <EditButtonWrapper style={{ gap: '10px' }} className={rfidsToModify.length ? 'disable-action-button' : ''}>
          <ButtonModal
            type="link"
            style={{ paddingLeft: '0px', paddingRight: '0px' }}
            icon={editGreySVG}
            disabled={!!rfidsToModify.length || showEditRFID[record.id]}
            click={handleShowRFIDEdit(record.id)}
            className={showEditRFID[record.id] ? 'disable-action-button' : ''}
          />
          <ButtonModal
            type="link"
            style={{ paddingLeft: '0px', paddingRight: '0px', marginRight: '10px' }}
            icon={trashcanSVG}
            disabled={!!rfidsToModify.length}
            click={removeSingleRFID(record)}
          />
        </EditButtonWrapper>
      ),
    },
  ]

  return (
    <Modal
      title={<Heading>{rfidTitleText}</Heading>}
      closable={true}
      okText={updateText}
      visible={true}
      onCancel={onCancel}
      afterClose={afterClose}
      destroyOnClose
      width={543}
      className="fixed-row-modal default-modal max-height-modal"
      footer={
        <FooterLayout justifyContent="flex-end">
          <Button
            style={{ width: '140px' }}
            type="primary"
            loading={loading}
            onClick={updateRFID}
            disabled={disableSave}
          >
            <ButtonTextSpacing disabled={disableSave}>
              <span className="paragraph-02-regular">{saveText}</span>
            </ButtonTextSpacing>
          </Button>
        </FooterLayout>
      }
    >
      <CancelModal
        visible={showRemoveRFIDModal}
        title={
          rfidsToRemove.length > 1
            ? `${rfidRemoveMultipleRFIDText.replace('{count}', rfidsToRemove.length.toString())}?`
            : `${rfidRemoveRFIDText} ${rfidsToRemove[0]?.rfidNumber}?`
        }
        description={rfidRemoveRFIDWarningText}
        onCancel={handleRemoveRFIDModal}
        onSubmit={removeRFIDs}
      />
      <Form form={form} layout="vertical" initialValues={{ email: selectedUser.email }}>
        <Form.Item
          name="email"
          className="disabled-placeholder-clear"
          label={<span className="heading-06-regular">{rfidUserText}</span>}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          validateStatus={addRFIDError ? 'error' : ''}
          help={addRFIDError ? addRFIDError.split(', ').map((error, i) => <div key={i}>{error}</div>) : undefined}
          label={<span className="heading-06-regular">{rfidAddRFIDText}</span>}
        >
          <Row style={{ gap: '10px' }}>
            <div style={{ flex: 1, maxWidth: 'calc(100% - 79px)' }}>
              <Select
                mode="tags"
                style={{ marginLeft: '0px' }}
                placeholder={rfidAddRFIDPlaceholderText}
                value={selectedRFIDs}
                onSearch={(value) => setRfidSearch(formatRFID(value))}
                onChange={handleChange}
                tokenSeparators={[',']}
                open={false}
                className="overflow-scroll"
              />
            </div>
            <Button type="primary" onClick={addRFIDs} disabled={!selectedRFIDs.length && !rfidSearch}>
              <span className="paragraph-02-regular">{rfidAddText}</span>
            </Button>
          </Row>
        </Form.Item>
        {!!rfidsToModify.length && (
          <SelectionInfoContainer align="middle" justify="space-between" style={{ display: loading ? 'none' : 'flex' }}>
            <Space>
              <span className="paragraph-01-regular">{`${rfidsToModify.length} ${rfidSelectedText}`}</span>
            </Space>
            <Space>
              <Button type="ghost" onClick={cancelModifyRFID}>
                {cancelText}
              </Button>
              <Button type="primary" onClick={handleRemoveRFIDModal}>
                {removeText}
              </Button>
            </Space>
          </SelectionInfoContainer>
        )}
        <Form.Item label={<span className="heading-06-regular">{`${rfids.length} ${rfidAssignedRFIDText}`}</span>}>
          {!!rfids.length && (
            <Table
              loading={loading}
              rowKey="id"
              className="sort-icon-close shadow-table shrink-row action-with-no-border editable-input"
              columns={columns}
              dataSource={rfids}
              rowSelection={{
                selectedRowKeys: rfidsToModify.map((r) => r.id),
                onSelect: handleRFIDChange,
                onChange: (_, rows) => setRfidsToModify(rows),
              }}
              pagination={false}
              style={{ marginBottom: '2rem' }} // to give space between tenants
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}

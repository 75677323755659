import { Bullet, BulletPointText, BulletPointWrapper } from 'atom/text'

interface BulletPointProps {
  value: string
}

const BulletPoint: React.FC<BulletPointProps> = ({ value }) => (
  <BulletPointWrapper>
    <Bullet />
    <BulletPointText>{value}</BulletPointText>
  </BulletPointWrapper>
)

export default BulletPoint

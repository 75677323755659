import { useState, useEffect, useCallback } from 'react'
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'
import { Modal, Spin, Select, Form, message } from 'antd'
import { GetConnectivityScore } from 'services/data-provider/listing'
import { ConnectivityScore } from 'models/listing'
import { Heading } from '../../atom/user-edit'
import { useChargerConnectivityTranslation } from '../../hooks/translation/useChargerConnectivityTranslation'

const ChargerConnectivityModal = ({ chargerId, isOpen, onClose }: any) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timezoneName = new Date()
    .toLocaleTimeString('en-US', {
      timeZoneName: 'short',
    })
    .split(' ')[2] // Extracts "EST", "MST", etc.

  const { connectivityPeriods, selectTimeframeText, timeText, scoreText, connectivityDetailsText } = useChargerConnectivityTranslation()

  const PERIOD_VALUES = ['last_1_hour', 'last_1_day', 'last_7_days', 'last_8_weeks']
  const [period, setPeriod] = useState('last_1_hour')
  const [connectivityData, setConnectivityData] = useState<ConnectivityScore[]>([])
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(async () => {
    if (!isOpen) return
    setLoading(true)
    try {
      const connectivityScores: ConnectivityScore[] = await GetConnectivityScore(chargerId, period, timezone)
      setConnectivityData(connectivityScores || []) // Ensures it's always an array
    } catch (error) {
      console.error('Error fetching connectivity score:', error)
      message.error('Failed to load connectivity data')
    } finally {
      setLoading(false)
    }
  }, [isOpen, period, chargerId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Modal
      title={<Heading>{connectivityDetailsText}</Heading>}
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      style={{ minHeight: '50%', minWidth: '50%' }}
    >
      <div className="flex items-center">
        <Form.Item label={selectTimeframeText} style={{ width: '20%' }}>
          <Select value={period} onChange={setPeriod} style={{ width: '100%' }}>
            {connectivityPeriods.map((label, index) => (
                <Select.Option key={index} value={PERIOD_VALUES[index]}>
                    {label}
                </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <Spin size="large" />
        </div>
      ) : (
        <div className="space-y-6">
          <div className="h-64">
            {connectivityData.length > 0 ? (
              <ResponsiveContainer minHeight={500} minWidth={290}>
                <LineChart data={connectivityData} margin={{ top: 20, right: 40, left: 60, bottom: 40 }}>
                  <XAxis
                    dataKey="period"
                    angle={-45}
                    textAnchor="end"
                    height={60}
                    interval={0}
                    tick={{ dy: 16 }}
                    label={{ value: `${timeText} (${timezoneName})`, offset: 10, position: 'insideTop' }}
                  />
                  <YAxis
                    domain={[0, 100]}
                    ticks={[0, 20, 40, 60, 80, 100]}
                    label={{ value: scoreText, angle: -90, position: 'insideLeft' }}
                  />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="connectivity_score"
                    stroke="#ccc"
                    strokeWidth={2}
                    dot={<CustomDot />}
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <p className="text-center text-gray-500">No connectivity data available.</p>
            )}
          </div>
        </div>
      )}
    </Modal>
  )
}

const CustomDot = (props: any) => {
  const { cx, cy, value } = props
  let fillColor = 'red' // Default to Weak Connectivity
  if (value >= 80) fillColor = 'green'
  // Strong
  else if (value >= 60) fillColor = 'yellow' // Acceptable

  return <circle cx={cx} cy={cy} r={5} fill={fillColor} />
}

export default ChargerConnectivityModal

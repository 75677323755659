import { BalanceTextWrapper } from 'atom/text'
import { theme } from 'theme'

interface BalanceTextProps {
  balance: string | number
  currency: string
}

const BalanceText: React.FC<BalanceTextProps> = ({ balance, currency }) => {
  const negativeBalance = Number(balance) < 0
  return (
    <BalanceTextWrapper>
      <span className="new-callout-bold" style={{ color: theme.colors.softBlack }}>
        {negativeBalance
          ? `($${parseFloat(Math.abs(Number(balance)).toString()).toFixed(2)})`
          : `$${parseFloat(balance.toString()).toFixed(2)}`}
      </span>
      <span className="heading-06-light">{currency}</span>
    </BalanceTextWrapper>
  )
}

export default BalanceText

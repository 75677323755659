// reportsData.ts
import { useChargerTranslation } from 'hooks/translation/useChargerTranslation'
import { useConnectorTranslation } from 'hooks/translation/useConnectorTranslation'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { useLocationTranslation } from 'hooks/translation/useLocationTranslation'
import { useReportTranslation } from 'hooks/translation/useReportTranslation'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { useTransactionSearchTranslation } from 'hooks/translation/useTransactionSearchTranslation'
import { GlossaryItem, ReportDescriptions } from 'models/reports'

export const useAssembleReportWithGlossaries = () => {
  const listingTranslations = useListingBasicTrans()
  const transactionTranslations = useTransactionSearchTranslation()
  const tenantTranslations = useTenantTranslation()
  const generalTranslations = useGeneralTranslation()
  const connectorTranslations = useConnectorTranslation()
  const locationTranslations = useLocationTranslation()
  const chargerTranslations = useChargerTranslation()

  const reportTranslations = useReportTranslation()

  // Utility function to generate glossary items
  const createGlossary = (items: GlossaryItem[]) => items.map(({ label, description }) => ({ label, description }))

  // Common glossary items
  const driverGlossaries = createGlossary([
    { label: reportTranslations.driverFeeHeading, description: reportTranslations.driverFeeDescription },
    {
      label: reportTranslations.driverPaymentProcessingFeeHeading,
      description: reportTranslations.driverPaymentProcessingFeeDescription,
    },
    { label: reportTranslations.driverTaxPaidHeading, description: reportTranslations.driverTaxPaidDescription },
    { label: reportTranslations.driverTotalPaidHeading, description: reportTranslations.driverTotalPaidDescription },
  ])

  const locationGlobalGlossaries = createGlossary([
    { label: generalTranslations.countryText, description: reportTranslations.countryDescription },
    { label: reportTranslations.stateProvinceHeading, description: reportTranslations.stateDescription },
  ])

  const similarGlossary = createGlossary([
    { label: reportTranslations.ghgSavingHeading, description: reportTranslations.ghgSavingDescription },
    ...driverGlossaries,
    {
      label: reportTranslations.ownerTransactionFeeHeading,
      description: reportTranslations.ownerTransactionFeeDescription,
    },
    {
      label: reportTranslations.ownerPaymentProcessingFeeHeading,
      description: reportTranslations.ownerPaymentProcessingFeeDescription,
    },
    { label: reportTranslations.ownerTaxHeading, description: reportTranslations.ownerTaxDescription },
    { label: reportTranslations.propertySubTotalHeading, description: reportTranslations.propertySubTotalDescription },
    { label: reportTranslations.propertyTaxHeading, description: reportTranslations.propertyTaxDescription },
    { label: reportTranslations.propertyTotalHeading, description: reportTranslations.propertyTotalDescription },
  ])

  const financialSummaryGlossaries = [
    { label: listingTranslations.listingTitleText, description: reportTranslations.listingTitleDescription },
    { label: transactionTranslations.transactionIDText, description: reportTranslations.transactionIdDescription },
    { label: tenantTranslations.tenantText, description: reportTranslations.siteDescription },
    ...locationGlobalGlossaries,
    {
      label: transactionTranslations.tranasctionStateText,
      description: reportTranslations.transactionStateDescription,
    },
    { label: reportTranslations.startTimeLocalHeading, description: reportTranslations.startTimeLocalDescription },
    { label: reportTranslations.endTimeLocalHeading, description: reportTranslations.endTimeLocalDescription },
    {
      label: reportTranslations.chargingDurationInHoursHeading,
      description: reportTranslations.chargingDurationInHoursDescription,
    },
    { label: reportTranslations.energyInkWhHeading, description: reportTranslations.energyInkWhDescription },
    ...similarGlossary,
  ]

  const utilityChargeSessionReportGlossaries = [
    { label: listingTranslations.listingTitleText, description: reportTranslations.listingTitleDescription },
    { label: tenantTranslations.tenantText, description: reportTranslations.siteDescription },
    { label: transactionTranslations.transactionIDText, description: reportTranslations.transactionIdDescription },
    { label: connectorTranslations.connectorIdText, description: reportTranslations.connectorIdDescription },
    { label: reportTranslations.addressHeading, description: reportTranslations.addressDescription },
    { label: reportTranslations.stateProvinceHeading, description: reportTranslations.stateDescription },
    { label: locationTranslations.cityText, description: reportTranslations.cityDescription },
    { label: reportTranslations.sessionStartDateHeading, description: reportTranslations.sessionStartDateDescription },
    { label: reportTranslations.sessionStartTimeHeading, description: reportTranslations.sessionStartTimeDescription },
    { label: reportTranslations.sessionEndDateHeading, description: reportTranslations.sessionEndDateDescription },
    { label: reportTranslations.sessionEndTimeHeading, description: reportTranslations.sessionEndTimeDescription },
    {
      label: reportTranslations.chargingStartDateHeading,
      description: reportTranslations.chargingStartDateDescription,
    },
    {
      label: reportTranslations.chargingStartTimeHeading,
      description: reportTranslations.chargingStartTimeDescription,
    },
    { label: reportTranslations.chargingEndDateHeading, description: reportTranslations.chargingEndDateDescription },
    { label: reportTranslations.chargingEndTimeHeading, description: reportTranslations.chargingEndTimeDescription },
    { label: reportTranslations.timeHeading, description: reportTranslations.timeDescription },
    { label: reportTranslations.chargingDurationHeading, description: reportTranslations.chargingDurationDescription },
    { label: reportTranslations.sessionDurationHeading, description: reportTranslations.sessionDurationDescription },
    {
      label: reportTranslations.loiteringDurationHeading,
      description: reportTranslations.loiteringDurationDescription,
    },
    { label: reportTranslations.idlingDurationHeading, description: reportTranslations.idlingDurationDescription },
    {
      label: reportTranslations.totalEnergyProvidedHeading,
      description: reportTranslations.totalEnergyProvidedDescription,
    },
    { label: reportTranslations.averagePowerHeading, description: reportTranslations.averagePowerDescription },
    { label: reportTranslations.peakPowerHeading, description: reportTranslations.peakPowerDescription },
    {
      label: reportTranslations.chargerLifetimeEnergyDeliveredHeading,
      description: reportTranslations.chargerLifetimeEnergyDeliveredDescription,
    },
    { label: reportTranslations.chargerMaxOutputHeading, description: reportTranslations.chargerMaxOutputDescription },
    { label: reportTranslations.evseVendorHeading, description: reportTranslations.evseVendorDescription },
    { label: reportTranslations.evseModelHeading, description: reportTranslations.evseModelDescription },
    { label: reportTranslations.evseSerialNumberHeading, description: reportTranslations.evseSerialNumberDescription },
    { label: reportTranslations.chargerTypeHeading, description: reportTranslations.chargerTypeDescription },
    { label: reportTranslations.connectorTypeHeading, description: reportTranslations.connectorTypeDescription },
    { label: reportTranslations.priceTypeHeading, description: reportTranslations.priceTypeDescription },
    { label: reportTranslations.serviceTypeCodeHeading, description: reportTranslations.serviceTypeCodeDescription },
    { label: reportTranslations.transactionStatuHeading, description: reportTranslations.transactionStatuDescription },
    { label: reportTranslations.vehicleMakeHeading, description: reportTranslations.vehicleMakeDescription },
    { label: reportTranslations.vehicleModelHeading, description: reportTranslations.vehicleModelDescription },

    ...similarGlossary,
  ]

  const utilityIntervalGlossaries = [
    { label: listingTranslations.listingTitleText, description: reportTranslations.listingTitleDescription },
    { label: reportTranslations.siteIdHeading, description: reportTranslations.siteIdDescription },
    { label: tenantTranslations.tenantText, description: reportTranslations.siteDescription },
    { label: generalTranslations.countryText, description: reportTranslations.countryDescription },
    { label: reportTranslations.stateProvinceHeading, description: reportTranslations.stateDescription },
    { label: transactionTranslations.transactionIDText, description: reportTranslations.transactionIdDescription },
    {
      label: reportTranslations.sessionStartDateHeading,
      description: reportTranslations.sessionStartDateDescription,
    },
    {
      label: reportTranslations.sessionStartTimeHeading,
      description: reportTranslations.sessionStartTimeDescription,
    },
    { label: reportTranslations.sessionEndDateHeading, description: reportTranslations.sessionEndDateDescription },
    { label: reportTranslations.sessionEndTimeHeading, description: reportTranslations.sessionEndTimeDescription },
    { label: reportTranslations.intervalIdHeading, description: reportTranslations.intervalIdDescription },
    { label: reportTranslations.portNumberHeading, description: reportTranslations.portNumberDescription },
    {
      label: reportTranslations.intervalStartDateHeading,
      description: reportTranslations.intervalStartDateDescription,
    },
    {
      label: reportTranslations.intervalStartTimeHeading,
      description: reportTranslations.intervalStartTimeDescription,
    },
    {
      label: reportTranslations.intervalEndDateHeading,
      description: reportTranslations.intervalEndDateDescription,
    },
    {
      label: reportTranslations.intervalEndTimeHeading,
      description: reportTranslations.intervalEndTimeDescription,
    },
    {
      label: reportTranslations.energyDeliveredHeading,
      description: reportTranslations.energyDeliveredDescription,
    },
    { label: reportTranslations.peakDemandHeading, description: reportTranslations.peakDemandDescription },
    { label: reportTranslations.averageDemandHeading, description: reportTranslations.averageDemandDescription },
  ]

  const siteReportGlossaries = [
    {
      label: listingTranslations.listingTitleText,
      description: reportTranslations.listingTitleDescription,
    },
    { label: tenantTranslations.tenantText, description: reportTranslations.siteDescription },
    {
      label: reportTranslations.evseSerialNumberHeading,
      description: reportTranslations.evseSerialNumberDescription,
    },
    { label: reportTranslations.ratedOutputHeading, description: reportTranslations.ratedOutputDescription },
    { label: reportTranslations.evseStatusHeading, description: reportTranslations.evseStatusDescription },
    {
      label: reportTranslations.effectiveStatusDateHeading,
      description: reportTranslations.effectiveStatusDateDescription,
    },
    {
      label: reportTranslations.effectiveStatusTimeHeading,
      description: reportTranslations.effectiveStatusTimeDescription,
    },
    { label: reportTranslations.previousStatusHeading, description: reportTranslations.previousStatusDescription },
    {
      label: reportTranslations.previousStatusDateHeading,
      description: reportTranslations.previousStatusDateDescription,
    },
    {
      label: reportTranslations.previousStatusTimeHeading,
      description: reportTranslations.previousStatusTimeDescription,
    },
    { label: reportTranslations.activationDateHeading, description: reportTranslations.activationDateDescription },
  ]

  const chargerUtilizationGlossaries = [
    { label: tenantTranslations.tenantText, description: reportTranslations.siteDescription },
    { label: listingTranslations.listingTitleText, description: reportTranslations.listingTitleDescription },
    { label: chargerTranslations.serialNumberText, description: reportTranslations.serialNumberDescription },
    { label: connectorTranslations.connectorIdText, description: reportTranslations.connectorIdDescription },
    { label: reportTranslations.stateProvinceHeading, description: reportTranslations.stateDescription },
    { label: generalTranslations.countryText, description: reportTranslations.countryDescription },
    {
      label: reportTranslations.utilizationPercentHeading,
      description: reportTranslations.utilizationPercentDescription,
    },
    {
      label: reportTranslations.totalTimeInUseMinutesHeading,
      description: reportTranslations.totalInUseMinutesDescription,
    },
    {
      label: reportTranslations.totalTimeInPeriodMinutesHeading,
      description: reportTranslations.totalTimeInPeriodMinutesDescription,
    },
  ]

  const uptimeGlossaries = [
    { label: tenantTranslations.tenantText, description: reportTranslations.siteDescription },
    { label: listingTranslations.listingTitleText, description: reportTranslations.listingTitleDescription },
    { label: chargerTranslations.serialNumberText, description: reportTranslations.serialNumberDescription },
    {
      label: reportTranslations.uptimePercentageHeading,
      description: reportTranslations.uptimePercentageDescription,
    },
    {
      label: reportTranslations.numberOfOutagesHeading,
      description: reportTranslations.numberOfOutagesDescription,
    },
    {
      label: reportTranslations.totalOutageTimeHeading,
      description: reportTranslations.totalOutageTimeDescription,
    },
    {
      label: reportTranslations.totalExcludingTimeHeading,
      description: reportTranslations.totalExcludingTimeDescription,
    },
    {
      label: reportTranslations.totalTimeInPeriodMinutesHeading,
      description: reportTranslations.totalTimeInPeriodMinutesDescription,
    },
  ]

  const uptimeDisclaimer = {
    title: reportTranslations.uptimePercentageDisclaimerTitle,
    description: reportTranslations.uptimePercentageDisclaimerDescription,
  }

  return [
    {
      id: 1,
      title: reportTranslations.financialSummaryReportTitle,
      body: reportTranslations.financialSummaryReportBody,
      glossary: financialSummaryGlossaries,
    },
    {
      id: 2,
      title: reportTranslations.utilityChargeSessionReportTitle,
      body: reportTranslations.utilityChargeSessionReportBody,
      glossary: utilityChargeSessionReportGlossaries,
    },
    {
      id: 3,
      title: reportTranslations.utilityIntervalReportTitle,
      body: reportTranslations.utilityIntervalReportBody,
      glossary: utilityIntervalGlossaries,
    },
    {
      id: 4,
      title: reportTranslations.siteReportTitle,
      body: reportTranslations.siteReportBody,
      glossary: siteReportGlossaries,
    },
    {
      id: 5,
      title: reportTranslations.chargerUtilizationReportTitle,
      body: reportTranslations.chargerUtilizationReportBody,
      glossary: chargerUtilizationGlossaries,
    },
    {
      id: 6,
      title: reportTranslations.uptimeReportTitle,
      body: reportTranslations.uptimeReportBody,
      glossary: uptimeGlossaries,
      disclaimer: uptimeDisclaimer,
    },
  ] as ReportDescriptions[]
}

import { useFormatMessage } from '../../helpers/intl'
import configProvider from '../../config'

export const useUserTranslation = () => {
  const emailTxt = useFormatMessage('dashboard.usersPage.table.heading.email', 'Email')
  const searchEmailTxt = useFormatMessage('dashboard.placeholder.searchEmail', 'Search Email')
  const driverInfoTxt = useFormatMessage(
    'dashboard.usersPage.table.nestedTable.accessLevel.driver.info',
    'A user that has access to private listings in',
  )
  const accessInfoTxt = useFormatMessage(
    'dashboard.usersPage.table.nestedTable.accessLevel.driver.info',
    "User's dashboard access to",
  )
  const driverTxt = useFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel.driver', 'Driver')
  const chargerViewerText = useFormatMessage(
    'dashboard.usersPage.table.nestedTable.accessLevel.chargerViewer',
    'Charger Viewer',
  )
  const inviteUser = useFormatMessage('dashboard.usersPage.inviteUser', 'INVITE USER')
  const tenantsTxt = useFormatMessage('dashboard.navigation.tenants', 'Sites')
  const userTenantWarningText = useFormatMessage(
    'dashboard.usersPage.user.tenant.warning.info',
    'Note: The user is not associated with all tenants selected',
  )
  const createPlanText = useFormatMessage('dashboard.subscriptionsPage.button.title', 'Create Plan')
  const subscribeUserText = useFormatMessage('dashboard.plan.subscribeUser', 'Subscribe User')
  const editUserText = useFormatMessage('dashboard.usersPage.editUser.heading', 'Edit User')
  const firstNameText = useFormatMessage('dashboard.userspage.editUserName.firstName.heading', 'First Name')
  const lastNameText = useFormatMessage('dashboard.userspage.editUserName.lastName.heading', 'Last Name')
  const sendInviteText = useFormatMessage('dashboard.usersPage.editUser.sendInvite', 'SEND INVITATION')
  const saveText = useFormatMessage('dashboard.usersPage.editUser.save', 'SAVE')
  const tenantsText = useFormatMessage('dashboard.usersPage.editUser.tenants.heading', 'SITE')
  const driverText = useFormatMessage('dashboard.usersPage.editUser.driver.heading', 'DRIVER')
  const roleText = useFormatMessage('dashboard.usersPage.editUser.role.heading', 'ROLE')
  const managerText = useFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel.manager', 'Manager')
  const viewerText = useFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel.viewer', 'Viewer')
  const chargerOperatorText = useFormatMessage(
    'dashboard.usersPage.table.nestedTable.accessLevel.chargerOperator',
    'Charger Operator',
  )
  const noneText = useFormatMessage('dashboard.usersPage.editUser.none', 'None')
  const addMoreText = useFormatMessage('dashboard.usersPage.editUser.addMore', 'ADD MORE')
  const tooltipText = useFormatMessage(
    'dashboard.usersPage.editUser.driverTooltip',
    'Authorized drivers will gain access to restricted chargers in the site',
  )

  const firstNameRequiredWarning = useFormatMessage(
    'dashboard.userspage.editUserName.firstName.warning',
    'First name is required',
  )
  const lastNameRequiredWarning = useFormatMessage(
    'dashboard.userspage.editUserName.lastName.warning',
    'Last name is required',
  )

  const firstNameMaxLengthRequiredWarning = useFormatMessage(
    'dashboard.userspage.editUserName.firstName.maxlength.warning',
    'First name cannot exceed 20 characters!',
  )

  const lastNameMaxLengthRequiredWarning = useFormatMessage(
    'dashboard.userspage.editUserName.lastName.maxlength.warning',
    'Last name cannot exceed 20 characters!',
  )

  const cancelText = useFormatMessage('dashboard.usersPage.editUser.cancelText', 'CANCEL')
  const updateText = useFormatMessage('dashboard.userspage.editUserName.updateText', 'Update')
  const heading = useFormatMessage('dashboard.usersPage.inviteUser.heading2', 'Invite User')
  const headingInfo = useFormatMessage('dashboard.usersPage.inviteUser.headingInfo', 'Heading Info')
  const emailText = useFormatMessage('dashboard.usersPage.inviteUser.email.heading', 'Email')
  const role2Text = useFormatMessage('dashboard.usersPage.inviteUser.role.heading2', 'ROLE')
  const tenantsInviteText = useFormatMessage('dashboard.usersPage.inviteUser.tenants.heading', 'SITE')
  const driverInviteText = useFormatMessage('dashboard.usersPage.inviteUser.driver.heading', 'DRIVER')
  const addMoreInviteText = useFormatMessage('dashboard.usersPage.inviteUser.addMore', 'ADD MORE')
  const tooltipInviteText = useFormatMessage(
    'dashboard.usersPage.inviteUser.driverTooltip',
    'Authorized drivers will gain access to restricted chargers in the site',
  )
  const managerInviteText = useFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel.manager', 'Manager')
  const viewerInviteText = useFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel.viewer', 'Viewer')
  const sendInvite = useFormatMessage('dashboard.usersPage.inviteUser.sendInvite', 'SEND INVITATION')
  const cancelInviteText = useFormatMessage('dashboard.usersPage.inviteUser.cancelText2', 'CANCEL')
  const noneInviteText = useFormatMessage('dashboard.usersPage.inviteUser.none', 'None')
  const idText = useFormatMessage('dashboard.usersPage.table.heading.id', 'Id')
  const userText = useFormatMessage('dashboard.usersPage.table.heading.user', 'User')
  const accessText = useFormatMessage('dashboard.usersPage.table.heading.access', 'Access')
  const noAccessText = useFormatMessage('dashboard.usersPage.table.heading.noaccess', 'No Access')
  const roleTxt = useFormatMessage('dashboard.usersPage.table.heading.role', 'Role')
  const overviewText = useFormatMessage('dashboard.tenantPage.overviewTab.heading', 'Overview')
  const plansText = useFormatMessage('dashboard.text.plans', 'Plans')
  const subscriptionsText = useFormatMessage('dashboard.text.subscriptions', 'Subscriptions')
  const userTypeText = useFormatMessage('dashboard.tenantPage.overviewTab.heading.userType', 'User Type')
  const emailError = useFormatMessage(
    'dashboard.usersPage.emailError',
    `Please enter the email address of the user that has already created a ${configProvider.config.siteTitle} account.`,
  )
  const discountCodeText = useFormatMessage('dashboard.usersPage.inviteUser.discountCode', 'Discount Code')
  const addTenantsText = useFormatMessage('dashboard.usersPage.inviteUser.addTenants', 'Add Sites')
  const discountPlanText = useFormatMessage('dashboard.usersPage.inviteUser.discountPlan', 'Discount Plan')
  const addPermissionsText = useFormatMessage('dashboard.usersPage.inviteUser.addPermissions', 'Add Permissions')

  const guestUserText = useFormatMessage('dashboard.usersPage.guestUser', 'Anonymous Guest')

  const rfidTitleText = useFormatMessage('dashboard.usersPage.rfidModal.title', 'RFID')
  const rfidUserText = useFormatMessage('dashboard.usersPage.rfidModal.user', 'User')
  const rfidAddRFIDText = useFormatMessage('dashboard.usersPage.rfidModal.addRFID', 'AddRFID')
  const rfidAddRFIDPlaceholderText = useFormatMessage(
    'dashboard.usersPage.rfidModal.addRFID.placeholder',
    'Add a comma after a single or multiple RFIDs',
  )
  const rfidAddText = useFormatMessage('dashboard.usersPage.rfidModal.add', 'Add')
  const rfidAssignedRFIDText = useFormatMessage('dashboard.usersPage.rfidModal.assignedRFID', 'Assigned RFID')
  const rfidRemoveRFIDText = useFormatMessage('dashboard.usersPage.rfidModal.removeRFID', 'Remove RFID')
  const rfidRemoveRFIDWarningText = useFormatMessage(
    'dashboard.usersPage.rfidModal.removeRFID.warning',
    'User will no longer be linked to the RFID, and this action cannot be undone. However, you can always assign a new RFID at any time.',
  )
  const rfidRFIDremovedText = useFormatMessage(
    'dashboard.usersPage.rfidModal.RFIDremoved',
    'RFID successfully removed.',
  )
  const rfidRFIDaddedText = useFormatMessage('dashboard.usersPage.rfidModal.RFIDadded', 'RFID successfully added.')
  const rfidSelectedText = useFormatMessage('dashboard.usersPage.rfidModal.selected', 'RFID selected')

  const rfidRemoveMultipleRFIDText = useFormatMessage(
    'dashboard.usersPage.rfidModal.removeMultipleRFID',
    'Remove {count} RFIDs',
  )
  const manageRFIDText = useFormatMessage('dashboard.usersPage.rfidModal.manageRFID', 'Manage RFID')
  const cloneUserText = useFormatMessage('dashboard.usersPage.cloneModal.tooltip', 'Clone User')
  const editUserInfoText = useFormatMessage('dashboard.usersPage.editUserInfo', 'Edit User Information')
  const portalText = useFormatMessage('dashboard.usersPage.inviteUser.portal', '{{label}} Portal')
  const driverOptionText = useFormatMessage('dashboard.usersPage.inviteUser.driver', 'Driver')
  const rolesText = useFormatMessage('dashboard.usersPage.inviteUser.roles', 'Roles')

  const cloneModalHeadingText = useFormatMessage('dashboard.usersPage.cloneModal.heading', 'Clone Permissions')
  const cloneModalHeadingDescriptionText = useFormatMessage(
    'dashboard.usersPage.cloneModal.heading.description',
    'In this modal you can copy permissions from one user and select another user to clone permissions to. You can add, edit, or remove permissions as needed. Any changes made here would not affect the permissions of the original user.',
  )
  const cloneModalCloningFromText = useFormatMessage('dashboard.usersPage.cloneModal.cloningFrom', 'Cloning From')
  const cloneModalCloningFromDescriptionText = useFormatMessage(
    'dashboard.usersPage.cloneModal.cloningFrom.description',
    'You’re loading the permission of this user as a starting point. You can add, edit, or remove permissions as needed. Any changes made here would not affect the permissions of the original user.',
  )
  const cloneModalCloningToText = useFormatMessage('dashboard.usersPage.cloneModal.cloningTo', 'Cloning Permissions To')
  const cloneModalCloningToDescriptionText = useFormatMessage(
    'dashboard.usersPage.cloneModal.cloningTo.description',
    'Permissions will be applied everyone added below. Enter the email of an existing user or invite a new one.',
  )
  const cloneModalSitePermissionsText = useFormatMessage(
    'dashboard.usersPage.cloneModal.sitePermissions',
    'Site Permissions',
  )
  const cloneModalSitePermissionsDescriptionText = useFormatMessage(
    'dashboard.usersPage.cloneModal.sitePermissions.description',
    'Add, edit, or remove permissions across different sites for SWTCH Portal or Driver role.',
  )
  const cloneModalCloneText = useFormatMessage('dashboard.usersPage.cloneModal.clone', 'Clone')

  const emailNotValidText = useFormatMessage(
    'dashboard.usersPage.inviteUser.email.notValid.warning',
    'Email is not a valid email!',
  )

  const userWalletText = useFormatMessage('dashboard.usersPage.userWallet', 'User Wallet')
  const userWalletDisabledText = useFormatMessage(
    'dashboard.usersPage.userWallet.disabled',
    'This user has not set up a wallet',
  )

  const driverWalletText = useFormatMessage('dashboard.usersPage.userWallet.driverWallet', "Driver's Wallet")

  const walletActivityTitleText = useFormatMessage('dashboard.usersPage.userWallet.activity', 'Wallet Activity')
  const walletRefundModalTitleText = useFormatMessage(
    'dashboard.usersPage.userWallet.refundModal.title',
    'Refund Wallet Balance?',
  )
  const walletRefundModalDescriptionText = useFormatMessage(
    'dashboard.usersPage.userWallet.refundModal.description',
    "You're about to refund {balance} to the driver's credit card.",
  )
  const walletRefundModalBulletPoint1Text = useFormatMessage(
    'dashboard.usersPage.userWallet.refundModal.bulletPoint1',
    'Once processed, the wallet balance will be $0.',
  )
  const walletRefundModalBulletPoint2Text = useFormatMessage(
    'dashboard.usersPage.userWallet.refundModal.bulletPoint2',
    'This action cannot be undone.',
  )
  const walletRefundModalConfirmRefundText = useFormatMessage(
    'dashboard.usersPage.userWallet.refundModal.confirmRefund',
    'Confirm Refund',
  )

  const walletRefundText = useFormatMessage('dashboard.usersPage.userWallet.refund', 'Refund')
  const walletCurrentBalanceText = useFormatMessage('dashboard.usersPage.userWallet.currentBalance', 'Current Balance')
  const walletHistoryText = useFormatMessage('dashboard.usersPage.userWallet.walletHistory', 'Wallet History')
  const walletHistoryTableDateText = useFormatMessage(
    'dashboard.usersPage.userWallet.walletHistory.table.heading.date',
    'Date',
  )
  const walletHistoryTableActivityText = useFormatMessage(
    'dashboard.usersPage.userWallet.walletHistory.table.heading.activity',
    'Wallet Activity',
  )
  const walletHistoryTableAmountText = useFormatMessage(
    'dashboard.usersPage.userWallet.walletHistory.table.heading.amount',
    'Amount',
  )
  const walletHistoryTableBalanceText = useFormatMessage(
    'dashboard.usersPage.userWallet.walletHistory.table.heading.balance',
    'Balance',
  )
  const walletHistoryTableTransactionIdText = useFormatMessage(
    'dashboard.usersPage.userWallet.walletHistory.table.heading.transactionId',
    'Transaction ID',
  )
  const walletHistoryTableConnectedText = useFormatMessage(
    'dashboard.usersPage.userWallet.walletHistory.table.heading.connected',
    'Connected',
  )
  const walletHistoryTableDisconnectedText = useFormatMessage(
    'dashboard.usersPage.userWallet.walletHistory.table.heading.disconnected',
    'Disconnected',
  )
  const walletEmailText = useFormatMessage('dashboard.usersPage.userWallet.email', 'Email')
  const walletCarMakeModelText = useFormatMessage('dashboard.usersPage.userWallet.carMakeModel', 'Car Make/ Model')
  const walletLicensePlateText = useFormatMessage('dashboard.usersPage.userWallet.licensePlate', 'License Plate')
  const walletExportCSVText = useFormatMessage('dashboard.usersPage.userWallet.exportCSV', 'Export CSV')

  const walletRefundModalSuccessText = useFormatMessage(
    'dashboard.usersPage.userWallet.refundModal.success',
    'Refunded wallet balance',
  )
  const emailTooltipText = useFormatMessage(
    'dashboard.usersPage.table.heading.email.tooltip',
    'Users appearing in grey text have been invited but not yet created an account. Actions will be available once they do. Users appearing in black text are registered and active in the system. You can manage their permissions at any time.',
  )

  return {
    emailTxt,
    searchEmailTxt,
    driverInfoTxt,
    accessInfoTxt,
    driverTxt,
    inviteUser,
    chargerViewerText,
    tenantsTxt,
    userTenantWarningText,
    createPlanText,
    subscribeUserText,
    editUserText,
    firstNameText,
    lastNameText,
    sendInviteText,
    saveText,
    tenantsText,
    driverText,
    roleText,
    managerText,
    viewerText,
    chargerOperatorText,
    noneText,
    addMoreText,
    tooltipText,
    firstNameRequiredWarning,
    lastNameRequiredWarning,
    firstNameMaxLengthRequiredWarning,
    lastNameMaxLengthRequiredWarning,
    cancelText,
    updateText,
    heading,
    headingInfo,
    emailText,
    role2Text,
    tenantsInviteText,
    driverInviteText,
    addMoreInviteText,
    tooltipInviteText,
    managerInviteText,
    viewerInviteText,
    sendInvite,
    cancelInviteText,
    noneInviteText,
    idText,
    userText,
    accessText,
    noAccessText,
    roleTxt,
    overviewText,
    plansText,
    subscriptionsText,
    userTypeText,
    emailError,
    discountCodeText,
    addTenantsText,
    discountPlanText,
    addPermissionsText,
    guestUserText,
    rfidTitleText,
    rfidUserText,
    rfidAddRFIDText,
    rfidAddRFIDPlaceholderText,
    rfidAddText,
    rfidAssignedRFIDText,
    rfidRemoveRFIDText,
    rfidRemoveRFIDWarningText,
    rfidRFIDremovedText,
    rfidSelectedText,
    rfidRemoveMultipleRFIDText,
    manageRFIDText,
    portalText,
    driverOptionText,
    rolesText,
    rfidRFIDaddedText,
    cloneModalHeadingText,
    cloneModalHeadingDescriptionText,
    cloneModalCloningFromText,
    cloneModalCloningFromDescriptionText,
    cloneModalCloningToText,
    cloneModalCloningToDescriptionText,
    cloneModalSitePermissionsText,
    cloneModalSitePermissionsDescriptionText,
    cloneModalCloneText,
    emailNotValidText,
    cloneUserText,
    editUserInfoText,
    userWalletText,
    userWalletDisabledText,
    driverWalletText,
    walletActivityTitleText,
    walletRefundModalTitleText,
    walletRefundModalDescriptionText,
    walletRefundModalBulletPoint1Text,
    walletRefundModalBulletPoint2Text,
    walletRefundModalConfirmRefundText,
    walletRefundText,
    walletCurrentBalanceText,
    walletHistoryText,
    walletHistoryTableDateText,
    walletHistoryTableActivityText,
    walletHistoryTableAmountText,
    walletHistoryTableBalanceText,
    walletHistoryTableTransactionIdText,
    walletHistoryTableConnectedText,
    walletHistoryTableDisconnectedText,
    walletEmailText,
    walletCarMakeModelText,
    walletLicensePlateText,
    walletExportCSVText,
    walletRefundModalSuccessText,
    emailTooltipText,
  }
}

import React, { useEffect, useState } from 'react'
import { PageHeader, Table } from 'antd'
import { RouteComponentProps } from 'react-router'

import { Box } from 'atom/box'
import { BackgroundContainer } from 'atom/layout/backgroundContainer'

import { AlertError } from 'components/error'
import InfoMessage from 'components/message/InfoMessage'
import { withAuthenticatedLayout } from '../../components/layouts/layout'

import { ChargersSelectors } from 'features/chargers/ChargersSelectors'
import ChargerEditOption from 'features/chargers/ChargerEditOption'
import ChargersTable from 'features/chargers/ChargersTable'
import ChargerConnectivityModal from 'components/listings/charger-connectivity-modal'

import { useChargers } from 'hooks/useChargers'

import { ChargerModal } from './modal/charger-modal'
import { useParsedTenantData } from 'hooks/useChargersParseData'
import { EmptyData } from 'components/redesign/emptyData'
import { ListingModal } from 'features/listings/modal/ListingModal'

const ChargersBasePage: React.FC<RouteComponentProps> = (props) => {
  document.querySelector('body')?.classList.add('redesignActive')

  const [showEditOption, setShowEditOption] = useState(false)
  const [renderEditOption, setRenderEditOption] = useState(false)

  const {
    connOCPPStatus,
    chargerServiceStatus,
    chargerConnectivityStatus,
    tenantsParsed,
    masterAccountParsed,
  } = useParsedTenantData(props.location.search)

  const {
    error,
    loading,
    vendors,
    filters,
    pagination,
    selectedRowKeys,
    chargersPerTenant,
    showChargerModal,
    showListingModal,
    showSearchQuery,
    selectedListings,
    selectedChargersConnectors,
    selectedExistingChargers,
    areAllChargersSelectedAcrossAllPages,
    selectAllChargers,
    fetchChargersPerTenant,
    fetchChargersPerTenantWithQuery,
    handleShowModal,
    handleSelect,
    handleSelectAllOnCurrentPage,
    handleTenantSelect,
    handleFilterChange,
    handlePaginationChange,
    resetSelectionAndCloseEditOptions,
    handleClearFilter,
    handleConnectivityStatusChange,
    connectivityModal,
    openConnectivityModal,
    closeConnectivityModal,
  } = useChargers(chargerServiceStatus, connOCPPStatus, chargerConnectivityStatus, tenantsParsed, masterAccountParsed)

  useEffect(() => {
    if (selectedListings.length > 0) {
      setRenderEditOption(true)
      setShowEditOption(true)
    } else {
      setShowEditOption(false)
      const timeoutId = setTimeout(() => {
        setRenderEditOption(false)
      }, 250) // Delay for the fade-out transition duration
      return () => clearTimeout(timeoutId)
    }
  }, [selectedListings])

  const chargersSelectors = (
    <ChargersSelectors
      loading={loading}
      filters={filters}
      vendors={vendors}
      onFetch={fetchChargersPerTenantWithQuery}
      onClearFilter={handleClearFilter}
      onFilterChange={handleFilterChange}
    />
  )

  return (
    <BackgroundContainer>
      <PageHeader title={chargersSelectors} />
      <AlertError error={error} />

      {showSearchQuery && selectedListings.length === 0 && (
        <InfoMessage
          loading={loading}
          text="charger"
          totalEntries={pagination.totalEntries}
          showSearchQuery={showSearchQuery}
          selectAllData={selectAllChargers}
          selectedData={selectedListings}
        />
      )}

      <div className={`fade-in ${showEditOption ? 'show' : ''}`}>
        {renderEditOption && (
          <ChargerEditOption
            loading={loading}
            text="charger"
            areAllChargersSelected={areAllChargersSelectedAcrossAllPages}
            selectedListings={selectedListings}
            onShowModal={handleShowModal}
            totalEntries={pagination.totalEntries}
            selectAllData={selectAllChargers}
            resetSelectionAndCloseEditOptions={resetSelectionAndCloseEditOptions}
          />
        )}
      </div>
      {/* Because of potential two or more tenants, render one loading table to represent all tenants and then render the actual tables */}
      <Box>
        {loading && <Table loading={loading} />}
        {!loading && chargersPerTenant.length === 0 && <EmptyData />}
        {!loading && chargersPerTenant.length !== 0 && (
          <ChargersTable
            loading={loading}
            chargers={chargersPerTenant}
            pagination={pagination}
            selectedRowKeys={selectedRowKeys}
            filters={filters}
            onSelectChange={handleSelect}
            onSelectAllChange={handleSelectAllOnCurrentPage}
            onSelectTenantChange={handleTenantSelect}
            onPageChange={handlePaginationChange}
            onConnectivityStatusChange={handleConnectivityStatusChange}
            onOpenConnectivityModal={openConnectivityModal}
          />
        )}
      </Box>

      {showListingModal && (
        <ListingModal
          loading={loading}
          selectedListings={selectedListings}
          onOk={fetchChargersPerTenant}
          onCancel={() => handleShowModal('listing', false)}
        />
      )}
      {showChargerModal && (
        <ChargerModal
          loading={loading}
          selectedCharger={selectedExistingChargers}
          selectedChargersConns={selectedChargersConnectors}
          onOk={() => handleShowModal('charger', false)}
          onCancel={() => handleShowModal('charger', false)}
        />
      )}
      {connectivityModal.isOpen && (
        <ChargerConnectivityModal
          chargerId={connectivityModal.chargerId}
          isOpen={connectivityModal.isOpen}
          onClose={closeConnectivityModal}
        />
      )}
    </BackgroundContainer>
  )
}

export const ChargersPage = withAuthenticatedLayout(ChargersBasePage)

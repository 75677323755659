export const getDomainFromUrl = (url: string): string => {
  // Handle localhost case
  if (url.includes('localhost')) {
    return 'localhost'
  }

  // Clean the URL by removing leading @ if present
  const cleanUrl = url.replace(/^@/, '')

  // Extract domain using URL constructor
  try {
    const urlObject = new URL(cleanUrl)
    const hostParts = urlObject.hostname.split('.')

    // Get last two parts of the domain (e.g., swtchenergy.com)
    return hostParts.slice(-2).join('.')
  } catch (e) {
    return ''
  }
}

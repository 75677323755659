import { log } from './logger'

export interface Auth0Config {
  domain: string
  clientId: string
  clientSecret: string
  scope: string
}

export type AirbrakeConfigEnv = 'development' | 'staging' | 'preproduction' | 'production'
export interface AirbrakeConfig {
  projectId: number
  projectKey: string
  env: AirbrakeConfigEnv
}

export interface Config {
  mockEnabled: boolean
  mockWebSocket: boolean
  network: string
  apiDomain: string
  dashboardApiDomain: string
  dashboardWsDomain: string
  auth0: Auth0Config
  airbrake: AirbrakeConfig | undefined
  googleMapsApiKey: string
  googleTimeZoneApiKey: string
  loadManagement: string
  publicAssetsDomain: string
  metabaseApiDomain: string
  supportDashboardDomain: string
  siteTitle: string
  cihApiDomain: string
  cihApiKey: string
  demoEmails: string[]
  isHttps: boolean
  callTransactionsApi: boolean
}

function getAirbrakeEnv(envVar: string): AirbrakeConfigEnv | undefined {
  if (envVar === 'dev') {
    return 'development'
  } else if (envVar === 'stag') {
    return 'staging'
  } else if (envVar === 'preprod') {
    return 'preproduction'
  } else if (envVar === 'prod') {
    return 'production'
  }
  return undefined
}

export class ConfigProvider {
  containsBackendProps = false
  config: Config

  constructor() {
    const {
      REACT_APP_AIRBRAKE_PROJECT_ID,
      REACT_APP_AIRBRAKE_PROJECT_KEY,
      REACT_APP_AIRBRAKE_ENV,
      REACT_APP_API_DOMAIN, // i,e: cahrger
      REACT_APP_DASHBOARD_API_DOMAIN,
      REACT_APP_DASHBOARD_WS_URL,
      REACT_APP_NETWORK, // i,e: swtch, powercharge
      REACT_APP_AUTH0_DOMAIN,
      REACT_APP_AUTH0_CLIENT_ID,
      REACT_APP_AUTH0_CLIENT_SECRET,
      REACT_APP_MOCK,
      REACT_APP_GOOGLE_MAPS_API_KEY,
      REACT_APP_GOOGLE_TIME_ZONE_API_KEY,
      REACT_APP_LOAD_MANAGEMENT,
      REACT_APP_PUBLIC_ASSETS_DOMAIN,
      REACT_APP_WS_MOCK,
      REACT_APP_METABASE_API_DOMAIN,
      REACT_APP_SUPPORT_DASHBOARD_DOMAIN,
      REACT_APP_SITE_TITLE,
      REACT_APP_CIH_API_KEY,
      REACT_APP_DEMO_EMAILS,
      REACT_APP_HTTPS,
      REACT_APP_CALL_TRANSACTIONS_API,
    } = process.env

    this.config = {
      mockEnabled: REACT_APP_MOCK === 'true',
      mockWebSocket: REACT_APP_WS_MOCK === 'true',
      airbrake: undefined,
      apiDomain: REACT_APP_API_DOMAIN || 'localhost:8080',
      dashboardApiDomain: REACT_APP_DASHBOARD_API_DOMAIN || 'chi-staging.swtchenergy.com',
      dashboardWsDomain: REACT_APP_DASHBOARD_WS_URL || 'wss://chi-staging.swtchenergy.com/ws?',
      network: REACT_APP_NETWORK || 'swtch',
      googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyAZK3vUG-tNGwqyaxBPGYEYnAVREeqW9to',
      googleTimeZoneApiKey: REACT_APP_GOOGLE_TIME_ZONE_API_KEY || 'AIzaSyBDvKljLv54znE_ZhNqd9GyB3vKYDIPVYg',
      auth0: {
        domain: REACT_APP_AUTH0_DOMAIN || '',
        clientId: REACT_APP_AUTH0_CLIENT_ID || '',
        clientSecret: REACT_APP_AUTH0_CLIENT_SECRET || '',
        scope: 'openid email',
      },
      loadManagement: REACT_APP_LOAD_MANAGEMENT || '',
      publicAssetsDomain: REACT_APP_PUBLIC_ASSETS_DOMAIN || 'https://swtch-garden-bucket.s3.amazonaws.com/public',
      metabaseApiDomain: REACT_APP_METABASE_API_DOMAIN || 'https://staging.cih.api.swtchenergy.com/cih/v1',
      supportDashboardDomain: REACT_APP_SUPPORT_DASHBOARD_DOMAIN || 'https://support.swtchenergy.com',
      siteTitle: REACT_APP_SITE_TITLE || 'SWTCH',
      cihApiDomain: REACT_APP_METABASE_API_DOMAIN || 'staging.cih.api.swtchenergy.com',
      cihApiKey: REACT_APP_CIH_API_KEY || '',
      demoEmails: REACT_APP_DEMO_EMAILS ? REACT_APP_DEMO_EMAILS.split(',') : [],
      isHttps: REACT_APP_HTTPS === 'true',
      callTransactionsApi: REACT_APP_CALL_TRANSACTIONS_API === 'true',
    }
    const airbrakeEnv = getAirbrakeEnv(REACT_APP_AIRBRAKE_ENV || '')
    if (REACT_APP_AIRBRAKE_PROJECT_ID && REACT_APP_AIRBRAKE_PROJECT_KEY && airbrakeEnv) {
      this.config.airbrake = {
        projectId: parseInt(REACT_APP_AIRBRAKE_PROJECT_ID),
        projectKey: REACT_APP_AIRBRAKE_PROJECT_KEY,
        env: airbrakeEnv,
      }
    }

    log('loaded application config %o', this.config)
  }
}

export default new ConfigProvider()

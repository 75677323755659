import {
  chargerUtilizationInUseAllWeekForWorkplaceCommercial,
  chargerUtilizationInUseWeekdayForWorkplaceCommercial,
  chargerUtilizationInUseWeekendForWorkplaceCommercial,
  consumptionWeekdayForWorkplaceCommercial,
  consumptionWeekendForWorkplaceCommercial,
  consumptionWeekForWorkplaceCommercial,
  operationEnergiesForWorkplaceCommercial,
  operationRevenuesForWorkplaceCommercial,
  operationTxnCountsForWorkplaceCommercial,
  summaryForWorkplaceCommercial,
} from 'helpers/insights/dataForWorkplaceCommercial'

import {
  chargerUtilizationInUseAllWeekForDCFC,
  chargerUtilizationInUseWeekdayForDCFC,
  chargerUtilizationInUseWeekendForDCFC,
  consumptionWeekdayForDCFC,
  consumptionWeekendForDCFC,
  consumptionWeekForDCFC,
  operationEnergiesForDCFC,
  operationRevenuesForDCFC,
  operationTxnCountsForDCFC,
  summaryForDCFC,
} from 'helpers/insights/dataForDCFC'
import {
  generateChargerConsumptionTemplate,
  generateChargerProfile,
  generateChargerUtilizationTemplate,
  generateDailyProfile,
  generateTxnOverview,
  generateTxnOverviewTemplate,
} from 'helpers/insights/generateInsight'
import {
  chargerUtilizationInUseAllWeekForMultiFamily1,
  chargerUtilizationInUseAllWeekForMultiFamily2,
  chargerUtilizationInUseWeekdayForMultiFamily1,
  chargerUtilizationInUseWeekdayForMultiFamily2,
  chargerUtilizationInUseWeekendForMultiFamily1,
  chargerUtilizationInUseWeekendForMultiFamily2,
  consumptionWeekdayForMultiFamily1,
  consumptionWeekdayForMultiFamily2,
  consumptionWeekendForMultiFamily1,
  consumptionWeekendForMultiFamily2,
  consumptionWeekForMultiFamily1,
  consumptionWeekForMultiFamily2,
  operationEnergiesForMultiFamily1,
  operationEnergiesForMultiFamily2,
  operationRevenuesForMultiFamily1,
  operationRevenuesForMultiFamily2,
  operationTxnCountsForMultiFamily1,
  operationTxnCountsForMultiFamily2,
  summaryForMultiFamily1,
  summaryForMultiFamily2,
} from 'helpers/insights/dataForMultiFamily'
import { TransactionSummary } from 'models/http'
import { ChargerInfo } from 'models/chargerv2'
import { TransactionFilter } from 'services/data-provider/metric'
import { log } from 'logger'

type TenantName = 'demoMFU-site1' | 'demoMFU-site2' | 'demoWorkplace-site1' | 'demoDCFC-site1'

type MockTransactionSummaryType = {
  [key in TenantName]: TransactionSummary
}

export const mockChargerInfo = {
  users: {
    active: 0, //integer
    guest: 0, //integer
    accountHolder: 0, //integer
  },
  connectors: {
    pluggedIn: 0,
    pluggedInLevel2: 0,
    pluggedInDc: 0,
    available: 0,
    availableLevel2: 0,
    availableDc: 0,
    drProgram: 0,
    drProgramLevel2: 0,
    drProgramDc: 0,
    charging: 0,
    totalConnectors: 0,
  },
  chargers: {
    online: 50, //integer
    offline: 30, //integer
    repair: 20, //integer
    maxAmp: 0, //unit A, integer
    currentAmp: 640, //unit A, float with decimal 2
  },
} as ChargerInfo

const mockInsightsMultiFamily1: TransactionSummary = {
  txnOverview: generateTxnOverviewTemplate(
    operationRevenuesForMultiFamily1,
    operationTxnCountsForMultiFamily1,
    operationEnergiesForMultiFamily1,
  ),
  chargers: generateChargerUtilizationTemplate(
    chargerUtilizationInUseAllWeekForMultiFamily1,
    chargerUtilizationInUseWeekdayForMultiFamily1,
    chargerUtilizationInUseWeekendForMultiFamily1,
  ),
  dailyProfiles: generateChargerConsumptionTemplate(
    consumptionWeekForMultiFamily1,
    consumptionWeekdayForMultiFamily1,
    consumptionWeekendForMultiFamily1,
  ),
  summary: summaryForMultiFamily1,
  timezone: 'America/Toronto',
}
const mockInsightsMultiFamily2: TransactionSummary = {
  txnOverview: generateTxnOverviewTemplate(
    operationRevenuesForMultiFamily2,
    operationTxnCountsForMultiFamily2,
    operationEnergiesForMultiFamily2,
  ),
  chargers: generateChargerUtilizationTemplate(
    chargerUtilizationInUseAllWeekForMultiFamily2,
    chargerUtilizationInUseWeekdayForMultiFamily2,
    chargerUtilizationInUseWeekendForMultiFamily2,
  ),
  dailyProfiles: generateChargerConsumptionTemplate(
    consumptionWeekForMultiFamily2,
    consumptionWeekdayForMultiFamily2,
    consumptionWeekendForMultiFamily2,
  ),
  summary: summaryForMultiFamily2,
  timezone: 'America/Toronto',
}
const mockInsightsWorkplaceCommercial: TransactionSummary = {
  txnOverview: generateTxnOverviewTemplate(
    operationRevenuesForWorkplaceCommercial,
    operationTxnCountsForWorkplaceCommercial,
    operationEnergiesForWorkplaceCommercial,
  ),
  chargers: generateChargerUtilizationTemplate(
    chargerUtilizationInUseAllWeekForWorkplaceCommercial,
    chargerUtilizationInUseWeekdayForWorkplaceCommercial,
    chargerUtilizationInUseWeekendForWorkplaceCommercial,
  ),
  dailyProfiles: generateChargerConsumptionTemplate(
    consumptionWeekForWorkplaceCommercial,
    consumptionWeekdayForWorkplaceCommercial,
    consumptionWeekendForWorkplaceCommercial,
  ),
  summary: summaryForWorkplaceCommercial,
  timezone: 'America/Toronto',
}

export const mockInsightsDCFC: TransactionSummary = {
  txnOverview: generateTxnOverviewTemplate(
    operationRevenuesForDCFC,
    operationTxnCountsForDCFC,
    operationEnergiesForDCFC,
  ),
  chargers: generateChargerUtilizationTemplate(
    chargerUtilizationInUseAllWeekForDCFC,
    chargerUtilizationInUseWeekdayForDCFC,
    chargerUtilizationInUseWeekendForDCFC,
  ),
  dailyProfiles: generateChargerConsumptionTemplate(
    consumptionWeekForDCFC,
    consumptionWeekdayForDCFC,
    consumptionWeekendForDCFC,
  ),
  summary: summaryForDCFC,
  timezone: 'America/Toronto',
}

export const mockTransactionsSummaryByTenants: MockTransactionSummaryType = {
  'demoMFU-site1': mockInsightsMultiFamily1,
  'demoMFU-site2': mockInsightsMultiFamily2,
  'demoWorkplace-site1': mockInsightsWorkplaceCommercial,
  'demoDCFC-site1': mockInsightsDCFC,
}

export const mockChargerMetrics = [
  mockInsightsMultiFamily1,
  mockInsightsMultiFamily1,
  mockInsightsWorkplaceCommercial,
  mockInsightsDCFC,
]

export const sumChargerMetrics = (transactionSummaries: TransactionSummary[], filters: TransactionFilter) => {
  let filteredTransactionSummaries = transactionSummaries
  const result: TransactionSummary = {
    txnOverview: generateTxnOverview(),
    chargers: generateChargerProfile(),
    dailyProfiles: generateDailyProfile(),
    summary: {
      txnCount: 0,
      txnAfterHr: 0,
      txnOnDays: 0,
      revenueTotal: 0,
      revenueDemandResponse: 0,
      revenueCharging: 0,
      revenueLoitering: 0,
      energyConsumeTotal: 0,
      energyConsumeInPeakHrs: 0,
      ghgSavingTotal: 0,
      chargerAverageDayDuration: 0,
      chargerAverageDayLevel2Duration: 0,
      chargerAverageDayDcfcDuration: 0,
      chargerAverageDayPlugin: 0,
      chargerAverageDayLevel2Plugin: 0,
      chargerAverageDayDcfcPlugin: 0,
      usersTotal: 0,
      avgTxnDay: 0,
      avgEnergyConsumePerTxn: 0,
      avgUserSpend: 0,
      stationActivation: {
        mobile: 0,
        web: 0,
        rfid: 0,
        roaming: 0,
        sms: 0,
        PosTerminal: 0,
      },
    },
  }

  if (filters?.tenants && filters.tenants.length > 0) {
    filteredTransactionSummaries = filters.tenants
      .filter((tenant) => mockTransactionsSummaryByTenants[tenant.name as keyof MockTransactionSummaryType])
      .map((tenant) => mockTransactionsSummaryByTenants[tenant.name as keyof MockTransactionSummaryType])
  }

  log('filteredTransactionSummaries', filteredTransactionSummaries)
  log('filteredTransactionSummaries.length', filteredTransactionSummaries.length)

  filteredTransactionSummaries.forEach((summary) => {
    result.txnOverview.forEach((item, index) => {
      item.revenue += summary.txnOverview[index].revenue
      item.txnCount += summary.txnOverview[index].txnCount
      item.energy += summary.txnOverview[index].energy
    })
    result.chargers.forEach((item, index) => {
      item.chargerInUseWeekday += summary.chargers[index].chargerInUseWeekday
      item.chargerInUseWeekend += summary.chargers[index].chargerInUseWeekend
      item.chargerInUseWeek += summary.chargers[index].chargerInUseWeek
    })
    result.dailyProfiles.forEach((item, index) => {
      item.consumeWeekday += summary.dailyProfiles[index].consumeWeekday
      item.consumeWeekend += summary.dailyProfiles[index].consumeWeekend
      item.consumeWeek += summary.dailyProfiles[index].consumeWeek
    })
    result.summary.txnCount += summary.summary.txnCount
    result.summary.txnAfterHr += summary.summary.txnAfterHr
    result.summary.txnOnDays += summary.summary.txnOnDays
    result.summary.revenueTotal += summary.summary.revenueTotal
    result.summary.revenueDemandResponse += summary.summary.revenueDemandResponse
    result.summary.revenueCharging += summary.summary.revenueCharging
    result.summary.revenueLoitering += summary.summary.revenueLoitering
    result.summary.energyConsumeTotal += summary.summary.energyConsumeTotal
    result.summary.energyConsumeInPeakHrs += summary.summary.energyConsumeInPeakHrs
    result.summary.ghgSavingTotal += summary.summary.ghgSavingTotal
    result.summary.chargerAverageDayDuration += summary.summary.chargerAverageDayDuration
    result.summary.chargerAverageDayLevel2Duration += summary.summary.chargerAverageDayLevel2Duration
    result.summary.chargerAverageDayDcfcDuration += summary.summary.chargerAverageDayDcfcDuration
    result.summary.chargerAverageDayPlugin += summary.summary.chargerAverageDayPlugin
    result.summary.chargerAverageDayLevel2Plugin += summary.summary.chargerAverageDayLevel2Plugin
    result.summary.chargerAverageDayDcfcPlugin += summary.summary.chargerAverageDayDcfcPlugin
    result.summary.usersTotal += summary.summary.usersTotal
    result.summary.avgTxnDay += summary.summary.avgTxnDay
    result.summary.avgEnergyConsumePerTxn += summary.summary.avgEnergyConsumePerTxn
    result.summary.avgUserSpend += summary.summary.avgUserSpend
    result.summary.stationActivation.mobile += summary.summary.stationActivation.mobile
    result.summary.stationActivation.web += summary.summary.stationActivation.web
    result.summary.stationActivation.rfid += summary.summary.stationActivation.rfid
    result.summary.stationActivation.roaming += summary.summary.stationActivation.roaming
  })
  return result
}

import configProvider from '../../config'
import { addQueryString } from '../../helpers/url'
import { apiClient } from './client'

import { sanitizeCreateNewListingObj } from '../../helpers/listing'
import { log } from '../../logger'
import {
  mockAllListings,
  mockAllListingsRespV1,
  mockListingFull,
  mockListingResp,
  mockNewListingResp,
  mockServiceDescriptions,
  mockServiceStatusReasons,
} from '../../mock/listing-mock'
import { mockStripeAccountsResp } from '../../mock/stripe-accounts-mock'
import {
  GeneralApiResponse,
  ListingsBaseResponse,
  ListingsResponse,
  ServiceStatusReasonsResponse,
} from '../../models/http'
import {
  ListingFull,
  ListingBase,
  ListingRef,
  CreateNewListingType,
  ListingStatusUpdate,
  ListingPricingSchemaUpdate,
  ListingBulkUpdate,
  NewListing,
  ServiceStatusDescription,
  ConnectivityScore,
} from '../../models/listing'
import { ListingStripeAccounts } from '../../models/stripe-account'
import { TenantRef } from '../../models/tenant'
import { returnMock } from './mock'
import { listingQuery, serviceStatusReasonsQuery } from '../../helpers/filter/listing'
import { ListingFilter, ServiceStatusReasonsFilter } from '../../models/filter'
import { PricingSchema } from '../../models/price'
import { mockPricingSchema } from '../../mock/pricing-schema-mock'
import { mockGeneralApiResponse } from '../../mock/response'
import { mockChargersV2 } from 'mock/charger-mock'
export async function GetAllListings(props: ListingFilter): Promise<ListingsBaseResponse> {
  let url = '/listings'

  const queryParams = listingQuery(props)

  url = addQueryString(url, queryParams)

  log('Get All Listings', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockAllListingsRespV1(props.page))
  }
  return apiClient(url, {})
}

export async function FindListings(
  isMockUpEnabled: boolean,
  term?: string,
  tenant?: TenantRef,
  tenants?: TenantRef[],
  shaving?: boolean,
): Promise<ListingRef[]> {
  let url = '/listings/find'

  let queryParams: { [key: string]: string } = {}

  if (shaving) {
    queryParams['shaving'] = `${shaving}`
  }

  if (term) {
    queryParams['term'] = `${term}`
  }

  if (tenant) {
    queryParams['tenant_id'] = `${tenant.id}`
  }

  if (tenants && tenants.length > 0) {
    queryParams['tenant_id'] = `${tenants.map((t) => t.id)}`
  }

  url = addQueryString(url, queryParams)

  log('finding listings', { url: url })
  if (isMockUpEnabled) {
    return returnMock(mockListingResp)
  }
  return apiClient(url, {})
}

export async function SearchListingIds(
  isMockUpEnabled: boolean,
  term?: number | string,
  tenant?: TenantRef,
  tenants?: TenantRef[],
): Promise<number[]> {
  let url = '/listings/listing_ids'

  let queryParams: { [key: string]: string } = {}

  if (term) {
    queryParams['listing_id'] = `${term}`
  }

  if (tenant) {
    queryParams['tenant_id'] = `${tenant.id}`
  }

  if (tenants && tenants.length > 0) {
    queryParams['tenant_id'] = `${tenants.map((t) => t.id)}`
  }

  url = addQueryString(url, queryParams)

  log('finding listings by ID', { url: url })
  if (isMockUpEnabled) {
    return returnMock([1, 2, 3])
  }
  return apiClient(url, {})
}

export async function GetListings(
  isMockUpEnabled: boolean,
  props: ListingFilter,
): Promise<ListingsResponse | NewListing[]> {
  let url = '/listings'

  const queryParams = listingQuery(props)

  url = addQueryString(url, queryParams)

  log('Get All Listings', { url })
  if (isMockUpEnabled) {
    return returnMock(mockNewListingResp(props.page ? props.page : 1))
  }
  return apiClient(url, {})
}

export async function GetServiceStatusReasons(
  props: ServiceStatusReasonsFilter,
): Promise<ServiceStatusReasonsResponse> {
  let url = '/service_status_reasons'

  const queryParams = serviceStatusReasonsQuery(props)

  url = addQueryString(url, queryParams)

  log('Get Service Status Reasons', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockServiceStatusReasons())
  }
  return apiClient(url, {})
}

export async function GetServiceStatusDescriptions(): Promise<ServiceStatusDescription[]> {
  let url = '/service_status_reason_descriptions'

  log('Get Service Status Reasons', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockServiceDescriptions)
  }
  return apiClient(url, {})
}

export async function GetConnectivityScore(
  listingId: number,
  period: string,
  timezone: string,
): Promise<Array<ConnectivityScore>> {
  const url = `/listings/${listingId}/connectivity_score?period=${period}&timezone=${timezone}`
  log('get listing connectivity score', { url })

  if (configProvider.config.mockEnabled) {
    return returnMock([
      { period: '11:10 PM', connectivity_score: 99 },
      { period: '11:15 PM', connectivity_score: 0.98 },
    ])
  }

  return apiClient(url, {})
}

export async function UpdateListingsStatus(data: ListingStatusUpdate): Promise<GeneralApiResponse> {
  const url = `/listings/update_status`
  log('update listings status', { url: url })

  if (configProvider.config.mockEnabled) {
    return returnMock(mockGeneralApiResponse)
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      listing: data,
    }),
  })
}

export async function UpdatePricingSchema(
  data: ListingPricingSchemaUpdate,
  isMockUpEnabled?: boolean,
): Promise<GeneralApiResponse> {
  const url = `/listings/pricing_schemas/${data.pricing_schema_id}/update`
  log(`update listings pricing schema id: ${data.pricing_schema_id}`, { url })

  if (isMockUpEnabled) {
    return returnMock(mockGeneralApiResponse)
  }

  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      listing: { ids: data.ids },
      pricing_schema_id: data.pricing_schema_id,
    }),
  })
}

export async function UpdateBulkListings(data: ListingBulkUpdate): Promise<GeneralApiResponse> {
  let url = '/listings/bulk_update'

  log('update bulk listings', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockGeneralApiResponse)
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      listing_ids: data.listing_ids,
      listing: data.listing,
    }),
  })
}

export async function TenantPricingSchemaOptions(tenant_id: string | number): Promise<PricingSchema[]> {
  const url = `/tenants/${tenant_id}/pricing_schemas?all_pricing_schemas=true`
  log('return an array pf pricing schemas for the tenant', { url: url })

  if (configProvider.config.mockEnabled) {
    return returnMock(mockPricingSchema)
  }
  return apiClient(url, {})
}

export async function GetAllShavingEnabledListings(): Promise<ListingRef[]> {
  let url = '/listings/find'

  let queryParams: { [key: string]: string } = {
    shaving: 'true',
  }

  url = addQueryString(url, queryParams)

  log('finding listings', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockListingResp)
  }
  return apiClient(url, {})
}

export async function GetListingStripeAccounts(): Promise<ListingStripeAccounts[]> {
  let url = '/listings/stripe_accounts'

  let queryParams: { [key: string]: string } = {}

  url = addQueryString(url, queryParams)

  log('finding listings', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockStripeAccountsResp[0])
  }
  return apiClient(url, {})
}

export async function CreateNewListing(listing: CreateNewListingType): Promise<ListingBase> {
  const url = '/listings'

  const listingParams = sanitizeCreateNewListingObj(listing)

  log('create a listing', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockAllListings[0])
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      listing: {
        ...listingParams,
      },
    }),
  })
}

export async function UpdateListing(listingId: number, listing: ListingFull): Promise<string> {
  const url = `/listings/${listingId}/edit`

  const listingParams = sanitizeCreateNewListingObj(listing)

  log('update a listing', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock('Success')
  }

  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      listing: {
        ...listingParams,
      },
    }),
  })
}
export async function GetListing(listingId: number): Promise<any> {
  const url = `/listings/${listingId}`

  log('get a listing', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockListingFull)
  }

  return apiClient(url, {})
}

export async function GetListingPricingSchema(listingId: number, isMockUpEnabled: boolean): Promise<PricingSchema> {
  let url = `/listings/${listingId}/pricing_schemas`
  log('get pricing schema for listing', { url: url })
  if (isMockUpEnabled) {
    const chargerData = mockChargersV2.find((charger) => charger.listings.find((listing) => listing.id === listingId))
    const selectedListing = chargerData?.listings.find((listing) => listing.id === listingId)
    const pricingSchema = {
      ...mockPricingSchema[0],
      flatFeeCents: Number(selectedListing?.price.flatFee) || 0,
      timeOfDayPriceWeekday: Array(48).fill(selectedListing?.price.pricePerUnitCents),
      timeOfDayPriceWeekend: Array(48).fill(selectedListing?.price.pricePerUnitCents),
      loiteringType:
        selectedListing?.loitering.loiteringStatus === 'no_penalty'
          ? 'none'
          : selectedListing?.loitering.loiteringStatus,
      loiteringTimeLimitMinutes: selectedListing?.loitering.loiteringTimeLimit || 0,
      loiteringGracePeriodMinutes: selectedListing?.loitering.loiteringGracePeriod || 0,
      timeOfDayLoiteringWeekday: Array(48).fill(
        selectedListing?.loitering.loiteringStatus === 'no_penalty'
          ? 0
          : (selectedListing?.loitering?.loiteringPenalty || 0) * 100,
      ),
      timeOfDayLoiteringWeekend: Array(48).fill(
        selectedListing?.loitering.loiteringStatus === 'no_penalty'
          ? 0
          : (selectedListing?.loitering?.loiteringPenalty || 0) * 100,
      ),
      appliedListings: chargerData?.listings
        .filter((listing) => listing.price?.flatFee === selectedListing?.price.flatFee)
        .map((listing) => ({
          listingTitle: listing.title,
          listingId: listing.id,
        })),
    }
    return returnMock(pricingSchema)
  }
  return apiClient(url, {})
}

import { useState } from 'react'
import { Button, PageHeader } from 'antd'

import { Box } from '../../atom/box'
import { AlertError } from '../../components/error'
import { useAppState } from '../../state'
import { BackgroundContainer } from '../../atom/layout/backgroundContainer'
import { useReports } from '../../hooks/useReports'
import ReportsSelectors from '../../features/reports/components/search'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { ButtonIconCircle } from '../../atom/button'
import { questionMarkSVG } from '../../assets/svg/questionMark'
import { ReportInfoModal } from '../../features/reports/modal/ReportInfoModal'
import { QuestionButtonWrapper } from '../../atom/reports'
import Iframe from '../../components/iframe'
import { WechatOutlined } from '@ant-design/icons'
import { ChatModal } from 'features/reports/modal/ChatModal'

const ReportsBasePage: React.FC = () => {
  document.querySelector('body')?.classList.add('redesignActive')
  const [showInfoModal, setShowInfoModal] = useState(false)

  const { currentUser, IsLaptop, IsDesktop, IsTablet, IsMobile } = useAppState()

  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()
  const isTablet = IsTablet()
  const isMobile = IsMobile()
  const {
    error,
    url,
    loading,
    startDate,
    endDate,
    filters,
    getReportsFromMetabase,
    handleChangeDate,
    handleLocation,
    handleUpdateFilters,
  } = useReports()

  const handleInfoModal = () => {
    setShowInfoModal(!showInfoModal)
  }

  const renderTitles = () => (
    <ReportsSelectors
      loading={loading}
      startDate={startDate}
      endDate={endDate}
      filters={filters}
      dropdownClassName="not-in-modal-selector"
      getReports={getReportsFromMetabase}
      handleChangeDate={handleChangeDate}
      handleLocation={handleLocation}
      onFilters={handleUpdateFilters}
    />
  )

  const renderButton = () => (
    <QuestionButtonWrapper responsive={isTablet || isMobile}>
      <ButtonIconCircle fetchData={handleInfoModal} loading={false} disabled={false} icon={questionMarkSVG} />
    </QuestionButtonWrapper>
  )

  const renderIFrame = (height: number) => {
    return (
      <Box>
        <Iframe title="metabase-reports" loading={loading ? 'lazy' : 'eager'} url={url} height={height} />
      </Box>
    )
  }

  const renderReportsView = () => {
    if (isDesktop || isLaptop) {
      return renderIFrame(650)
    } else {
      return renderIFrame(500)
    }
  }

  const [showChat, setShowChat] = useState(false)
  const handleChat = () => {
    setShowChat(!showChat)
  }

  return (
    <BackgroundContainer>
      <AlertError error={error} />
      <PageHeader
        style={{ paddingTop: '13px' }}
        title={renderTitles()}
        extra={isDesktop || isLaptop ? renderButton() : null}
      />
      {currentUser?.role === 'admin' && (
        <div style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 10 }}>
          <Button
            size="large"
            type="primary"
            icon={<WechatOutlined style={{ fontSize: 30 }} />}
            style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', padding: 25 }}
            onClick={handleChat}
          />
        </div>
      )}
      {showChat && <ChatModal onCancel={handleChat} />}
      {renderReportsView()}
      {showInfoModal && <ReportInfoModal onCancel={handleInfoModal} />}
    </BackgroundContainer>
  )
}

export const ReportsPage = withAuthenticatedLayout(ReportsBasePage)

import { styled, theme } from '../theme'
export const CardBody = styled.div`
  .ant-card-body {
    padding: 0;
  }
`
export const CardWrapper = styled.div`
  height: 100%;
  .ant-card {
    background: #ffffff;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
  }

  .ant-card-head-title {
    z-index: 1;
    padding: 20px 0;
  }

  .ant-card-head-title span {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }

  .ant-card-body {
    padding: 0px 20px;
  }

  .ant-card-head {
    border-bottom: 0;
    padding: 0px 20px;
  }

  .ant-card-loading-content {
    padding-bottom: 20px;
  }

  .station-overview-meta-container {
    padding: 21px auto;
  }

  .station-over-view-meta-container:nth-of-type(2) {
    margin: 21px;
  }

  .stations .ant-card-body,
  .current .ant-card-body {
    padding: 0;
  }

  .ant-card-extra svg {
    cursor: pointer;
  }

  .title {
    flex: 1;
  }
`

export const WalletCardWrapper = styled.div`
  background: ${theme.colors.white};
  width: 100%;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

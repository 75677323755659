import { WalletDataResponse } from 'models/http'
import { WalletData, WalletInfo } from 'models/user'

export const mockWalletInfo: WalletInfo = {
  balance: 100,
  currency: 'usd',
  email: 'test@test.com',
  carMakeModel: 'Toyota Camry',
  licensePlate: 'ABC123',
}

export const mockWalletHistory: WalletData[] = [
  {
    id: 5157,
    title: 'J0001',
    duration: 1800,
    amount: 1.7,
    balance: -1.7,
    walletId: '314',
    connectedAt: '2023-11-16T20:00:00',
    disconnectedAt: '2023-11-16T20:30:00',
    transactionId: 3604,
    createdAt: '2023-11-16T16:54:50Z',
    updatedAt: '2023-11-16T16:54:50Z',
  },
  {
    id: 5158,
    title: 'Wallet Autoload',
    duration: null,
    amount: 10.0,
    balance: 8.3,
    walletId: '314',
    connectedAt: null,
    disconnectedAt: null,
    transactionId: null,
    createdAt: '2023-11-16T16:54:51Z',
    updatedAt: '2023-11-16T16:54:51Z',
  },
  {
    id: 5159,
    title: 'J0001',
    duration: 1800,
    amount: 1.7,
    balance: 6.6,
    walletId: '314',
    connectedAt: '2023-11-16T17:30:00',
    disconnectedAt: '2023-11-16T18:00:00',
    transactionId: 3605,
    createdAt: '2023-11-16T16:55:05Z',
    updatedAt: '2023-11-16T16:55:05Z',
  },
  {
    id: 5160,
    title: 'Refund',
    duration: null,
    amount: 1.7,
    balance: 8.3,
    walletId: '314',
    connectedAt: null,
    disconnectedAt: null,
    transactionId: null,
    createdAt: '2023-11-16T16:55:13Z',
    updatedAt: '2023-11-16T16:55:13Z',
  },
  {
    id: 5161,
    title: 'Refund',
    duration: null,
    amount: 1.7,
    balance: 10.0,
    walletId: '314',
    connectedAt: null,
    disconnectedAt: null,
    transactionId: null,
    createdAt: '2023-11-23T20:48:41Z',
    updatedAt: '2023-11-23T20:48:41Z',
  },
  {
    id: 5165,
    title: 'MP03r',
    duration: 7200,
    amount: 4.52,
    balance: 5.48,
    walletId: '314',
    connectedAt: '2024-02-06T23:00:00',
    disconnectedAt: '2024-02-07T01:00:00',
    transactionId: 3663,
    createdAt: '2024-02-06T19:39:09Z',
    updatedAt: '2024-02-06T19:39:09Z',
  },
  {
    id: 5166,
    title: 'MP03r',
    duration: 22860,
    amount: 14.35,
    balance: -8.87,
    walletId: '314',
    connectedAt: '2024-02-06T19:39:00',
    disconnectedAt: '2024-02-07T02:00:00',
    transactionId: 3664,
    createdAt: '2024-02-06T19:40:22Z',
    updatedAt: '2024-02-06T19:40:22Z',
  },
  {
    id: 5167,
    title: 'Wallet Autoload',
    duration: null,
    amount: 10.0,
    balance: 1.13,
    walletId: '314',
    connectedAt: null,
    disconnectedAt: null,
    transactionId: null,
    createdAt: '2024-02-06T19:40:23Z',
    updatedAt: '2024-02-06T19:40:23Z',
  },
]

export const mockWalletHistoryResponse: WalletDataResponse = {
  data: mockWalletHistory,
  pagination: {
    totalEntries: 10,
    currentPage: 1,
    perPage: 10,
  },
}

import { Button, Col, PageHeader, Row, Space, Spin } from 'antd'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import solidGauge from 'highcharts/modules/solid-gauge'
import { debounce } from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { AlertError } from '../../components/error'

import { LocationSelector } from '../../components/redesign/location-selector'
import { MultiTenantSelector } from '../../components/redesign/multiple-tenant-selector'
import { SwtchError } from '../../models/error'
import { TenantRef } from '../../models/tenant'
import { useAppState } from '../../state'

import { ChargerInfo, ChargerStationInfo, ConnectorInfo } from '../../models/chargerv2'
import { TransactionResponsev2, TransactionSummary } from '../../models/http'
import {
  ChargerProfile,
  DailyProfile,
  SummaryStationActivation,
  Transactionv2,
  TrxnOverview,
  TrxSummary,
} from '../../models/transaction'
import { FetchTransactions, GetChargerSummaryMetrics, TransactionFilter } from '../../services/data-provider/metric'
import {
  HistoricalDataSectionWrapper,
  HomePageContainer,
  RealTimeDataSectionWrapper,
  SectionGap,
} from './ContainerStyles/homepageStyles'

import { CustomDateRangePicker } from 'components/redesign/datepicker'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { useFormatMessage } from 'helpers/intl'
import { useCalendarTransactions } from 'hooks/translation/useCalendarTranslation'
import { useSelectorTranslation } from 'hooks/translation/useSelectorTranslation'
import { useSelector } from 'hooks/useSelector'
import { MasterAccountRef } from 'models/master-account'
import { theme } from 'theme'
import configProvider from '../../config'
import { useDashboardTranslation } from '../../hooks/translation/useDashboardTranslation'
import { log } from '../../logger'
import {
  allMockChargerInfos,
  mockDemoTenantsChargerInfo,
  MockDemoTenantsChargerInfoType,
  sumChargerInfo,
} from '../../mock/websocket/charger'
import { getSession } from '../../state/session-store'
import OperationOverview from './components/OperationOverview'
import MetricsSummary from './components/metrics/MetricsSummary'
import ConsumptionInsightsSection from './section/consumption-insights-section'
import StationActivationSection from './section/station-activation-section'
import StationOverviewSection from './section/station-overview-section'
import UtilizationInsightsSection from './section/utilization-insights-section'

highchartsMore(Highcharts)
solidGauge(Highcharts)

const { mockWebSocket, mockEnabled, dashboardWsDomain } = configProvider.config

export const DashboardBasePageRedesign: React.FC = () => {
  const { isMockUpEnabled, selectedTenant } = useAppState()
  const { calendarRanges } = useCalendarTransactions()

  const mockStartDate = moment().subtract(1, 'years').startOf('year')
  const mockEndDate = moment().subtract(1, 'years').endOf('year')
  const productionStartDate = moment().subtract(6, 'days')
  const productionEndDate = moment().endOf('day')

  const [startDate, setStartDate] = useState(isMockUpEnabled ? mockStartDate : productionStartDate)
  const [endDate, setEndDate] = useState(isMockUpEnabled ? mockEndDate : productionEndDate)

  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(true)
  const [wsLoading, setWsLoading] = useState(false)

  const [selectedCountries, setSelectedCountries] = useState<string[]>([])
  const [selectedProvinces, setSelectedProvinces] = useState<string[]>([])
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [transactionsSummary, setTransactionsSummary] = useState<TransactionSummary>()
  const [utilizationInsightChargerProfiles, setUtilizationInsightChargerProfiles] = useState<ChargerProfile[]>()
  const [stationActivation, setStationActivation] = useState<SummaryStationActivation>()
  const [trxProfileSummary, setTrxProfileSummary] = useState<TrxSummary>()
  const [trnxOverview, setTrnxOverview] = useState<TrxnOverview[]>()
  const [dailyProfiles, setDailyProfiles] = useState<DailyProfile[]>()
  const [chargerInfo, setChargerInfo] = useState<ChargerInfo>()
  const [chargerStationInfo, setChargerStationInfo] = useState<ChargerStationInfo>()
  const [connectorInfo, setConnectorInfo] = useState<ConnectorInfo>()
  const [utlizationInsightTabKey, setUtlizationInsightTabKey] = useState('All')
  const [utlizationInsightGaugeTabKey, setUtlizationInsightGaugeTabKey] = useState('All')
  const [consumptionInsightLoadProfileTabKey, setConsumptionInsightLoadProfileTabKey] = useState('All')
  const [operationOverviewTabKey, setOperationOverviewTabKey] = useState('Transactions')

  const [stationActivationDataEmpty, setStationActivationDataEmpty] = useState(false)
  const [averageDailyLoadProfileEmpty, setAverageDailyLoadProfileEmpty] = useState(false)
  const [utilizationProfileEmpty, setUtilizationProfileEmpty] = useState(false)
  const [utilizationGauageEmpty, setUtilizationGaugeEmpty] = useState(false)

  const [ws, setWebsocket] = useState<WebSocket>()
  const [updateDict, setUpdateDict] = useState(`update=1`)
  const [tenantsUpdateStr, setTenantsUpdateStr] = useState('')
  const [locationUpdateStr, setLocationUpdateStr] = useState('')
  const [masterAccountUpdateStr, setMasterAccountUpdateStr] = useState('')

  const [timeZone, setTimeZone] = useState('')

  const [wsConnected, setWsConnected] = useState(false)

  const [locationDictStr, setLocationDictStr] = useState('')
  const [tenantDictStr, setTenantDictStr] = useState('')
  const [masterAccountDictStr, setMasterAccountDictStr] = useState('')
  const wsURL = useRef(dashboardWsDomain)

  const [filter, setFilter] = useState<TransactionFilter>({
    page: 1,
    perPage: 10,
    defaultTenant: selectedTenant,
  })

  const [transactionResponse, setTransactionResponse] = useState<TransactionResponsev2>()
  const [transactions, setTransactions] = useState<Transactionv2[]>()

  const { stationOverviewText, operationOverviewText } = useDashboardTranslation()

  const { accountNamesText } = useSelectorTranslation()

  const {
    multiMasterAccountDebounceFetcher,
    multiMasterAccountHandleChange,
    multiMasterAccountHandlePlaceholder,
    multiMasterAccountHandleValue,
    renderMultiMasterAccountOption,
  } = useSelector()

  document.querySelector('body')?.classList.add('redesignActive')
  const isResponsiveStyling = useMediaQuery({ minWidth: 0, maxWidth: 649 })

  const onChangeDate = (dates: moment.Moment[]) => {
    if (dates[0] !== startDate) {
      setStartDate(dates[0])
    }
    if (dates[1] !== endDate) {
      setEndDate(dates[1])
    }
  }

  const handleClearMasterAccounts = () => {
    setFilter({ ...filter, masterAccounts: [] })
  }

  const handleLocation = (chosenCountries: string[], chosenProvinces: string[]) => {
    setSelectedCountries(chosenCountries)
    setSelectedProvinces(chosenProvinces)
    setFilter({ ...filter, country: chosenCountries.join(','), province: chosenProvinces.join(',') })
  }

  const handleTenantSelection = (tenants: TenantRef[]) => {
    // update tenants and remove any duplicates
    setTenants(
      tenants.filter((obj, index) => {
        return index === tenants.findIndex((o) => obj.id === o.id)
      }),
    )
    setFilter({ ...filter, tenants: tenants })
  }

  const handleMasterAccountSelection = (masterAccounts: MasterAccountRef[]) =>
    setFilter({
      ...filter,
      masterAccounts: masterAccounts.filter((obj, index) => {
        return index === masterAccounts.findIndex((o) => obj.id === o.id)
      }),
    })

  // const handleMasterAccountSelection = (
  //   value: any,
  //   setDirty: (dirty: boolean) => void,
  //   setSelectedOptions: (selectedOption: any[]) => void,
  //   onOptionsChange: (selectedTenants: any[]) => void,
  //   options: any[],
  // ) => {
  //   const findMasterAccount = allMasterAccounts?.find((p) => p.name === value)
  //   setMasterAccount(findMasterAccount)
  //   setSelectedOptions(value)
  //   onOptionsChange(value)
  //   setFilter({ ...filter, masterAccountId: findMasterAccount?.id })
  // }

  useEffect(() => {
    fetchTransactionSummary()
  }, [filter])

  useEffect(() => {
    if (!wsConnected) {
      setWsLoading(true)
      startWebSocket()
    }
    return () => {
      ws && ws.send('update=0Tclose=1')
    }
  }, [wsConnected])

  const fetchTransactionSummary = () => {
    setLoading(true)
    setError(undefined)
    const controller = new AbortController()

    Promise.all([
      GetChargerSummaryMetrics(filter, startDate, endDate, controller, isMockUpEnabled).then((resp) => {
        log('resp:', resp)
        setTransactionsSummary(resp)
      }),
      FetchTransactions(filter, startDate, endDate, controller, isMockUpEnabled).then((resp) =>
        setTransactionResponse(resp),
      ),
    ])
      .catch((err: SwtchError) => setError(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    transactionsSummary && setUtilizationInsightChargerProfiles(transactionsSummary.chargers)
    transactionsSummary && setStationActivation(transactionsSummary.summary.stationActivation)
    transactionsSummary && setTrxProfileSummary(transactionsSummary.summary)
    transactionsSummary && setTrnxOverview(transactionsSummary.txnOverview)
    transactionsSummary && setDailyProfiles(transactionsSummary.dailyProfiles)
    transactionsSummary && transactionsSummary.timezone
      ? setTimeZone(transactionsSummary.timezone)
      : setTimeZone('local')
    transactionResponse && setTransactions(transactionResponse.transactions)

    setUtlizationInsightGaugeTabKey(utlizationInsightGaugeTabKey)
    setOperationOverviewTabKey(operationOverviewTabKey)
    setUtlizationInsightTabKey(utlizationInsightTabKey)
    setConsumptionInsightLoadProfileTabKey(consumptionInsightLoadProfileTabKey)

    transactionsSummary &&
      setStationActivationDataEmpty(
        Object.values(transactionsSummary.summary.stationActivation).every((obj) => obj === 0),
      )
    handleTabChangeUtilizationInsightTab(utlizationInsightTabKey)
    handleTabChangeConsumptionInsightTab(consumptionInsightLoadProfileTabKey)
    handleTabUtilizationInsightChargingDurationTab(utlizationInsightGaugeTabKey)
    handleTabChangeOperationOverviewTab(operationOverviewTabKey)
  }, [transactionsSummary, transactionResponse])

  const startWebSocket = () => {
    if (mockEnabled || mockWebSocket || isMockUpEnabled) {
      setWsLoading(false)
      setChargerInfo(sumChargerInfo(allMockChargerInfos))
      return
    }
    const session = getSession()

    if (!session) throw new Error('not logged in')

    log('---before creating ws :', wsURL.current)

    const ws = new WebSocket(encodeURI(wsURL.current), [`${session.token}`, `${session.id}`])

    setWebsocket(ws)

    ws.addEventListener('open', (event) => {
      log('WebSocket open')
    })

    ws.addEventListener('message', (event) => {
      log('Received message ', event.data)
    })

    ws.addEventListener('close', (event) => {
      log('WebSocket connection closed')
    })

    ws.addEventListener('error', (event) => {
      log('WebSocket connection error:', event)
    })

    ws.onopen = function (e) {
      log('[open] Connection established')
      log('Sending to server')
      setWsConnected(true)
      // ws.send(dict)
    }

    ws.onmessage = function (event) {
      setWsLoading(false)
      log(`[message] Data received from server: ${event.data}`)
      setChargerInfo(JSON.parse(event.data))
      event.data.chargers && setChargerStationInfo(JSON.parse(event.data.chargers))
    }

    ws.onclose = function (event) {
      if (event.wasClean) {
        log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`)
      } else {
        log('[close] Connection died')
      }
      log('wsURL onClose:', wsURL.current)
    }
  }

  useEffect(() => {
    if (mockEnabled || mockWebSocket || isMockUpEnabled) {
      // filter the mock data based on the selected tenants
      const filteredChargerInfos =
        tenants.length > 0
          ? tenants
              .filter((tenant) => mockDemoTenantsChargerInfo[tenant.name as keyof MockDemoTenantsChargerInfoType])
              .map((tenant) => mockDemoTenantsChargerInfo[tenant.name as keyof MockDemoTenantsChargerInfoType])
          : allMockChargerInfos
      setChargerInfo(sumChargerInfo(filteredChargerInfos))
      return
    }

    tenants.length > 0
      ? setTenantsUpdateStr(`Ttenant_ids=${[...tenants.map((t) => t.id)].join(',')}`)
      : setTenantsUpdateStr('')

    selectedCountries.length > 0 && selectedProvinces.length > 0
      ? setLocationUpdateStr(
          `Tcountry=${[...selectedCountries.map((country) => country)].join(',')}Tprovince=${[
            ...selectedProvinces.map((province) => province),
          ].join(',')}`,
        )
      : setLocationUpdateStr('')

    filter.masterAccounts && filter.masterAccounts.length > 0
      ? setMasterAccountUpdateStr(
          `Tmaster_account_ids=${filter.masterAccounts.map((masterAccount) => masterAccount.id)}`,
        )
      : setMasterAccountUpdateStr('')

    tenants.length > 0
      ? setTenantDictStr(`tenant_ids=${[...tenants.map((t) => t.id)].join(',')}`)
      : setTenantDictStr('')

    selectedCountries.length > 0 && selectedProvinces.length > 0
      ? setLocationDictStr(
          `country=${[...selectedCountries.map((country) => country)].join(',')}&province=${[
            ...selectedProvinces.map((province) => province),
          ].join(',')}`,
        )
      : setLocationDictStr('')

    filter.masterAccounts && filter.masterAccounts.length > 0
      ? setMasterAccountDictStr(`master_account_ids=${filter.masterAccounts.map((ma) => ma.id)}`)
      : setMasterAccountDictStr('')
  }, [tenants, selectedCountries, selectedProvinces, filter.masterAccounts])

  useEffect(() => {
    setUpdateDict(`update=1${tenantsUpdateStr}${locationUpdateStr}${masterAccountUpdateStr}`)
  }, [tenantsUpdateStr, locationUpdateStr, masterAccountUpdateStr])

  useEffect(() => {
    log('updateDict: ', updateDict)
    if (ws) {
      ws.send(updateDict)
    }
  }, [updateDict])

  useEffect(() => {
    const parts = [tenantDictStr, locationDictStr, masterAccountDictStr].filter((part) => part && part.length > 0)
    if (parts.length > 0) {
      wsURL.current = `${dashboardWsDomain}${parts.join('&')}`
    }
  }, [tenantDictStr, locationDictStr, masterAccountDictStr])

  useEffect(() => {
    chargerInfo && setChargerStationInfo(chargerInfo.chargers)
    chargerInfo && setConnectorInfo(chargerInfo.connectors)
  }, [chargerInfo])

  const handleTabChangeOperationOverviewTab = (activeKey: string) => {
    switch (activeKey) {
      case 'Revenue':
        setOperationOverviewTabKey('Revenue')
        break
      case 'Transactions':
        setOperationOverviewTabKey('Transactions')
        break
      case 'Energy':
        setOperationOverviewTabKey('Energy')
        break
    }
  }

  const handleTabChangeUtilizationInsightTab = (activeKey: string) => {
    switch (activeKey) {
      case 'All':
        setUtlizationInsightTabKey('All')
        transactionsSummary &&
          setUtilizationProfileEmpty(
            transactionsSummary.chargers.map((obj) => obj.chargerInUseWeek).every((entry) => entry === 0),
          )
        break
      case 'Weekday':
        setUtlizationInsightTabKey('Weekday')
        transactionsSummary &&
          setUtilizationProfileEmpty(
            transactionsSummary.chargers.map((obj) => obj.chargerInUseWeekday).every((entry) => entry === 0),
          )
        break
      case 'Weekend':
        setUtlizationInsightTabKey('Weekend')
        transactionsSummary &&
          setUtilizationProfileEmpty(
            transactionsSummary.chargers.map((obj) => obj.chargerInUseWeekend).every((entry) => entry === 0),
          )
        break
    }
  }

  const handleTabChangeConsumptionInsightTab = (activeKey: string) => {
    switch (activeKey) {
      case 'All':
        setConsumptionInsightLoadProfileTabKey('All')
        transactionsSummary &&
          setAverageDailyLoadProfileEmpty(
            transactionsSummary.dailyProfiles.map((obj) => obj.consumeWeek).every((entry) => entry === 0),
          )
        break
      case 'Weekday':
        setConsumptionInsightLoadProfileTabKey('Weekday')
        transactionsSummary &&
          setAverageDailyLoadProfileEmpty(
            transactionsSummary.dailyProfiles.map((obj) => obj.consumeWeekday).every((entry) => entry === 0),
          )
        break
      case 'Weekend':
        setConsumptionInsightLoadProfileTabKey('Weekend')
        transactionsSummary &&
          setAverageDailyLoadProfileEmpty(
            transactionsSummary.dailyProfiles.map((obj) => obj.consumeWeekend).every((entry) => entry === 0),
          )
        break
    }
  }

  const handleTabUtilizationInsightChargingDurationTab = (activeKey: string) => {
    switch (activeKey) {
      case 'All':
        setUtlizationInsightGaugeTabKey('All')
        transactionsSummary &&
          setUtilizationGaugeEmpty(
            transactionsSummary.summary.chargerAverageDayDuration === 0 &&
              transactionsSummary.summary.chargerAverageDayPlugin === 0,
          )
        break
      case 'Level2':
        setUtlizationInsightGaugeTabKey('Level2')
        transactionsSummary &&
          setUtilizationGaugeEmpty(
            transactionsSummary.summary.chargerAverageDayLevel2Plugin === 0 &&
              transactionsSummary.summary.chargerAverageDayLevel2Duration === 0,
          )
        break
      case 'DCFC':
        setUtlizationInsightGaugeTabKey('DCFC')
        transactionsSummary &&
          setUtilizationGaugeEmpty(
            transactionsSummary.summary.chargerAverageDayDcfcDuration === 0 &&
              transactionsSummary.summary.chargerAverageDayDcfcPlugin === 0,
          )
        break
    }
  }

  const pageHeaderTitle = (
    <Space>
      <h5 className="section-sub-header" style={{ marginBottom: 0, paddingTop: '8px' }}>
        {stationOverviewText}
      </h5>
    </Space>
  )

  const operationOverviewTitle = (
    <h5 className="section-sub-header" style={{ marginBottom: '15px', paddingTop: '10px' }}>
      {operationOverviewText}
    </h5>
  )

  const renderSelector = (props: any): React.ReactElement => (
    <ReusableSelector
      {...props}
      showSearch
      isSingle={false}
      maxTagCount={0}
      loading={loading}
      // other common props
    />
  )

  const renderMasterAccountSelector = renderSelector({
    showArrow: false,
    onOptionsChange: (opts: MasterAccountRef[]) => handleMasterAccountSelection(opts),
    onClear: () => handleClearMasterAccounts(),
    customWidth: '160px',
    placeholder: accountNamesText,
    showMagnifySVG: false,
    isDebounceFetcher: true,
    fieldForValue: 'id',
    debounceFetcher: multiMasterAccountDebounceFetcher,
    handlePlaceholder: multiMasterAccountHandlePlaceholder,
    handleOptionChange: multiMasterAccountHandleChange,
    handleValue: multiMasterAccountHandleValue,
    renderOption: renderMultiMasterAccountOption,
    isMockUpEnabled,
  })

  const pageHeaderExtra = (
    <Row gutter={[8, 8]}>
      <Col xs={24} sm={8}>
        <LocationSelector reportsPage onSearch={debounce(handleLocation, 1000)} loading={loading} />
      </Col>
      <Col xs={24} sm={8}>
        {renderMasterAccountSelector}
      </Col>
      <Col xs={24} sm={8}>
        <MultiTenantSelector
          onOptionsChange={debounce(handleTenantSelection, 1000)}
          onClear={() => setTenants([])}
          longerWidth={false}
        />
      </Col>
    </Row>
  )

  const historicalDataSectionExtra = (
    <>
      <Col xs={24} sm={12} md={2} lg={2} xl={2} xxl={2}>
        <CustomDateRangePicker
          startDate={startDate}
          endDate={endDate}
          onChangeDate={onChangeDate}
          calendarRanges={calendarRanges}
        />
      </Col>
      <Col xs={24} sm={7} md={7} lg={7} xl={7} xxl={7}>
        <Button
          type="default"
          onClick={fetchTransactionSummary}
          style={{ marginRight: '5px', width: isResponsiveStyling ? '100%' : '' }}
        >
          <span style={{ fontFamily: theme.fontFamily.lightFontFamily }} className="regular-cap">
            {useFormatMessage('dashboard.homePage.search', 'Search')}
          </span>
        </Button>
      </Col>
    </>
  )

  return (
    <>
      <HomePageContainer isResponsiveStyling={isResponsiveStyling}>
        <AlertError error={error} />
        <RealTimeDataSectionWrapper>
          <PageHeader title={pageHeaderTitle} extra={pageHeaderExtra} />
          <StationOverviewSection
            wsLoading={wsLoading}
            chargerInfo={chargerInfo}
            connectorInfo={connectorInfo}
            chargerStationInfo={chargerStationInfo}
            tenants={tenants}
            masterAccounts={filter?.masterAccounts}
          />
        </RealTimeDataSectionWrapper>
        <SectionGap />
        <HistoricalDataSectionWrapper>
          <Spin spinning={loading}>
            <PageHeader title={operationOverviewTitle} extra={historicalDataSectionExtra} />
            <OperationOverview
              loading={loading}
              startDate={startDate}
              endDate={endDate}
              trnxOverview={trnxOverview}
              operationOverviewTabKey={operationOverviewTabKey}
              onHandleTabChangeOperationOverviewTab={handleTabChangeOperationOverviewTab}
            />
            <MetricsSummary loading={loading} trxProfileSummary={trxProfileSummary} />
            <UtilizationInsightsSection
              utilizationProfileEmpty={utilizationProfileEmpty}
              utilizationInsightChargerProfiles={utilizationInsightChargerProfiles}
              handleTabChangeUtilizationInsightTab={handleTabChangeUtilizationInsightTab}
              utlizationInsightTabKey={utlizationInsightTabKey}
              timeZone={timeZone}
              trxProfileSummary={trxProfileSummary}
              utilizationGauageEmpty={utilizationGauageEmpty}
              handleTabUtilizationInsightChargingDurationTab={handleTabUtilizationInsightChargingDurationTab}
              utlizationInsightGaugeTabKey={utlizationInsightGaugeTabKey}
            />
            <ConsumptionInsightsSection
              loading={loading}
              timeZone={timeZone}
              trxProfileSummary={trxProfileSummary}
              handleTabChangeConsumptionInsightTab={handleTabChangeConsumptionInsightTab}
              averageDailyLoadProfileEmpty={averageDailyLoadProfileEmpty}
              consumptionInsightLoadProfileTabKey={consumptionInsightLoadProfileTabKey}
              dailyProfiles={dailyProfiles}
            />
            <StationActivationSection
              stationActivationDataEmpty={stationActivationDataEmpty}
              stationActivation={stationActivation}
              transactions={transactions}
            />
          </Spin>
        </HistoricalDataSectionWrapper>
      </HomePageContainer>
    </>
  )
}

import { styled, theme } from 'theme'

interface props {
  language: string
}

export const CreateListingFormWrapper = styled.span`
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px;
  }

  .ant-input-number-input {
    height: 38px;
    padding-bottom: 3px;
  }

  .ant-input-number-lg {
    height: 38px;
    padding-bottom: 2px;
  }

  .combine-right.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    border-left: none;
    margin-bottom: 1px;
  }

  .combine-left {
    background: none;
  }

  .ant-input-number-group-addon:first-child {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    background-color: transparent;
  }

  .ant-input-number-group-addon:first-child:hover {
    border-color: ${theme.colors.primary};
    border-right-width: 1px;
  }

  .ant-input-number-group-addon:last-child {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    background-color: transparent;
  }

  .define-new .ant-select-single .ant-select-selection-placeholder {
    position: relative !important;
    left: 0;
    top: 0;
  }
`

export const UpdateListingWrapper = styled.div`
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    border-radius: 0px;
  }
`

export const UpdateListingModalWrapper = styled.div<props>`
  .ant-form-item-label > label {
    font-size: 14px;
    width: ${(props) => (props.language === 'fr' ? '175px' : '135px')};
    margin-right: 8px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px;
  }

  .ant-select-selector > .ant-select-selection-placeholder {
    font-size: 14px;
  }

  .ant-select-single > .ant-select-selector > .ant-select-selection-placeholder {
    padding-top: 3px;
    font-size: 14px;
  }

  .ant-select-selector > .ant-select-selection-item {
    padding-top: 3px;
    font-size: 14px;
  }

  .ant-row {
    display: flex;
    align-items: center;
  }

  .ant-tag {
    padding: 4px 12px;
    align-content: center;
  }

  span.ant-radio + * {
    font-size: 14px;
  }

  .ant-select-multiple span.ant-select-selection-item .ant-select-selection-item-content {
    font-size: 14px;
  }

  .ant-form-item-hidden,
  .ant-form-item-hidden.ant-row {
    display: none;
  }

  .ant-select {
    width: 260px;
  }

  .service-status-help.ant-form-item-with-help .ant-form-item-explain {
    margin: 10px 0;
  }
  .service-status .ant-picker {
    width: 260px;
    height: 38px;
    border-radius: 41px;
  }

  .service-status .ant-form-item-control-input {
    width: 260px;
  }

  .service-status.ant-row.ant-form-item {
    align-items: flex-start;
  }

  #resolution_id {
    justify-content: flex-start;
  }

  #reason {
    justify-content: flex-start;
  }

  .ant-btn > span {
    letter-spacing: 0.05em;
  }

  .service-status
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: ${(props) => (props.language === 'fr' ? '58px' : '22px')};
  }

  .issue-symptom
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: ${(props) => (props.language === 'fr' ? '0px' : '18px')};
  }

  .shorter-red .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    right: ${(props) => (props.language === 'fr' ? '58px' : '40px')};
  }
  .pricing-schema
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: ${(props) => (props.language === 'fr' ? '62px' : '39px')};
  }

  .access-type .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: ${(props) => (props.language === 'fr' ? '73px' : '36px')};
  }

  .car-settings .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: ${(props) => (props.language === 'fr' ? '-2px' : '36px')};
  }

  .map-settings .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: ${(props) => (props.language === 'fr' ? '9px' : '30px')};
  }

  .commission-percent
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: ${(props) => (props.language === 'fr' ? '24px' : '9px')};
  }

  .define-new > .ant-form-item-label > label::after {
    display: none;
  }

  .define-new .ant-select-selection-placeholder {
    padding-top: 0px !important;
  }

  .ant-input-number {
    width: 260px;
  }

  .ant-input-number-input {
    height: 38px;
    font-size: 14px;
    width: 260px;
  }
`

import { theme } from '../../theme'

export const arrowLeftSVG = (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.86561 3.503L2.8797 7.48888M2.8797 7.48888L6.8648 11.474M2.8797 7.48888L11.4797 7.48808C13.6888 7.48788 15.4795 9.27858 15.4793 11.4877L15.4789 15.2022"
      stroke={theme.colors.darkGray}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const multiArrow = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill={theme.colors.white} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1722 12L12.2222 7.05L13.6362 5.636L20.0002 12L13.6362 18.364L12.2222 16.95L17.1722 12Z"
      fill={theme.colors.white}
    />
    <path
      d="M10.1722 12L5.22217 7.05L6.63617 5.636L13.0002 12L6.63617 18.364L5.22217 16.95L10.1722 12Z"
      fill={theme.colors.white}
    />
  </svg>
)

export const rightArrowSVG = (
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L5 5L1 9" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const downArrowSVG = (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 1L5 5L1 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const upArrowSVG = (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 5L5 1L9 5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const refundArrowSVG = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.4993 7.75009C10.4993 7.38896 10.4282 7.03136 10.29 6.69771C10.1518 6.36407 9.94925 6.06091 9.69388 5.80555C9.43852 5.55019 9.13537 5.34762 8.80172 5.20942C8.46807 5.07122 8.11048 5.00009 7.74934 5.00009H2.55934L4.77934 7.22009C4.85303 7.28875 4.91213 7.37155 4.95312 7.46355C4.99411 7.55555 5.01615 7.65487 5.01793 7.75557C5.01971 7.85627 5.00118 7.9563 4.96346 8.04969C4.92574 8.14308 4.8696 8.22791 4.79838 8.29913C4.72716 8.37035 4.64233 8.42649 4.54894 8.46421C4.45555 8.50193 4.35552 8.52046 4.25482 8.51868C4.15411 8.51691 4.0548 8.49486 3.9628 8.45387C3.8708 8.41288 3.788 8.35378 3.71934 8.28009L0.21934 4.78009C0.0788896 4.63947 0 4.44884 0 4.25009C0 4.05134 0.0788896 3.86072 0.21934 3.72009L3.71934 0.220091C3.86151 0.0876106 4.04956 0.0154875 4.24386 0.0189157C4.43816 0.0223439 4.62355 0.101056 4.76096 0.238469C4.89838 0.375882 4.97709 0.561267 4.98052 0.755568C4.98394 0.949869 4.91182 1.13792 4.77934 1.28009L2.55934 3.50009H7.74934C8.87651 3.50009 9.95751 3.94786 10.7545 4.74489C11.5516 5.54192 11.9993 6.62292 11.9993 7.75009C11.9993 8.87726 11.5516 9.95827 10.7545 10.7553C9.95751 11.5523 8.87651 12.0001 7.74934 12.0001H6.74934C6.55043 12.0001 6.35966 11.9211 6.21901 11.7804C6.07836 11.6398 5.99934 11.449 5.99934 11.2501C5.99934 11.0512 6.07836 10.8604 6.21901 10.7198C6.35966 10.5791 6.55043 10.5001 6.74934 10.5001H7.74934C8.11048 10.5001 8.46807 10.429 8.80172 10.2908C9.13537 10.1526 9.43852 9.95 9.69388 9.69463C9.94925 9.43927 10.1518 9.13612 10.29 8.80247C10.4282 8.46882 10.4993 8.11123 10.4993 7.75009Z"
      fill={theme.colors.black}
    />
  </svg>
)

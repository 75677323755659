import { styled, theme } from '../theme'

interface props {
  usersPage?: boolean
  isAdmin?: boolean
  customPadding?: string
}

interface userSelectorProps {
  isLaptop?: boolean
  tablet?: boolean
  showMobileHeader?: boolean
  isAdmin?: boolean
}

export const LineBreak = styled.span<props>`
  border-right: 1px solid ${theme.colors.fadedGrayBlue};
  height: 24px;
  z-index: 2;
  position: absolute;
  left: 320px;
  top: ${(props) => (props.isAdmin ? '8px' : '28px')};
`

export const MultiTenantStyling = styled.div`
  div > .ant-select > .ant-select-selector {
    width: 321px;
    border-radius: 48px 0px 0px 48px !important;
    border-right-width: 0px;
    z-index: 1;
    height: 39px;
  }
`

export const MultiTenantMobileStyling = styled.div`
  width: 100%;
  margin-bottom: 10px;

  div {
    min-width: 0px;
  }

  .ant-input-search {
    border-radius: 48px;
  }

  .ant-input-affix-wrapper {
    border-radius: 48px !important;
    height: 38px;
    border-color: ${theme.colors.primary} !important;
  }

  .ant-input-affix-wrapper:hover {
    border-color: ${theme.colors.primary};
  }

  .ant-input-affix-wrapper-focused:hover,
  :focus,
  :focus-within,
  :active {
    border-color: ${theme.colors.primary};
  }

  div > .ant-select {
    min-width: 0px;
  }

  div > .ant-select > .ant-select-selector {
    width: 100%;
    z-index: 1;
  }
`

export const UserSelectorStyling = styled.div<userSelectorProps>`
  width: 210px;

  svg {
    height: 13px;
  }

  .ant-input-prefix {
    margin-right: 0px;
  }

  .ant-input-group-wrapper > .ant-input-wrapper > .ant-input-affix-wrapper {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    width: ${(props) => (props.tablet ? '290px' : '310px')};
    line-height: 26px;
    border-radius: 0px 48px 48px 0px !important;
    border-left-width: 1px;
    font-size: 13px;
    font-weight: 400;
    border-left-color: transparent;
    margin-left: -1px;
    height: 39px;
  }

  .ant-input-group-wrapper > .ant-input-wrapper > .ant-input-affix-wrapper:hover {
    border-left-width: 1px !important;
    border-color: ${theme.colors.primary};
    box-shadow: none !important;
  }

  .ant-input-group-wrapper > .ant-input-wrapper > .ant-input-affix-wrapper-focused {
    border-left-width: 1px !important;
    border-color: ${theme.colors.primary} !important;
    box-shadow: none !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    border-radius: 0 !important;
    padding-left: 5px;
    padding-top: 1.5px;
    height: 25px;
    font-size: 11px;
  }
`

export const AccessTagContainer = styled.div`
  span {
    white-space: break-spaces;
    margin-bottom: 5px;
  }
`

export const InviteButtonWrapper = styled.div`
  button > svg {
    margin-bottom: 1px;
  }
`

export const SearchButtonContainer = styled.div`
  display: flex;
  align-items: center;
`

export const SearchButtonWrapper = styled.div`
  .ant-btn-primary:hover {
    background-color: ${theme.colors.buttonHovering};
    border-color: ${theme.colors.buttonHovering};
  }

  button > svg {
    margin-top: 4px;
  }
`

export const DriverSvgSpacing = styled.span`
  svg {
    margin-bottom: -5px;
    margin-left: 5px;
    height: 18px;
    width: 18px;
  }
`

export const TableStyling = styled.div<props>`
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background: none;
  }

  .ant-table.ant-table-small .ant-table-title {
    padding: 0;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    text-align: left;
  }

  .ant-table-thead > tr > th {
    text-align: left;
    font-weight: 700;
    font-size: 13px;
    padding: 12px 10px !important;
    line-height: 16px;
    color: ${theme.colors.softBlack} !important;
  }

  .ant-table-tbody > tr > td {
    padding: ${(props) => (props.customPadding ? props.customPadding : '12px 10px !important')};
    text-align: left;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: ${theme.colors.softBlack} !important;
  }

  .ant-pagination-item {
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px !important;
    width: 36px;
    border: none;
  }

  .ant-pagination-item a {
    color: ${theme.colors.black};
  }

  .ant-pagination-prev::after {
    top: ${(props) => (props.usersPage ? '3px' : '5px')};
  }

  .ant-spin-container {
    display: flex;
    flex-direction: column;
    min-height: 83vh;
    justify-content: space-between;
    background-color: white;
  }

  .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-pagination
    > ant-pagination-next
    > .ant-pagination-item-link
    > .anticon,
  .anticon-right {
    display: inline !important;
  }

  .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
    background-color: ${theme.colors.ternary25};
    border: 1px ${theme.colors.cultered} solid;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    display: none;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev,
  .ant-pagination-prev {
    margin-right: 8px !important;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev,
  .ant-pagination-prev {
    margin-left: 8px !important;
  }

  .ant-table-pagination.ant-pagination {
    margin-bottom: 40px;
    align-items: center;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${theme.colors.primary};
  }

  a {
    color: ${theme.colors.primary};
  }
`

export const CustomBox = styled.div<userSelectorProps>`
  background-color: ${theme.colors.ternary25};
  margin: 0;
  min-height: 84vh;

  .tabs-styling > .ant-tabs-nav {
    margin-bottom: 20px;
    margin-top: 12px;
    display: ${(props) => (!props.showMobileHeader ? 'inline-block' : 'flex')};
    flex-direction: column;
  }

  .tabs-styling > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
    margin-bottom: ${(props) => (!props.showMobileHeader ? '0' : '45px')};
  }

  .subscriptions-header {
    margin-top: ${(props) => (!props.showMobileHeader ? '0' : '55px')};
  }

  .plans-button {
    margin-top: ${(props) => (props.showMobileHeader ? '50px' : '0')};
    width: ${(props) => (props.showMobileHeader ? '100%' : 'fit-content%')};

    button {
      width: 100%;
    }
  }
`

export const EditButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const TabsButtonWrapper = styled.div<userSelectorProps>`
  position: absolute;
  right: 0;
  top: 0;

  &.subscriptions-header {
    display: flex;
    gap: 15px;
  }

  .subscription-email-search {
    width: 244px !important;
    padding-top: 0px !important;
    margin-right: ${(props) => (props.isLaptop ? '0' : '20px')};
  }

  .subscription-email-search > .ant-input-group > .ant-input-affix-wrapper:first-child {
    height: 39px;
    border-radius: 20px;
    padding-right: 0px;
  }

  .subscription-email-search > .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    padding-left: 5px;
    height: 25px;
    font-size: 13px;
  }

  .subscription-email-search > .ant-input-group > .ant-input-affix-wrapper:hover {
    border-color: ${theme.colors.primary};
    box-shadow: none;
  }

  .subscription-email-search > .ant-input-group > .ant-input-affix-wrapper-focused {
    border-color: ${theme.colors.primary};
    box-shadow: none;
  }

  &.discount-plans-button {
    right: 153px;

    .ant-btn-primary {
      background: ${theme.colors.white};
      border: 1px solid ${theme.colors.lightGray};
    }
  }
`

export const TabsWrapper = styled.div`
  .tabs-styling > .ant-tabs-nav > .ant-tabs-extra-content {
    width: 67%;
  }

  .tabs-styling > .ant-tabs-nav > .ant-tabs-extra-content > .ant-row > .user-selector > span {
    top: 8px;
  }

  .tabs-styling > .ant-tabs-nav > .ant-tabs-extra-content > .ant-row > .user-selector > div > svg {
    top: 19px;
  }

  .tabs-styling > .ant-tabs-nav > .ant-tabs-extra-content > .ant-row > .ant-space {
    position: absolute;
    right: 0;
    top: 0;
  }

  .tabs-styling > .ant-tabs-nav > .ant-tabs-extra-content > .ant-row > .user-selector {
    display: flex;
    position: absolute;
    left: 358px;
    top: 0;
  }
`

export const InviteButtonCircleWrapper = styled.div`
  button {
    height: 38px;
    width: 38px;
  }

  svg {
    margin-left: 0px;
    margin-bottom: 0px;
  }
`

export const UserTitleMobileWrapper = styled.div<userSelectorProps>`
  display: flex;
  margin-top: ${(props) => (props.isAdmin ? '-45px' : '0')};
`

export const UserWalletActionButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 10px;
  padding-left: 5px;
  width: 47px;
`

// Add utility function to convert JSON to CSV
const convertJsonToCsv = (jsonData: any[]): string => {
  if (!jsonData || !jsonData.length) return ''

  // Extract column headers from the first object
  const headers = Object.keys(jsonData[0])

  // Create CSV header row
  const csvRows = [headers.join(',')]

  // Create CSV data rows
  for (const row of jsonData) {
    const values = headers.map((header) => {
      const value = row[header]
      // Handle special characters and commas in the data
      const escaped = typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
      return escaped
    })
    csvRows.push(values.join(','))
  }

  return csvRows.join('\n')
}

// Function to create a downloadable CSV from JSON data
export const createDownloadableCSV = (jsonData: any[]): string => {
  const csvData = convertJsonToCsv(jsonData)
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
  return URL.createObjectURL(blob)
}

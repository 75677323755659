import { theme } from '../../theme'

export const UserCloneSVG = (props: any) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clip-path="url(#clip0_8317_81420)">
      <path
        d="M13.1261 5.5C13.1261 6.3288 12.7969 7.12366 12.2108 7.70971C11.6248 8.29576 10.8299 8.625 10.0011 8.625C9.17232 8.625 8.37746 8.29576 7.79141 7.70971C7.20536 7.12366 6.87612 6.3288 6.87612 5.5C6.87612 4.6712 7.20536 3.87634 7.79141 3.29029C8.37746 2.70424 9.17232 2.375 10.0011 2.375C10.8299 2.375 11.6248 2.70424 12.2108 3.29029C12.7969 3.87634 13.1261 4.6712 13.1261 5.5ZM3.75195 17.265C3.77873 15.6253 4.4489 14.0618 5.61793 12.9117C6.78697 11.7616 8.36119 11.1171 10.0011 11.1171C11.641 11.1171 13.2153 11.7616 14.3843 12.9117C15.5533 14.0618 16.2235 15.6253 16.2503 17.265C14.2901 18.1648 12.158 18.6288 10.0011 18.625C7.77112 18.625 5.65445 18.1383 3.75195 17.265Z"
        stroke={theme.colors.darkGray}
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.91042 12.7441L5.31998 12.1536C4.77485 12.6988 4.68583 13.5273 5.05294 14.165H1.83366C0.912263 14.165 0.165325 14.9119 0.165325 15.8333C0.165325 16.7547 0.912263 17.5017 1.83366 17.5017H5.05294C4.68583 18.1394 4.77485 18.9679 5.31998 19.513C5.97151 20.1645 7.02784 20.1645 7.67936 19.513L10.1794 17.013C10.4922 16.7001 10.668 16.2758 10.668 15.8333C10.668 15.3909 10.4922 14.9665 10.1794 14.6536L7.67936 12.1536M5.91042 12.7441L7.67936 12.1536M5.91042 12.7441L5.31998 12.1536M5.91042 12.7441L5.31998 12.1536M7.67936 12.1536C7.02784 11.5021 5.97151 11.5021 5.31998 12.1536M7.67936 12.1536L5.31998 12.1536"
        fill={theme.colors.white}
        stroke={theme.colors.darkGray}
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8317_81420">
        <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)

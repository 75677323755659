import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useEffect, useState } from 'react'

import { useFormatMessage } from '../../../../helpers/intl'
import { SummaryStationActivation } from '../../../../models/transaction'
import { useAppState } from '../../../../state'
import { styled, theme } from '../../../../theme'

const HomePageStationActivationContainer = styled.div`
  .highcharts-color-3 {
    fill: ${(props) => props.theme.colors.pieGraphPrimary};
    stroke: ${(props) => props.theme.colors.pieGraphPrimary};
  }

  .highcharts-color-1 {
    fill: ${(props) => props.theme.colors.pieGraphTernary};
    stroke: ${(props) => props.theme.colors.pieGraphTernary};
  }

  .highcharts-color-2 {
    fill: ${(props) => props.theme.colors.pieGraphQuaternary};
    stroke: ${(props) => props.theme.colors.pieGraphQuaternary};
  }

  .highcharts-color-0 {
    fill: ${(props) => props.theme.colors.pieGraphSecondary};
    stroke: ${(props) => props.theme.colors.pieGraphSecondary};
  }

  div[data-highcharts-chart],
  .highcharts-container,
  svg.highcharts-root {
    overflow: visible !important;
  }

  .highcharts-background {
    fill: transparent;
  }

  .highcharts-data-label {
    font-size: 1.17em;
    opacity: 1 !important;
  }

  .highcharts-data-label text {
    font-family: 'AkkuratLLWeb-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
  }

  .highcharts-pie-series .highcharts-point {
    -webkit-filter: none !important;
    filter: none !important;
    stroke-width: 0 !important;
  }

  div[data-highcharts-chart] {
    margin: 0 auto;
  }

  .paragraph-02-regular tspan {
    font-weight: bold;
    fill: #000;
  }

  .highcharts-legend {
    pointer-events: none;
  }
`

interface props {
  stationActivation: SummaryStationActivation
}

export const HomePageStationActivation: React.FC<props> = ({ stationActivation }) => {
  const webApp = useFormatMessage('dashboard.transactionsPage.advancedSearch.startMethod.website', 'Website')
  const roamingPartner = useFormatMessage(
    'dashboard.transactionsPage.advancedSearch.startMethod.ocpi',
    'Roaming Partner',
  )
  const mobileApp = useFormatMessage('dashboard.transactionsPage.advancedSearch.startMethod.mobile', 'Mobile')
  const rfid = useFormatMessage('dashboard.transactionsPage.advancedSearch.startMethod.rfid', 'RFID')
  const posTerminal = useFormatMessage(
    'dashboard.transactionsPage.advancedSearch.startMethod.posterminal',
    'POS Terminal',
  )
  const sms = useFormatMessage('dashboard.transactionsPage.advancedSearch.startMethod.Sms', 'SMS')

  const { language } = useAppState()

  const [stationActivationChartOptions, setStationActivationChartOptions] = useState({
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      styled: true,
      events: {
        render: function () {
          //@ts-ignore
          this.reflow()
        },
      },
    },
    title: {
      text: '',
      align: 'left',
    },
    tooltip: {
      enabled: false,
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },

    legend: {
      layout: 'horizontal',
      backgroundColor: '#FFFFFF',
      align: 'left',
      verticalAlign: 'bottom',
      floating: true,
      itemMarginTop: 10,
      itemMarginBottom: 10,
      width: '106%',
      symbolRadius: 3,
      itemStyle: {
        color: '#858a88',
        fontFamily: 'AkkuratLLWeb-Regular',
        fontWeight: 400,
        cursor: 'auto',
      },
      enableMouseTracking: false,
      labelFormatter: function (): any {
        //@ts-ignore

        var output = `<span class='paragraph-02-regular seriesName'> ${
          //@ts-ignore
          this.name
          //@ts-ignore
        } </span> <span class='paragraph-02-regular'><b> (${this.y})</b></span>`
        return output
      },
      x: 10,
      y: 130,
    },

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        innerSize: 90,
        dataLabels: {
          enabled: true,
          distance: -40,
          style: {
            textOutline: null,
          },
          //@ts-ignore
          formatter: function () {
            //@ts-ignore
            if (this.y > 0) {
              //@ts-ignore
              return Highcharts.numberFormat(this.point.percentage, 1) + ' %'
            }
          },
        },
        showInLegend: true,
        center: ['50%', '50%'],
        size: '80%',
        slicedOffset: 1,
      },
      series: {
        states: {
          inactive: {
            enabled: false,
          },
        },
      },
    },

    series: [
      {
        name: 'Station Activation',
        colorByPoint: true,
        data: [
          {
            name: mobileApp,
            y: 0,
            color: '#54dec7',
            sliced: false,
            legendIndex: 1,
          },
          {
            name: webApp,
            y: 0,
            color: '#C8F9E8',
            sliced: false,
            legendIndex: 2,
          },
          {
            name: 'RFID',
            y: 0,
            color: '#026E78',
            sliced: false,
            legendIndex: 3,
          },
          {
            name: roamingPartner,
            y: 0,
            color: '#242e2c',
            sliced: false,
            legendIndex: 4,
          },
        ],
        point: {
          events: {
            mouseOver: function () {
              const point = this,
                //@ts-ignore
                chart = this.series.chart,
                //@ts-ignore
                legendItemGroup = this.legendItem.group,
                { y, height, width } = legendItemGroup.element.getBBox()
              //@ts-ignore
              const isOnRight = this.legendIndex % 2 === 0

              //@ts-ignore
              const initialX = this.series.data[0].legendItem.x
              //@ts-ignore
              const isVertial = this.series.data.every((item) => {
                return item.legendItem.x === initialX
              })
              chart.renderedLegendRect = chart.renderer
                .rect(
                  isVertial ? -50 : isOnRight ? -10 : -50,
                  y - 10,
                  isVertial ? 500 : isOnRight ? width + 200 : width + 120,
                  height + 20,
                )
                .attr({
                  fill: theme.colors.grey13,
                  stroke: theme.colors.grey13,
                })
                .add(legendItemGroup)
              //@ts-ignore
              point.graphic.attr({
                transform: `scale(1.08) `,
                'transform-origin': 'center',
              })
              //@ts-ignore
              point.graphic.css({
                transition: 'transform .5s',
              })
            },
            mouseOut: function () {
              const point = this
              //@ts-ignore
              point.series.chart.renderedLegendRect.destroy()
              //@ts-ignore
              point.graphic.attr({
                transform: `scale(1) `,
                'transform-origin': 'center',
              })
            },
          },
        },
        states: {
          hover: {
            opacity: 1,
            halo: null,
          },
        },
      },
    ],
  })

  useEffect(() => {
    setStationActivationChartOptions({
      ...stationActivationChartOptions,
      series: [
        {
          ...stationActivationChartOptions.series[0],
          data: [
            {
              name: webApp,
              y: stationActivation.web || 0,
              color: theme.colors.pieGraphSecondary,
              sliced: false,
              legendIndex: 2,
            },
            {
              name: rfid,
              y: stationActivation.rfid || 0,
              color: theme.colors.pieGraphTernary,
              sliced: false,
              legendIndex: 3,
            },
            {
              name: roamingPartner,
              y: stationActivation.roaming || 0,
              color: theme.colors.pieGraphQuaternary,
              sliced: false,
              legendIndex: 4,
            },

            {
              name: mobileApp,
              y: stationActivation.mobile || 0,
              color: theme.colors.pieGraphPrimary,
              sliced: false,
              legendIndex: 1,
            },
            {
              name: sms,
              y: stationActivation.sms || 0,
              color: theme.colors.pieGraphQuinary,
              sliced: false,
              legendIndex: 5,
            },
            {
              name: posTerminal,
              y: stationActivation.PosTerminal || 0,
              color: theme.colors.pieGraphSenary,
              sliced: false,
              legendIndex: 6,
            },
          ],
        },
      ],
    })
  }, [stationActivation, language])

  return (
    <HomePageStationActivationContainer>
      <HighchartsReact highcharts={Highcharts} options={stationActivationChartOptions} />
    </HomePageStationActivationContainer>
  )
}

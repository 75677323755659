import { addQueryString } from 'helpers/url'
import { log } from 'logger'
import { mockWalletHistory, mockWalletHistoryResponse, mockWalletInfo } from 'mock/activities-mock'
import { WalletDataResponse } from 'models/http'
import { SuccessResponse, WalletData, WalletInfo } from 'models/user'
import { apiClient } from './client'
import { returnMock } from './mock'

export async function GetWalletHistory(
  isMockUpEnabled: boolean,
  person_id: string,
  page: number,
): Promise<WalletDataResponse> {
  let url = `/activities`
  let queryParams: { [key: string]: string | number } = { person_id, page }

  url = addQueryString(url, queryParams)

  log('getting wallet history', { url })
  if (isMockUpEnabled) {
    return returnMock(mockWalletHistoryResponse)
  }
  return apiClient(url, {})
}

export async function GetWholeWalletHistory(isMockUpEnabled: boolean, person_id: string): Promise<WalletData[]> {
  let url = `/activities`
  let queryParams: { [key: string]: string | number } = { person_id, all_activities: 'true' }

  url = addQueryString(url, queryParams)

  log('getting whole wallet history', { url })
  if (isMockUpEnabled) {
    return returnMock(mockWalletHistory)
  }
  return apiClient(url, {})
}

export async function RefundBalance(isMockUpEnabled: boolean, person_id: string): Promise<SuccessResponse> {
  let url = `/activities/refund_balance`
  let queryParams: { [key: string]: string } = { person_id }

  url = addQueryString(url, queryParams)

  log('refunding balance', { url })
  if (isMockUpEnabled) {
    return returnMock({ message: "Successfully refunded to user's payment method" })
  }
  return apiClient(url, {
    method: 'POST',
  })
}

export async function GetWalletInfo(isMockUpEnabled: boolean, person_id: string): Promise<WalletInfo> {
  let url = `/activities/wallet_info`
  let queryParams: { [key: string]: string } = { person_id }

  url = addQueryString(url, queryParams)

  log('getting wallet information', { url })
  if (isMockUpEnabled) {
    return returnMock(mockWalletInfo)
  }
  return apiClient(url, {})
}

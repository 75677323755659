import { Form, Input, Select } from 'antd'

import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { useSelectorTranslation } from 'hooks/translation/useSelectorTranslation'
import { ServiceStatusReasons } from 'models/listing'
import { ServiceStatusSectionHeader } from './service-status-section-header'
import { MustBeRequired } from 'components/rules/rules'
import { useState } from 'react'

interface props {
  loading: boolean
  currentReasons: ServiceStatusReasons[]
}

export const IssueDetailsSection: React.FC<props> = ({ loading, currentReasons }) => {
  const {
    characterMaxLimitText,
    issueDetailsText,
    issueSymptomText,
    detailsText,
    issueSymptomIsRequired,
  } = useListingBasicTrans()

  const { selectReasonForStatusChangeText } = useSelectorTranslation()

  const title = issueDetailsText

  const [selectedSymptom, setSelectedSymptom] = useState<string | null>(null)

  return (
    <ServiceStatusSectionHeader title={title}>
      <Form.Item
        name="root_cause_id"
        className="issue-symptom"
        label={issueSymptomText}
        style={{ alignItems: 'flex-start' }}
        rules={selectedSymptom ? [] : [MustBeRequired(issueSymptomIsRequired)]}
      >
        <Select
          placeholder={selectReasonForStatusChangeText}
          disabled={loading}
          loading={loading}
          onChange={(value) => setSelectedSymptom(value)}
        >
          {currentReasons.map((option) => (
            <Select.Option value={option.id}>{option.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="event_notes"
        rules={[{ required: false, max: 255, message: characterMaxLimitText }]}
        label={detailsText}
        className="service-status"
      >
        <Input.TextArea placeholder={detailsText} />
      </Form.Item>
    </ServiceStatusSectionHeader>
  )
}

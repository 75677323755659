import { DollarOutlined, DownOutlined, EditFilled } from '@ant-design/icons'
import { Dropdown, Menu, Space } from 'antd'

import EVChargerSVG from 'assets/svg/ev_charging'
import { plugGreySVG } from 'assets/svg/plug'
import { Links } from 'components/routes/paths'

import { getHighlightedText, renderFormatMessage } from 'helpers/intl'
import { buildRailsListingUrl } from 'helpers/url'

import { useChargerTranslation } from 'hooks/translation/useChargerTranslation'
import { useListingsTranslation } from 'hooks/translation/useListingsTranslation'
import { NewListing } from 'models/listing'
import { useEffect, useState } from 'react'

import { useAppState } from 'state'
import { theme } from 'theme'
import CreatePricingAndDiscountSchemaModal from 'components/tenants/modal/create-pricing-and-discount-modal'
import { Tenant } from 'models/tenant'
import { GetListingPricingSchema } from 'services/data-provider/listing'
import { SwtchApiError, SwtchError } from 'models/error'
import { PricingSchema } from 'models/price'
import EditPricingSchemaModal from 'components/tenants/modal/edit-pricing-schema-modal'
import { GetDiscounts } from 'services/data-provider/discount'
import { DiscountSchema } from 'models/discount'

const menuStyle = {
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.frenchGray}`,
  borderRadius: '5px',
}

const { protocol, hostname } = window.location

interface ChargerActionsMenuProps {
  listing: NewListing
  tenant: Tenant
  reloadTable: () => void
}

const ActionsMenu = ({ listing, tenant, reloadTable }: ChargerActionsMenuProps) => {
  const { currentUser, isMockUpEnabled, language } = useAppState()
  const isAdmin = currentUser?.role === 'admin'
  const isManager =
    !isAdmin &&
    currentUser?.accesses.some((access) =>
      access.permissions.some(
        (permission) => permission === 'tenant.manager' || permission === 'tenant.charger_operator',
      ),
    )

  const [openCreatePricingPlanModal, setOpenCratePricingPlanModal] = useState(false)
  const [openEditPricingPlanModal, setOpenEditPricingPlanModal] = useState(false)

  const [selectedPricingSchema, setSelectedPricingSchema] = useState<PricingSchema | undefined>()
  const [discounts, setDiscounts] = useState<DiscountSchema[]>([])

  const {
    viewChargerInAppText,
    viewChargerDetailsText,
    updateAllChargersText,
    updateThisChargersText,
  } = useChargerTranslation()
  const { editListingDetailsText } = useListingsTranslation()

  useEffect(() => {
    if (!(openEditPricingPlanModal || openCreatePricingPlanModal)) {
      resetSelectedPricingSchema()
      setDiscounts([])
    } else {
      loadDiscounts()
    }
  }, [openEditPricingPlanModal, openCreatePricingPlanModal])

  const handleCreatePricingPlanModal = () => setOpenCratePricingPlanModal(!openCreatePricingPlanModal)

  const handleEditPricingPlanModal = () => setOpenEditPricingPlanModal(!openEditPricingPlanModal)

  const resetSelectedPricingSchema = () => setSelectedPricingSchema(undefined)

  const mockChargerBasedOnEnvironment = () => {
    let chargerId = 68
    let connectorId = 85
    if (window.location.hostname.includes('staging.admin.swtchenergy.com')) {
      // ! Charger 5SW2-1-2141-00091 (FR05)
      chargerId = 68
      connectorId = 85
    } else if (window.location.hostname.includes('preprod.admin.swtchenergy.com')) {
      // ! Charger 1EC3-1-1917-00010 (FR02)
      chargerId = 241
      connectorId = 269
    } else if (window.location.hostname.includes('admin.swtchenergy.com')) {
      // ! using charger AXLU111001D1S1V2238A093A0 (P5342) for demo purposes
      chargerId = 4506
      connectorId = 827
    }
    return [chargerId, connectorId]
  }

  const afterSave = (type: 'create' | 'edit') => () => {
    if (type === 'create') {
      handleCreatePricingPlanModal()
    } else if (type === 'edit') {
      handleEditPricingPlanModal()
    }
    reloadTable()
  }

  const renderChargerDetailsLink = () => {
    if (listing.charger?.id && listing.connector?.id) {
      const [chargerId, connectorId] = mockChargerBasedOnEnvironment()
      return isMockUpEnabled ? (
        <a
          key={`${listing.id}-charger`}
          href={`${protocol}//${hostname}/chargers/${chargerId}/${connectorId}`}
          target="_blank"
          rel="noreferrer"
          aria-label={`View details for charger ${listing.charger.id}`}
        >
          {viewChargerDetailsText}
        </a>
      ) : (
        <a
          key={`${listing.id}-charger`}
          href={Links.charger({
            chargerId: listing.charger.id,
            connectorId: listing.connector.id,
          })}
          target="_blank"
          rel="noreferrer"
          aria-label={`View details for charger ${listing.charger.id}`}
        >
          {viewChargerDetailsText}
        </a>
      )
    }
    return <span>{viewChargerDetailsText}</span>
  }

  const renderChargerStatusLink = () => {
    if (listing.id) {
      return (
        <a
          key={`${listing.id}-${listing.title}`}
          href={buildRailsListingUrl(listing)}
          target="_blank"
          rel="noreferrer"
          aria-label={`View charger status for listing ${listing.title}`}
        >
          {viewChargerInAppText}
        </a>
      )
    }
    return <span>{viewChargerInAppText}</span>
  }

  const renderEditLink = () => {
    if (listing.id) {
      return (
        <a
          key={`${listing.id}-${listing.title}`}
          href={Links.listing({ listingId: listing.id })}
          target="_blank"
          rel="noreferrer"
        >
          {editListingDetailsText}
        </a>
      )
    }
    return <span>{editListingDetailsText}</span>
  }

  const loadDiscounts = async () => {
    GetDiscounts(isMockUpEnabled, Number(tenant.id))
      .then((resp: DiscountSchema[]) => {
        setDiscounts(resp)
      })
      .catch((err: SwtchError) => console.log('error', err))
  }

  const loadExistingPriceSchemaForListing = (listingId: number, openFunction: () => void) => async () => {
    try {
      const response = await GetListingPricingSchema(listingId, isMockUpEnabled)
      setSelectedPricingSchema(response)
      openFunction()
    } catch (e) {
      const priceSchemaNotExist = (((e as SwtchApiError)?.cause as any)?.description || '').startsWith(
        'Entity not found',
      )
      if (priceSchemaNotExist) {
        handleCreatePricingPlanModal()
      }
    }
  }

  const renderUpdatePricingForAllChargers = () => {
    const displayText = getHighlightedText(updateAllChargersText, language === 'en' ? 'ALL' : 'TOUS')
    if (listing.id) {
      return (
        <button
          key={`${listing.id}-${listing.title}-update`}
          onClick={loadExistingPriceSchemaForListing(listing.id, handleEditPricingPlanModal)}
          className="no-style-button"
        >
          {displayText}
        </button>
      )
    }
    return <span>{displayText}</span>
  }

  const renderUpdatePricingForThisChargers = () => {
    const displayText = getHighlightedText(updateThisChargersText, language === 'en' ? 'THIS' : 'CE')
    if (listing.id) {
      return (
        <button
          key={`${listing.id}-${listing.title}-update`}
          onClick={loadExistingPriceSchemaForListing(listing.id, handleCreatePricingPlanModal)}
          className="no-style-button"
        >
          {displayText}
        </button>
      )
    }
    return <span>{displayText}</span>
  }

  const menu = (
    <Menu style={menuStyle}>
      <Menu.Item
        disabled={!listing.charger || !listing.connector}
        aria-disabled={!listing.charger || !listing.connector}
        icon={plugGreySVG}
      >
        {renderChargerDetailsLink()}
      </Menu.Item>
      <Menu.Item disabled={!listing.id} icon={<EVChargerSVG color={theme.colors.darkGray} />}>
        {renderChargerStatusLink()}
      </Menu.Item>
      {(isAdmin || isManager) && tenant.networkPackage !== 'energy_monitoring' && tenant.networkPackage !== 'lite' && (
        <>
          <Menu.Item
            disabled={!listing.id}
            aria-disabled={!listing.id}
            icon={<DollarOutlined style={{ fontSize: '20px', color: theme.colors.darkGray, marginRight: 0 }} />}
          >
            {renderUpdatePricingForAllChargers()}
          </Menu.Item>
          <Menu.Item
            disabled={!listing.id}
            icon={<DollarOutlined style={{ fontSize: '20px', color: theme.colors.darkGray, marginRight: 0 }} />}
          >
            {renderUpdatePricingForThisChargers()}
          </Menu.Item>
        </>
      )}
      {isAdmin ? (
        <Menu.Item
          disabled={!listing.id}
          icon={<EditFilled style={{ fontSize: theme.fontSizes[3], color: theme.colors.darkGray, width: '20px' }} />}
        >
          {renderEditLink()}
        </Menu.Item>
      ) : null}
    </Menu>
  )

  return (
    <>
      {openCreatePricingPlanModal && (
        <CreatePricingAndDiscountSchemaModal
          tenant={tenant}
          onCancel={handleCreatePricingPlanModal}
          defaultListings={[listing]}
          onlyPricing
          hideMaxLimit={isManager}
          onOk={afterSave('create')}
          discounts={discounts}
        />
      )}
      {openEditPricingPlanModal && selectedPricingSchema && (
        <EditPricingSchemaModal
          tenant={tenant}
          disableChargerId
          onCancel={handleEditPricingPlanModal}
          selectedPricingSchema={selectedPricingSchema}
          hideMaxLimit={isManager}
          onOk={afterSave('edit')}
          discounts={discounts}
        />
      )}
      <Dropdown overlay={menu} overlayClassName="custom-dropdown" aria-haspopup="true" aria-controls="actions-menu">
        <span onClick={(e) => e.preventDefault()} role="button" tabIndex={0} aria-label="More actions">
          <Space>
            <span style={{ color: theme.colors.softBlack, textTransform: 'uppercase' }}>
              {renderFormatMessage('dashboard.text.more', 'More')}
            </span>
            <DownOutlined style={{ color: theme.colors.softBlack }} />
          </Space>
        </span>
      </Dropdown>
    </>
  )
}

export default ActionsMenu

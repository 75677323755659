import { apiClient2, apiMetabaseClient } from './client'
import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import { MessageResponse, MetabaseReportsResponse } from '../../models/reports'
import { reportsQuery } from '../../helpers/filter/reports'
import { ReportFilter } from '../../models/filter'
import { returnMock } from './mock'

export async function GetMetabaseReport(
  isMockUpEnabled: boolean,
  params: ReportFilter,
): Promise<MetabaseReportsResponse> {
  let url = '/report/get-metabase-report-url'

  const queryParams = reportsQuery(params)

  url = addQueryString(url, queryParams)

  log('GetMetabaseReport', url)

  if (isMockUpEnabled) {
    url = `${url}&isMockUpEnabled=true&metabase_dashboard_id=89`
    // ! use this to avoid metabase call locally
    // return {
    //   data: [{ url: 'https://google.ca' }],
    //   code: 200,
    // }
  }

  return apiMetabaseClient(url, {})
}

export async function GetReportThroughAI(message: string, isMockUpEnabled: boolean): Promise<MessageResponse> {
  let url = '/v1/report/reporting-pilot'

  log('Get message from AI', { url })
  if (isMockUpEnabled) {
    return returnMock({
      success: true,
      data: 'Hello World',
      isFile: false,
    })
  }
  return apiClient2(url, {
    method: 'POST',
    body: JSON.stringify({ message }),
  })
}

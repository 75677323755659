import Highcharts from 'highcharts'
import { useMediaQuery } from 'react-responsive'
import HighchartsReact from 'highcharts-react-official'
import HC_exportData from 'highcharts/modules/export-data'
import HC_exporting from 'highcharts/modules/exporting'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { ChargerProfile } from '../../../../models/transaction'
import { useAppState } from '../../../../state'
import { HomePageChargerUtilization } from '../../../../atom/chart/chart'
import { useFormatMessage } from 'helpers/intl'
import { theme } from 'theme'

HC_exporting(Highcharts)
HC_exportData(Highcharts)

const borderRadius = require('highcharts-border-radius')
borderRadius(Highcharts)

const handleFileName = (tabKey: string) => {
  return `Utilization Insights ${tabKey} Data ${moment().format('YYYY-DD-MM')}`
}

interface props {
  tabKey: string
  chargerProfile: ChargerProfile[]
  timezone?: string
  chartRef: React.MutableRefObject<null>
}

export const HomePageUtilizationInsightsChart: React.FC<props> = ({ tabKey, chargerProfile, timezone, chartRef }) => {
  const timzeZoneText = useFormatMessage('dashboard.homePage.operationoverview.timeZone.heading', 'Time (EST)')
  const { language } = useAppState()
  const isResponsiveStyling = useMediaQuery({ minWidth: 0, maxWidth: 649 })

  const [utilizationInsightOptions, setUtilizationInsightOptions] = useState({
    chart: {
      type: 'column',
      styled: true,
      height: 400,
      style: {
        fontFamily: theme.fontFamily.regularFontFamily,
        fontWeight: '400',
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      headerFormat: '<table>',
      footerFormat: '</table>',
      style: {
        color: '#fff',
        fontFamily: theme.fontFamily.regularFontFamily,
        fontSize: '11px',
        fontWeight: 700,
        textAlign: 'center',
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: '',
    },
    //   tooltip: {},
    plotOptions: {
      groupPadding: 0,
      pointWidth: 15,
      pointPadding: 0,
      borderWidth: 0,
      series: {
        stacking: 'normal',
      },
      column: {
        borderRadiusTopLeft: 5,
        borderRadiusTopRight: 5,
      },
    },
    xAxis: {
      categories: [''],
      tickWidth: 0,
      fontSize: '11px',
      labels: {
        rotation: -45,
        style: {
          fontFamily: theme.fontFamily.regularFontFamily,
          fontWeight: 400,
          color: '#000',
          fontSize: '11px',
        },
      },
      title: {
        text: '',
        margin: 20,
        style: {
          fontFamily: theme.fontFamily.regularFontFamily,
          fontWeight: 400,
          fontSize: theme.fontSizes[2],
        },
      },
    },
    yAxis: {
      max: 40,
      maxPadding: 0,
      allowDecimals: false,
      title: {
        text: 'hi there',
        style: {
          fontSize: theme.fontSizes[2],
        },
      },
      labels: {
        style: {
          fontFamily: theme.fontFamily.regularFontFamily,
          fontWeight: 400,
          color: '#000',
          fontSize: '11px',
        },
      },
      gridLineDashStyle: 'dash',
    },
    series: [
      {
        name: 'Fill Series',
        legendIndex: 1,
        color: '#242E2C',
        opacity: 0.2,
        data: [1],
        enableMouseTracking: false,
      },

      {
        name: '# of Chargers In Use',
        LegendIndex: 0,
        color: theme.colors.primary,
        data: [1],
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
      csv: {
        dateFormat: '%Y-%m-%d',
      },
      filename: '',
      chartOptions: {
        chart: {
          style: {
            fontFamily: theme.fontFamily.regularFontFamily,
          },
        },
        title: {
          style: {
            fontFamily: theme.fontFamily.regularFontFamily,
            fontWeight: 400,
            fontSize: '10px',
          },
        },
        xAxis: {
          title: {
            style: {
              fontFamily: theme.fontFamily.regularFontFamily,
              fontWeight: 400,
              fontSize: '8px',
            },
          },
          labels: {
            style: {
              fontFamily: theme.fontFamily.regularFontFamily,
              fontWeight: 400,
              fontSize: '8px',
            },
          },
        },
        yAxis: {
          title: {
            text: '# of Chargers In Use',
            style: {
              fontFamily: theme.fontFamily.regularFontFamily,
              fontWeight: 400,
              fontSize: '8px',
            },
          },
          labels: {
            style: {
              fontFamily: theme.fontFamily.regularFontFamily,
              fontWeight: 400,
              fontSize: '8px',
            },
          },
        },
      },
    },
  })

  useEffect(() => {
    const timeSeries = chargerProfile.map((obj) => obj.time)
    const chargerInUseWeek = chargerProfile.map((obj) => obj.chargerInUseWeek)
    const chargerInUseWeekday = chargerProfile.map((obj) => obj.chargerInUseWeekday)
    const chargerInUseWeekend = chargerProfile.map((obj) => obj.chargerInUseWeekend)
    const chargerInUseWeekMax = Math.max(...chargerInUseWeek)
    const chargerInUseWeekdayMax = Math.max(...chargerInUseWeekday)
    const chargerInUseWeekendMax = Math.max(...chargerInUseWeekend)
    const chargerInUseWeekFill = chargerProfile.map((obj) => Number.MAX_VALUE - obj.chargerInUseWeek)
    const chargerInUseWeekdayFill = chargerProfile.map((obj) => Number.MAX_VALUE - obj.chargerInUseWeekday)
    const chargerInUseWeekendFill = chargerProfile.map((obj) => Number.MAX_VALUE - obj.chargerInUseWeekend)

    const getUtilizationInsightOptions: any = (
      tabKey: string,
      timezone: string,
      timeSeries: string[],
      chargerInUseWeekMax: number,
      chargerInUseWeekFill: number[],
      chargerInUseWeek: number[],
      handleFileName: (tabKey: string) => string,
    ) => {
      const commonXAxis = {
        title: {
          text: timezone && timezone === 'America/Toronto' ? timzeZoneText : 'Time (Hourly)',
        },
        categories: timeSeries,
      }

      const commonYAxis = {
        title: { text: 'Total # of Chargers' },
        max: chargerInUseWeekMax,
        style: {
          fontSize: theme.fontSizes[2],
        },
      }

      const commonSeries = [
        {
          data: chargerInUseWeekFill,
        },
        {
          data: chargerInUseWeek,
        },
      ]

      const commonExporting = {
        filename: handleFileName(tabKey),
      }

      return {
        xAxis: commonXAxis,
        yAxis: commonYAxis,
        series: commonSeries,
        exporting: commonExporting,
      }
    }

    switch (tabKey) {
      case 'All':
        setUtilizationInsightOptions(
          getUtilizationInsightOptions(
            tabKey,
            timezone,
            timeSeries,
            chargerInUseWeekMax,
            chargerInUseWeekFill,
            chargerInUseWeek,
            handleFileName,
          ),
        )

        break
      case 'Weekday':
        setUtilizationInsightOptions(
          getUtilizationInsightOptions(
            tabKey,
            timezone,
            timeSeries,
            chargerInUseWeekdayMax,
            chargerInUseWeekdayFill,
            chargerInUseWeekday,
            handleFileName,
          ),
        )

        break
      case 'Weekend':
        setUtilizationInsightOptions(
          getUtilizationInsightOptions(
            tabKey,
            timezone,
            timeSeries,
            chargerInUseWeekendMax,
            chargerInUseWeekendFill,
            chargerInUseWeekend,
            handleFileName,
          ),
        )

        break
    }
  }, [chargerProfile, tabKey, timezone, language])

  return (
    <HomePageChargerUtilization lang={language} isResponsiveStyling={isResponsiveStyling}>
      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={utilizationInsightOptions} />
    </HomePageChargerUtilization>
  )
}

import { useFormatMessage } from '../../helpers/intl'

export const useChargerConnectivityTranslation = () => {
  const connectivityPeriods: string[] = [
    useFormatMessage('listings.chargerConnectivity.last1hour', 'Last 1 Hour (5-Min Interval)'),
    useFormatMessage('listings.chargerConnectivity.last1day', 'Last 1 Day (Hourly Interval)'),
    useFormatMessage('listings.chargerConnectivity.last7days', 'Last 7 Days (Daily Interval)'),
    useFormatMessage('listings.chargerConnectivity.last8weeks', 'Last 8 Weeks (Weekly Interval)'),
  ]
  const connectivityDetailsText = useFormatMessage('listings.chargerConnectivity.connectivityDetails', 'Connectivity Details')
  const selectTimeframeText = useFormatMessage('listings.chargerConnectivity.selectTimeframe', 'Select Timeframe')
  const timeText = useFormatMessage('listings.chargerConnectivity.time', 'Time')
  const scoreText = useFormatMessage('listings.chargerConnectivity.score', 'Score')
  return {
    connectivityPeriods,
    connectivityDetailsText,
    selectTimeframeText,
    timeText,
    scoreText,
  }
}

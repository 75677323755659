import { Button, Col, PageHeader, Row, Table } from 'antd'
import { refundArrowSVG } from 'assets/svg/arrow'
import { DownloadSVGLink } from 'assets/svg/download'
import { QuestionIcon } from 'assets/svg/questionMark'
import { LinkButton } from 'atom/button'
import { WalletCardWrapper } from 'atom/card'
import { BackgroundContainer } from 'atom/layout/backgroundContainer'
import { DateTimeV2 } from 'components/date-time'
import { AlertError } from 'components/error'
import { withAuthenticatedLayout } from 'components/layouts/layout'
import { CancelModal } from 'components/modal/CancelModal'
import BalanceText from 'components/text/BalanceText'
import BulletPoint from 'components/text/BulletPoint'
import LabeledText from 'components/text/LabeledText'
import { useUserTranslation } from 'hooks/translation/useUserTranslation'
import { useNotifications } from 'hooks/useNotification'
import { SwtchError } from 'models/error'
import { WalletFilter } from 'models/filter'
import { PaginationMeta } from 'models/pagination'
import { WalletData, WalletInfo } from 'models/user'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  GetWalletHistory,
  GetWalletInfo,
  GetWholeWalletHistory,
  RefundBalance,
} from 'services/data-provider/activities'
import { useAppState } from 'state'
import { theme } from 'theme'
import WalletActivityModal from './modal/WalletActivityModal'
import { createDownloadableCSV } from 'helpers/jsonToSCV'
import { Loading } from 'atom/loader'

const UserWalletBasePage: React.FC = () => {
  document.querySelector('body')?.classList.add('redesignActive')
  // @ts-ignore
  const { userId } = useParams()

  const { isMockUpEnabled } = useAppState()

  const {
    driverWalletText,
    walletRefundModalTitleText,
    walletRefundModalDescriptionText,
    walletRefundModalBulletPoint1Text,
    walletRefundModalBulletPoint2Text,
    walletRefundModalConfirmRefundText,
    walletHistoryTableDateText,
    walletHistoryTableActivityText,
    walletHistoryTableAmountText,
    walletHistoryTableBalanceText,
    walletHistoryTableTransactionIdText,
    walletHistoryTableConnectedText,
    walletHistoryTableDisconnectedText,
    walletEmailText,
    walletCarMakeModelText,
    walletLicensePlateText,
    walletExportCSVText,
    walletCurrentBalanceText,
    walletRefundText,
    walletHistoryText,
  } = useUserTranslation()

  const [walletData, setWalletData] = useState<WalletData[]>([])
  const [error, setError] = useState<SwtchError>()
  const [walletInfo, setWalletInfo] = useState<WalletInfo>()
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [filter, setFilter] = useState<WalletFilter>({ page: 1, perPage: 10 })
  const [refundModalVisible, setRefundModalVisible] = useState(false)
  const [walletActivityModalVisible, setWalletActivityModalVisible] = useState(false)
  const [refundLoading, setRefundLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  const { openSuccessNotification } = useNotifications()
  const walletColumns = [
    {
      title: walletHistoryTableDateText,
      key: 'createdAt',
      render: ({ createdAt, timeZone }: WalletData) => {
        if (!createdAt) return '-'
        return <DateTimeV2 alignLeft date={createdAt} timezone={timeZone} />
      },
    },
    {
      title: () => (
        <Row align="middle">
          <span>{walletHistoryTableActivityText}</span>
          <Button type="text" style={{ padding: 0 }} icon={<QuestionIcon />} onClick={handleWalletActivityModal} />
        </Row>
      ),
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: walletHistoryTableTransactionIdText,
      key: 'transactionId',
      render: ({ transactionId, createdAt }: WalletData) => {
        if (!transactionId) return '-'

        const url = `/transactions?transaction_id=${transactionId}&start_date=${createdAt}&end_date=${createdAt}`
        return (
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              console.log('URL in onClick:', e.currentTarget.href)
            }}
          >
            {transactionId}
          </a>
        )
      },
    },
    {
      title: walletHistoryTableConnectedText,
      key: 'connectedAt',
      render: ({ connectedAt, timeZone }: WalletData) => {
        if (!connectedAt) return '-'
        return <DateTimeV2 alignLeft date={connectedAt} timezone={timeZone} />
      },
    },
    {
      title: walletHistoryTableDisconnectedText,
      key: 'disconnectedAt',
      render: ({ disconnectedAt, timeZone }: WalletData) => {
        if (!disconnectedAt) return '-'
        return <DateTimeV2 alignLeft date={disconnectedAt} timezone={timeZone} />
      },
    },
    {
      title: walletHistoryTableAmountText,
      key: 'amount',
      align: 'right' as 'right',
      width: 100,
      render: ({ amount }: WalletData) => {
        if (amount < 0) return `($${Math.abs(parseFloat(`${amount}`)).toFixed(2)})`
        return <span style={{ color: theme.colors.successPrimary }}>${parseFloat(`${amount}`).toFixed(2)}</span>
      },
    },
    {
      title: walletHistoryTableBalanceText,
      key: 'balance',
      align: 'right' as 'right',
      width: 100,
      render: ({ balance }: WalletData) => {
        if (balance < 0) return `($${Math.abs(parseFloat(`${balance}`)).toFixed(2)})`
        return <span>${parseFloat(`${balance}`).toFixed(2)}</span>
      },
    },
  ]

  const handleRefundModal = () => setRefundModalVisible(!refundModalVisible)
  const handleWalletActivityModal = () => setWalletActivityModalVisible(!walletActivityModalVisible)
  const onPaginationChange = (page: number) => {
    setFilter({ ...filter, page })
    fetchWalletHistory(page)
  }

  const handleRefund = async () => {
    setRefundLoading(true)
    try {
      const { message } = await RefundBalance(isMockUpEnabled, userId)
      openSuccessNotification(message)
      handleRefundModal()
      fetchWalletHistory()
      fetchWalletInfo()
    } catch (error) {
      setError(error as SwtchError)
    } finally {
      setRefundLoading(false)
    }
  }

  const formatWholeHistoryWithTableColumns = (data: WalletData[]) => {
    return data.map((item) => {
      return {
        [walletHistoryTableDateText]: item.createdAt ? new Date(item.createdAt).toLocaleString() : '-',
        [walletHistoryTableActivityText]: item.title || '-',
        [walletHistoryTableTransactionIdText]: item.transactionId || '-',
        [walletHistoryTableConnectedText]: item.connectedAt ? new Date(item.connectedAt).toLocaleString() : '-',
        [walletHistoryTableDisconnectedText]: item.disconnectedAt
          ? new Date(item.disconnectedAt).toLocaleString()
          : '-',
        [walletHistoryTableAmountText]:
          item.amount < 0
            ? `($${Math.abs(parseFloat(`${item.amount}`)).toFixed(2)})`
            : `$${parseFloat(`${item.amount}`).toFixed(2)}`,
        [walletHistoryTableBalanceText]:
          item.balance < 0
            ? `($${Math.abs(parseFloat(`${item.balance}`)).toFixed(2)})`
            : `$${parseFloat(`${item.balance}`).toFixed(2)}`,
      }
    })
  }

  const handleExportCSV = async () => {
    try {
      const data = await GetWholeWalletHistory(isMockUpEnabled, userId)

      // Format data to match table columns
      const formattedData = formatWholeHistoryWithTableColumns(data)

      const csvFileURL = createDownloadableCSV(formattedData)

      // Create a link element and trigger download
      const downloadLink = document.createElement('a')
      downloadLink.href = csvFileURL
      downloadLink.download = `wallet-history-${userId}.csv` // Name for the downloaded file
      document.body.appendChild(downloadLink)
      downloadLink.click()

      // Clean up
      document.body.removeChild(downloadLink)
      URL.revokeObjectURL(csvFileURL) // Free up memory
    } catch (error) {
      setError(error as SwtchError)
    }
  }

  const fetchWalletHistory = async (page: number = 1) => {
    try {
      setLoading(true)
      const { data, pagination } = await GetWalletHistory(isMockUpEnabled, userId, page)
      setWalletData(data)
      setPagination(pagination)
    } catch (error) {
      setError(error as SwtchError)
    } finally {
      setLoading(false)
    }
  }

  const fetchWalletInfo = async () => {
    try {
      const data = await GetWalletInfo(isMockUpEnabled, userId)
      setWalletInfo(data)
    } catch (error) {
      setError(error as SwtchError)
    }
  }

  useEffect(() => {
    fetchWalletHistory()
    fetchWalletInfo()
  }, [])

  return (
    <BackgroundContainer>
      <WalletActivityModal visible={walletActivityModalVisible} onCancel={handleWalletActivityModal} />
      <CancelModal
        visible={refundModalVisible}
        onCancel={handleRefundModal}
        onSubmit={handleRefund}
        submitText={walletRefundModalConfirmRefundText}
        title={walletRefundModalTitleText}
        description={
          <div>
            <p className="paragraph-01-regular">
              {walletRefundModalDescriptionText.replace('{balance}', `$${walletInfo?.balance}`)}
            </p>
            <BulletPoint value={walletRefundModalBulletPoint1Text} />
            <BulletPoint value={walletRefundModalBulletPoint2Text} />
          </div>
        }
      />
      <AlertError error={error} />
      <PageHeader title={<h4 className="heading-04-regular">{driverWalletText}</h4>} />
      {walletInfo ? (
        <Row gutter={[24, 24]} style={{ marginBottom: '24px' }}>
          <Col sm={12} style={{ display: 'flex' }}>
            <WalletCardWrapper>
              <span className="heading-06-bold-normal-opacity">{walletCurrentBalanceText}</span>
              <Row style={{ alignItems: 'flex-end', gap: '8px' }}>
                <BalanceText balance={walletInfo?.balance} currency={walletInfo?.currency.toUpperCase()} />
              </Row>
              <div>
                <Button
                  disabled={walletInfo?.balance <= 0 || refundLoading}
                  type="primary"
                  className="regular-cap-2"
                  icon={refundArrowSVG}
                  onClick={handleRefundModal}
                  loading={refundLoading}
                >
                  {walletRefundText}
                </Button>
              </div>
            </WalletCardWrapper>
          </Col>
          <Col sm={12} style={{ display: 'flex' }}>
            <WalletCardWrapper>
              <LabeledText showBorderBottom label={walletEmailText} value={walletInfo.email} />
              <Row>
                <LabeledText sm={12} noMarginBottom label={walletCarMakeModelText} value={walletInfo.carMakeModel} />
                <LabeledText sm={12} noMarginBottom label={walletLicensePlateText} value={walletInfo.licensePlate} />
              </Row>
            </WalletCardWrapper>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <Loading />
          </Col>
        </Row>
      )}
      <PageHeader
        title={<h4 className="heading-04-regular">{walletHistoryText}</h4>}
        extra={
          <LinkButton
            text={walletExportCSVText}
            onClick={handleExportCSV}
            icon={<DownloadSVGLink width={15} height={16} color={theme.colors.primary} />}
          />
        }
      />
      <Table
        columns={walletColumns}
        dataSource={walletData}
        loading={loading}
        rowKey="id"
        className="pagination-white"
        pagination={{
          position: ['bottomCenter'],
          total: pagination?.totalEntries,
          current: pagination?.currentPage,
          pageSize: pagination?.perPage,
          showSizeChanger: false,
          onChange: onPaginationChange,
        }}
      />
    </BackgroundContainer>
  )
}

export const UserWalletPage = withAuthenticatedLayout(UserWalletBasePage)

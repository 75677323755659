import { Col, Layout, Menu, MenuProps, Row, Space } from 'antd'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useAppState } from '../../../state'
import { styled, theme } from '../../../theme'
import { Links } from '../../routes/paths'

import { arrowLeftSVG, rightArrowSVG } from '../../../assets/svg/arrow'
import { globeSVG } from '../../../assets/svg/globe'
import { listingSVG } from '../../../assets/svg/listing'
import { logoutSVG } from '../../../assets/svg/logout'
import { peakShavingIcon } from '../../../assets/svg/peakShavingIcon'
import { pieChartSVG } from '../../../assets/svg/pieChart'
import { plugNeonGreenHeaderSVG } from '../../../assets/svg/plug'
import { reportSVG } from '../../../assets/svg/report'
import { tenantSVG } from '../../../assets/svg/tenant'
import { transactionSVG } from '../../../assets/svg/transaction'
import { userSVG } from '../../../assets/svg/user'
import { AppHeaderContainer, TenantNameTitleWrapper } from '../../../atom/app-header'
import { useAppHeaderTranslation } from '../../../hooks/translation/useAppHeaderTranslation'
import { supportDashboardIcon } from '../../../assets/svg/supportDashboardIcon'
import { GetTenant } from '../../../services/data-provider/tenants'
import { GetListing } from 'services/data-provider/listing'
import { TagsFilled, UserOutlined } from '@ant-design/icons'
import EVChargerSVG from 'assets/svg/ev_charging'
import { useSelector } from 'hooks/useSelector'
import { GetWalletInfo } from 'services/data-provider/activities'

const MenuSpacing = styled.div`
  .ant-layout-header {
    padding: 0 25px 0 15px;
  }
`

interface props {
  collapsed: boolean
  handleToggleMenu(): void
}

export const AppHeader: React.FC<props> = ({ collapsed, handleToggleMenu }) => {
  const [tenantName, setTenantName] = useState('')
  const {
    currentUser,
    selectedTenant,
    language,
    isClassicView,
    isMockUpEnabled,
    hasNoAccess,
    logout,
    setLanguage,
    ToggleClassicDashboardView,
  } = useAppState()

  const location = useLocation()

  const {
    adminDashboardText,
    demoDashboardText,
    supportDashboardText,
    dashboardText,
    chargersText,
    transactionsText,
    listingText,
    tenantsText,
    reportsText,
    usersText,
    peakShavingText,
    taggingText,
    logoutText,
    switchHomePageText,
    switchHomePageNewVersionText,
  } = useAppHeaderTranslation()
  const { siteLabelCleaner } = useSelector()

  useEffect(() => {
    const splitPathName = location.pathname.split('/')
    if (splitPathName[1] === 'tenants' && splitPathName.length === 3) {
      const tenantId = splitPathName[2]
      GetTenant(Number(tenantId), isMockUpEnabled).then((tenant) =>
        setTenantName(siteLabelCleaner(tenant?.combineName || tenant?.displayName || tenant?.name)),
      )
    } else if (splitPathName[1] === 'listing' && splitPathName[2] !== 'create') {
      GetListing(Number(splitPathName[2])).then((listing) => setTenantName(listing.title ? listing.title : ''))
    } else if (splitPathName[3] === 'wallet') {
      let userId = splitPathName[2]
      GetWalletInfo(isMockUpEnabled, userId).then((walletInfo) => setTenantName(walletInfo.email))
    } else {
      setTenantName('')
    }
  }, [location.pathname])

  const changeLocal = () => {
    switch (language) {
      case 'en':
        setLanguage('fr')
        break
      case 'fr':
        setLanguage('en')
        break
    }
  }

  const handleLogout: MenuProps['onClick'] = (e) => {
    if (e.key === 'logout') {
      logout()
      window.location.replace(Links.auth0login())
    }
  }

  if (!currentUser && !hasNoAccess) {
    return <div />
  }

  const renderTitle = () => {
    const splitPathName = location.pathname.split('/')
    let title = ''
    let icon = pieChartSVG

    // Check if isMockUpEnabled is true and the path is the home page
    if (isMockUpEnabled && splitPathName[1] === 'dashboard') {
      title = demoDashboardText
    } else {
      switch (splitPathName[1]) {
        case 'listings':
        case 'listing':
          icon = listingSVG
          title = listingText
          break
        case 'chargers':
          icon = plugNeonGreenHeaderSVG
          title = chargersText
          break
        case 'chargers2':
          icon = <EVChargerSVG color={theme.colors.darkGray} />
          title = chargersText
          break
        case 'tenants':
          icon = tenantSVG
          title = tenantsText
          break
        case 'transactions':
          icon = transactionSVG
          title = transactionsText
          break
        case 'reports':
          icon = reportSVG
          title = reportsText
          break
        case 'users':
          icon = userSVG
          title = usersText
          break
        case 'peakShavingPrograms':
          icon = peakShavingIcon
          title = peakShavingText
          break
        case 'demandManagement':
          icon = peakShavingIcon
          title = peakShavingText
          break
        case 'tagging':
          icon = <TagsFilled style={{ fontSize: 18 }} />
          title = taggingText
          break
        case 'supportDashboard':
          icon = supportDashboardIcon
          title = supportDashboardText
          break
        case 'usersv2':
          icon = userSVG
          title = usersText
          break
        default:
          switch (currentUser?.role) {
            case 'admin':
              title = adminDashboardText
              break
            case 'support':
              title = supportDashboardText
              break
            case 'user':
              const tenantDisplayName = currentUser?.accesses.filter((obj) => {
                return obj.display === selectedTenant?.name
              })
              if (selectedTenant && tenantDisplayName.length === 1) {
                title = tenantDisplayName[0].displayName || tenantDisplayName[0].display
              } else {
                title = dashboardText
              }
              break
            default:
              title = dashboardText
              break
          }
          break
      }
    }

    return (
      <>
        <span style={{ position: 'absolute', left: '5px', top: '13px' }}>{icon}</span>
        {title}
      </>
    )
  }

  return (
    <AppHeaderContainer>
      <MenuSpacing style={{ zIndex: 1 }}>
        <Layout.Header>
          <Row justify="start" align="middle">
            <Col xs={22} sm={23} md={17} lg={18} xl={20} xxl={20}>
              <Space>
                <h1 className="heading-01-regular dashboard-title" style={{ marginBottom: 0, marginLeft: '30px' }}>
                  {renderTitle()}
                  {tenantName.length > 0 && (
                    <TenantNameTitleWrapper>
                      {' '}
                      <span className="arrow-svg">{rightArrowSVG}</span> {tenantName}
                    </TenantNameTitleWrapper>
                  )}
                </h1>
              </Space>
            </Col>
            <Col xs={2} sm={1} md={7} lg={6} xl={4} xxl={4}>
              <Menu theme="light" mode="horizontal" className="sub-menu">
                <Menu.SubMenu
                  key="language"
                  title={language === 'en' ? 'ENG' : 'FRA'}
                  className="language"
                  icon={globeSVG}
                >
                  <Menu.Item
                    disabled={language === 'en' ? true : false}
                    key="en"
                    onClick={changeLocal}
                    className="lng-option en"
                  >
                    ENGLISH
                  </Menu.Item>
                  <Menu.Item
                    disabled={language === 'fr' ? true : false}
                    key="fr"
                    onClick={changeLocal}
                    className="lng-option fr"
                  >
                    FRANÇAIS
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="signedUser" title={<UserOutlined />} className="signed-in-user">
                  {currentUser?.email && (
                    <Menu.Item key="signed-user" onClick={changeLocal} className="menu-2 current-user">
                      {currentUser?.email}
                    </Menu.Item>
                  )}
                  {isMockUpEnabled ? null : (
                    <Menu.Item
                      key="switch-homepage"
                      onClick={() => ToggleClassicDashboardView()}
                      icon={arrowLeftSVG}
                      className="menu-2 switch-old-homepage-toggle"
                    >
                      {isClassicView ? switchHomePageNewVersionText : switchHomePageText}
                    </Menu.Item>
                  )}
                  <Menu.Item key="logout" onClick={handleLogout} icon={logoutSVG} className="menu-2 logout-optn">
                    {logoutText}
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu>
            </Col>
          </Row>
        </Layout.Header>
      </MenuSpacing>
    </AppHeaderContainer>
  )
}

import { AugmentedUserResponse, UserResponse } from 'models/http'
import { RFID, SuccessResponse, toAugmentedUser, UserRef, UserWallet } from '../models/user'
import moment from 'moment'
import { log } from 'logger'
import { mockTenantsRef } from 'services/data-provider/mock'

export const mockRFID = [
  {
    id: 1,
    rfidNumber: '1234567890',
    personId: '1',
  },
  {
    id: 2,
    rfidNumber: '1234567890',
    personId: '2',
  },
  {
    id: 3,
    rfidNumber: '1234567890',
    personId: '3',
  },
] as RFID[]

export const mockSuccessResponse = {
  create: {
    message: 'All RFIDs created successfully',
  },
  update: {
    message: 'All RFIDs updated successfully',
  },
  delete: {
    message: 'RFID has been removed successfully',
  },
} as { [key: string]: SuccessResponse }

export const mockCloneUserResponse = {
  message: 'User cloned successfully',
} as SuccessResponse

export const mockUserRef = [
  {
    id: 'Uxy3ks0VTzsgSdCYhQ7d57w',
    email: 'mikeokc@swtchenergy.com',
    name: 'Mike',
  },
  {
    id: 'gDLLnZdzzDV1QXzbaKvwCIw',
    email: 'canadadan@swtchenergy.com',
    name: 'Dan',
  },
  {
    id: 'yCcmQZxNdAGrG0WTgq6uUTQ',
    email: 'linda23@swtchenergy.com',
    name: 'Linda',
  },
  {
    id: 'yCcmQZxNAGrG0WTgq6uUTQ',
    email: 'johndoe@swtchenergy.com',
    name: 'Annonymous Guest',
  },
] as UserRef[]

export const mockDiscountPlanUser = [
  {
    userId: 'Uxy3ks0VTzsgSdCYhQ7d57w',
    userEmail: 'mikeokc@swtchenergy.com',
    userName: 'Mike',
    timeAdded: moment().format(),
  },
  {
    userId: 'gDLLnZdzzDV1QXzbaKvwCIw',
    userEmail: 'canadadan@swtchenergy.com',
    userName: 'Dan',
    timeAdded: moment().format(),
  },
  {
    userId: 'yCcmQZxNdAGrG0WTgq6uUTQ',
    userEmail: 'linda23@swtchenergy.com',
    userName: 'Linda',
    timeAdded: moment().format(),
  },
  {
    userId: 'yCcmQZxNAGrG0WTgq6uUTQ',
    userEmail: 'johndoe@swtchenergy.com',
    userName: 'Annonymous Guest',
    timeAdded: moment().format(),
  },
]

export const mockUserWallet = [
  {
    ...mockUserRef[0],
    wallet: {
      id: 1,
      amount: 1000,
      person_id: 'sldfjsdlf',
      created_at: 'sjofsjf',
      updated_at: 'sjflsjdf',
      country: 'CA',
    },
    hasAlternatePaymentMethod: false,
    hasBillingAddr: false,
    billingAddr: {
      id: 1,
      person_id: 'ZHJGhjH9RgKk0JhpVLUfow',
      stripe_customer_id: 'cus_IXGr25Ncse4OPB',
      stripe_payment_gateway_id: 1,
      state: 'Ontario',
      zip: 'M3N 1S7',
      country: 'CA',
    },
    walletStatus: {
      hasWallet: true,
      walletCountry: 'CA',
      hasValidPaymentMethod: true,
      hasAlternatePaymentMethod: false,
      hasBillingAddr: true,
      billingAddr: {
        city: null,
        country: 'US',
        address1: null,
        address2: null,
        zip: '90210',
        state: 'CA',
      },
    },
    note: '',
  },
  {
    ...mockUserRef[1],
    wallet: {
      id: 1,
      amount: 1000,
      person_id: 'sldfjsdlf',
      created_at: 'sjofsjf',
      updated_at: 'sjflsjdf',
      country: 'CA',
    },
    hasAlternatePaymentMethod: false,
    hasBillingAddr: false,
    billingAddr: {
      id: 1,
      person_id: 'ZHJGhjH9RgKk0JhpVLUfow',
      stripe_customer_id: 'cus_IXGr25Ncse4OPB',
      stripe_payment_gateway_id: 1,
      state: 'Ontario',
      zip: 'M3N 1S7',
      country: 'CA',
    },
    walletStatus: {
      hasWallet: true,
      walletCountry: 'CA',
      hasValidPaymentMethod: true,
      hasAlternatePaymentMethod: false,
      hasBillingAddr: true,
      billingAddr: {
        city: null,
        country: 'US',
        address1: null,
        address2: null,
        zip: '90210',
        state: 'CA',
      },
    },
    note: '',
  },
  {
    ...mockUserRef[2],
    wallet: {
      id: 1,
      amount: 1000,
      person_id: 'sldfjsdlf',
      created_at: 'sjofsjf',
      updated_at: 'sjflsjdf',
      country: 'CA',
    },
    hasAlternatePaymentMethod: false,
    hasBillingAddr: false,
    billingAddr: {
      id: 1,
      person_id: 'ZHJGhjH9RgKk0JhpVLUfow',
      stripe_customer_id: 'cus_IXGr25Ncse4OPB',
      stripe_payment_gateway_id: 1,
      state: 'Ontario',
      zip: 'M3N 1S7',
      country: 'CA',
    },
    walletStatus: {
      hasWallet: true,
      walletCountry: 'CA',
      hasValidPaymentMethod: true,
      hasAlternatePaymentMethod: false,
      hasBillingAddr: true,
      billingAddr: {
        city: null,
        country: 'US',
        address1: null,
        address2: null,
        zip: '90210',
        state: 'CA',
      },
    },
    note: '',
  },
] as UserWallet[]

export const mockUsers = [
  {
    id: 'Uxy3ks0VTzsgSdCYhQ7d57w',
    email: 'mikeokc@swtchenergy.com',
    name: 'Mike Jackson',
    role: 'manager',
    note: 'test',
    accesses: [
      {
        resourceType: 'Tenant',
        resourceId: mockTenantsRef[0].id,
        permissions: ['tenant.driver'],
        display: 'Muli-Family Site 1',
      },
      {
        resourceType: 'Tenant',
        resourceId: mockTenantsRef[1].id,
        permissions: ['tenant.manager'],
        display: 'Multi-Family Site 2',
      },
      {
        resourceType: 'Tenant',
        resourceId: mockTenantsRef[2].id,
        permissions: ['tenant.charger_operator'],
        display: 'Workplace Site 1',
      },
    ],
    accessPlans: [],
  },
  {
    id: 'gDLLnZdzzDV1QXzbaKvwCIw',
    email: 'canadadan@swtchenergy.com',
    name: 'Danny Dan',
    role: 'admin',
    accesses: [],
    accessPlans: [],
  },
  {
    id: 'yCcmQZxNdAGrG0WTgq6uUTQ',
    email: 'linda23@swtchenergy.com',
    name: 'Linda Anderson',
    role: 'driver',
    accesses: [
      {
        resourceType: 'Tenant',
        resourceId: mockTenantsRef[0].id,
        permissions: ['tenant.driver'],
        display: 'demoMFU-site1',
        displayName: 'Multi-Family Site 1',
      },
      {
        resourceType: 'Tenant',
        resourceId: mockTenantsRef[1].id,
        permissions: ['tenant.driver'],
        display: 'demoMFU-site2',
        displayName: 'Multi-Family Site 2',
      },
      {
        resourceType: 'Tenant',
        resourceId: mockTenantsRef[2].id,
        permissions: ['tenant.driver'],
        display: 'demoWorkplace-site1',
        displayName: 'Workplace Site 1',
      },
      {
        resourceType: 'Tenant',
        resourceId: mockTenantsRef[3].id,
        permissions: ['tenant.driver'],
        display: 'DCFC-site1',
        displayName: 'DCFC Site 1',
      },
    ],
    accessPlans: [],
  },
  {
    id: 'yCcmQZxNAGrG0WTgq6uUTQ',
    email: 'johndoe@swtchenergy.com',
    name: 'Annonymous Guest',
    role: 'driver',
    accesses: [
      {
        resourceType: 'Tenant',
        resourceId: mockTenantsRef[3].id,
        permissions: ['tenant.driver'],
        display: 'DCFC Site 1',
        displayName: null,
      },
    ],
    accessPlans: [],
  },
]

export const mockAugmentedUserResponse = (term?: string, updateField?: string): AugmentedUserResponse => {
  const filterUsers = mockUserResponse(term, updateField)
  return {
    ...filterUsers,
    data: filterUsers.data.map((u) => toAugmentedUser(u)),
  }
}
export const mockUserResponse = (term?: string, updateField?: string) => {
  let filterUsers = mockUsers
  log('term', term)

  if (term) {
    filterUsers = filterUsers.filter((user) => user.email.toLowerCase().includes(term.toLowerCase()))
  }

  log('term found', filterUsers)
  log('updateField', updateField)
  if (updateField) {
    // update the found user with the new field
    filterUsers = filterUsers.map((user) => {
      user.name = updateField
      return user
    })
  }

  const newUserInvited: any = {
    id: `yCcmQZxNAGrG0WTgq6uUTQ${mockUsers.length + 1}`.toString(),
    email: term || '',
    name: term?.split('@')[0] || '',
    role: 'user',
    note: '',
    accesses: [
      {
        resourceType: 'Tenant',
        resourceId: '42',
        permissions: ['tenant.driver'],
        display: '100-Victoria-St-S-Kitchener-ON-N2G2B3',
        displayName: '100-Victoria-St-S-Kitchener-ON-N2G2B3',
      },
      {
        resourceType: 'Tenant',
        resourceId: '109',
        permissions: ['tenant.manager'],
        display: 'opus',
        displayName: 'opus',
      },
      {
        resourceType: 'Tenant',
        resourceId: '56',
        permissions: ['tenant.charger_operator'],
        display: 'Royal-Botanical-Gardens-ON',
        displayName: 'Royal Botanical Gardens',
      },
    ],
    subscriptions: [
      {
        id: 1,
        personId: 3,
        planId: 2,
        startDate: '2021-09-01',
        endDate: '2021-09-30',
        status: 'active',
        createdAt: '2021-09-01',
        updatedAt: '2021-09-01',
        quantity: 1,
        subscriber: {
          id: '3',
          email: 'lan2@gmail.com',
          name: 'Lan2',
        },
      },
    ],
    accessPlans: [],
  }

  if (filterUsers.length === 0) {
    filterUsers = mockUsers
    filterUsers.push(newUserInvited)
  }

  log('mockUserResponse', filterUsers)
  log('mockusers', mockUsers)
  return {
    data: filterUsers,
    pagination: {
      currentPage: 1,
      totalEntries: filterUsers.length,
      perPage: 20,
    },
  } as UserResponse
}

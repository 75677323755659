import { Col, Modal, Row } from 'antd'
import { renderFormatMessage } from 'helpers/intl'
import { useUserTranslation } from 'hooks/translation/useUserTranslation'
import { theme } from 'theme'
interface WalletActivityModalProps {
  visible: boolean
  onCancel: () => void
}

export const WalletActivityModal: React.FC<WalletActivityModalProps> = ({ visible, onCancel }) => {
  const { walletActivityTitleText } = useUserTranslation()
  const activities = Array.from({ length: 7 }, (_, index) => ({
    title: renderFormatMessage(`dashboard.usersPage.userWallet.activity.${index + 1}.title`, 'title'),
    description: renderFormatMessage(`dashboard.usersPage.userWallet.activity.${index + 1}.description`, 'description'),
  }))
  return (
    <Modal
      title={<div className="heading-05-regular">{walletActivityTitleText}</div>}
      closable={true}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ padding: '0px' }}
    >
      {activities.map(({ title, description }) => (
        <Row style={{ borderBottom: `1px solid ${theme.colors.lightGray}`, padding: '20px' }}>
          <Col span={8}>
            <span className="paragraph-01-regular">{title}</span>
          </Col>
          <Col span={16}>
            <span className="paragraph-01-regular">{description}</span>
          </Col>
        </Row>
      ))}
    </Modal>
  )
}

export default WalletActivityModal

import { Button, Form, Input, Modal, Spin } from 'antd'
import { AlertError } from 'components/error'
import { isValidEmail } from 'helpers/regularExpression/email'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { useNotifications } from 'hooks/useNotification'
import { useSelector } from 'hooks/useSelector'
import { AccessPlan, AccessPlanPayload } from 'models/access-plan'
import { DiscountSchema } from 'models/discount'
import { useEffect, useState } from 'react'
import { GetAllAccessPlans } from 'services/data-provider/access-plan'
import { ActiveDiscountForUser } from 'services/data-provider/discount'
import { ButtonTextSpacing, FooterLayout, Heading } from '../../../atom/user-edit'
import { useUserTranslation } from '../../../hooks/translation/useUserTranslation'
import { SwtchError } from '../../../models/error'
import { AugmentedUser, UserPermissions } from '../../../models/user'
import { CloneUser } from '../../../services/data-provider/users'
import { useAppState } from '../../../state'
import { MemoizedEditForm } from './user-edit-form'

interface props {
  selectedUser: AugmentedUser
  onCancel: (e: React.FormEvent<EventTarget>) => void
  onFinish: () => void
}

export interface AccessPlanObj {
  [key: string]: AccessPlan[]
}

export const UserCloneForm: React.FC<props> = ({ onCancel, selectedUser, onFinish }) => {
  const [form] = Form.useForm()
  const { siteLabelCleaner } = useSelector()
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)
  const [userPermissions, setUserPermissions] = useState<UserPermissions[]>([])
  const [initialUserPermissions, setInitialUserPermissions] = useState<UserPermissions[]>([])
  const [disableSave, setDisableSave] = useState(true)
  const [email, setEmail] = useState('')

  const {
    cancelText,
    emailText,
    cloneModalHeadingText,
    cloneModalHeadingDescriptionText,
    cloneModalCloningFromText,
    cloneModalCloningFromDescriptionText,
    cloneModalCloningToText,
    cloneModalCloningToDescriptionText,
    cloneModalSitePermissionsText,
    cloneModalSitePermissionsDescriptionText,
    cloneModalCloneText,
    emailNotValidText,
  } = useUserTranslation()
  const { currentUser, isMockUpEnabled } = useAppState()

  const currentUserTenantAccesses = currentUser?.accesses
    ?.filter(({ resourceType, permissions }) => resourceType === 'Tenant' && permissions?.length)
    .map(({ resourceId }) => Number(resourceId))

  const filteredUserPermissions = userPermissions.filter(
    (user) => currentUser?.role === 'admin' || currentUserTenantAccesses?.includes(Number(user.id)) || !user.id,
  )
  const [idsWithOperatorRole, setIdsWithOperatorRole] = useState<string[]>([]) //id with operator role
  const [allAccessPlans, setAllAccessPlans] = useState<AccessPlanObj>({})
  const [emailError, setEmailError] = useState('')
  const { openErrorNotification, openSuccessNotification } = useNotifications()
  const { somethingWentWrongText } = useGeneralTranslation()
  const permissionTransform = {
    'tenant.none': 'none',
    'tenant.viewer': 'viewer',
    'tenant.manager': 'manager',
    'tenant.charger_operator': currentUser?.role === 'admin' ? 'charger_operator' : 'manager',
    'tenant.charger_viewer': 'charger_viewer',
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    setEmailError('')
  }

  const fetchActiveDiscount = async () => {
    try {
      const currentDiscount = await ActiveDiscountForUser(selectedUser.email, isMockUpEnabled)
      await handleUserAccess(currentDiscount)
    } catch (err) {
      openErrorNotification((err as SwtchError).description || somethingWentWrongText)
    } finally {
      setLoading(false)
    }
  }

  const handleUserAccess = async (tenantDiscount: DiscountSchema[]) => {
    let objArr: any[] = []
    let idWithOperatorRole: string[] = []

    let updatedUserPermissions: any[] = []
    const currentAccess = selectedUser.accesses.map((access) => ({ ...access, combineName: 'SWTCH HQ (swtchhq)' }))
    currentAccess.forEach((access) => {
      if (access.resourceType !== 'Tenant') return

      let driver = false
      let roles = 'none'

      const tenantId = access.resourceId
      access.permissions.forEach((permission) => {
        if (permission === 'tenant.driver') {
          driver = true
        } else {
          roles = getUpdatedRole(permission, roles) // Update roles with permissions
        }
        if (permission === 'tenant.charger_operator') {
          idWithOperatorRole.push(tenantId)
        }
      })
      const tenantName =
        currentUser?.role === 'admin'
          ? siteLabelCleaner(`${access.displayName ? `${access.displayName} ` : ''}(${access.display || ''})`)
          : siteLabelCleaner(access.displayName || access.display)
      const accessPlansForTenant = selectedUser.accessPlans.filter((plan) => plan.tenant_id === Number(tenantId))

      updatedUserPermissions.push({
        name: tenantName,
        id: tenantId,
        access: roles,
        driver: driver,
        accessPlan: [...accessPlansForTenant],
        portal: roles !== 'none',
        driverOption: accessPlansForTenant.length > 0,
      })
    })
    //Populate discountPlan
    tenantDiscount.forEach((discount) => {
      const discountTenantId = discount.tenantId
      const existingTenantIndex = updatedUserPermissions.findIndex((obj: any) => obj.id === discountTenantId)
      if (existingTenantIndex > -1) {
        const currentDiscountPlan = [...(updatedUserPermissions[existingTenantIndex]?.discountPlan ?? []), discount]
        updatedUserPermissions[existingTenantIndex] = {
          ...updatedUserPermissions[existingTenantIndex],
          discountPlan: currentDiscountPlan,
          driverOption: updatedUserPermissions[existingTenantIndex]?.driverOption || currentDiscountPlan.length > 0,
        }
      }
    })

    // Ensure all access plans are accounted for
    selectedUser.accessPlans.forEach((accessPlan) => {
      const accessPlanTenantId = accessPlan.tenant_id
      const existingTenantIndex = updatedUserPermissions.findIndex((obj: any) => obj.id === accessPlanTenantId)
      if (existingTenantIndex > -1) {
        const alreadyExists = updatedUserPermissions[existingTenantIndex].accessPlan.some(
          (plan: AccessPlanPayload) => plan.id === accessPlan.id,
        )
        if (!alreadyExists) objArr[existingTenantIndex].accessPlan.push(accessPlan)
      }
    })

    // Final state updates
    setIdsWithOperatorRole(idWithOperatorRole)
    setUserPermissions(updatedUserPermissions)
    setInitialUserPermissions(updatedUserPermissions)
    setLoading(false)
  }

  const fetchAllAccessPlansForTenants = async () => {
    const currentUserTenantAccesses = currentUser?.accesses
      ?.filter(({ resourceType, permissions }) => resourceType === 'Tenant' && permissions?.length)
      .map(({ resourceId }) => Number(resourceId))

    const uniqueTenantIds = Array.from(
      new Set([
        ...(selectedUser?.accesses || [])
          .map(({ resourceId, resourceType }) => (resourceType === 'Tenant' ? resourceId : ''))
          .filter((val) => !!val),
        ...(selectedUser?.accessPlans || []).map(({ tenant_id }) => tenant_id),
      ]),
    ).filter((tenantId) => {
      if (currentUser?.role === 'admin') return true
      else return currentUserTenantAccesses?.includes(tenantId as number)
    })
    try {
      // Fetch all access plans concurrently
      const responses = await Promise.all(
        uniqueTenantIds.map(async (tenantId) => {
          const resp = await GetAllAccessPlans(isMockUpEnabled, Number(tenantId))
          return { tenantId, resp }
        }),
      )
      // Filter out responses with errors and create the new state object
      const accessPlans = responses.reduce<AccessPlanObj>((acc, { tenantId, resp }) => {
        if (!('error' in resp)) {
          acc[tenantId] = resp
        }
        return acc
      }, {})
      // Update state once with the combined access plans
      setAllAccessPlans((prev) => ({ ...prev, ...accessPlans }))
    } catch (error) {
      console.error('Failed to fetch access plans:', error)
    }
  }

  useEffect(() => {
    if (!selectedUser) return
    setLoading(true)
    fetchAllAccessPlansForTenants()
    fetchActiveDiscount()
  }, [selectedUser])

  useEffect(() => {
    const canClickSaveButton = email && !emailError

    setDisableSave(!canClickSaveButton)
  }, [userPermissions, initialUserPermissions, email, emailError])

  // Helper function to update roles based on permissions
  const getUpdatedRole = (permission: string, currentRole: string): string => {
    const role = permissionTransform[permission as keyof typeof permissionTransform]
    if (currentRole === 'none') return role
    if (currentRole === 'viewer' && role !== 'none') return role
    if (currentRole === 'manager' && role !== 'none' && role !== 'viewer') return role
    return currentRole
  }

  const afterClose = () => {
    form.resetFields()
  }

  const onOk = () => {
    if (!isValidEmail(email)) {
      setEmailError(emailNotValidText)
      return
    }
    setLoading(true)

    let accessPlanIds: number[] = []
    let discountPlanIds: number[] = []
    const updatedUserPermissions: UserPermissions[] = [...filteredUserPermissions].map((user) => {
      accessPlanIds = [...accessPlanIds, ...(user.driverOption ? user.accessPlan?.map((plan) => plan.id) ?? [] : [])]
      discountPlanIds = [
        ...discountPlanIds,
        ...(user.driverOption ? user.discountPlan?.map((discount) => discount.id) ?? [] : []),
      ]
      return {
        ...user,
        access: user.portal ? user?.access : 'none',
        driverOption: undefined,
        portal: undefined,
        accessPlan: undefined,
        discountPlan: undefined,
      }
    })
    userPermissions.forEach((user, index) => {
      //If the user is not admin and didn't update the tenant with operator role
      if (currentUser?.role !== 'admin' && idsWithOperatorRole.includes(user.id) && user.access === 'manager') {
        updatedUserPermissions.splice(index, 1, { ...user, access: 'charger_operator' })
      }
    })

    CloneUser(isMockUpEnabled, email, updatedUserPermissions, accessPlanIds, discountPlanIds)
      .then((resp) => {
        openSuccessNotification(resp.message)
        return onFinish()
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      title={<Heading>{cloneModalHeadingText}</Heading>}
      closable={true}
      visible={true}
      onCancel={onCancel}
      onOk={onOk}
      afterClose={afterClose}
      width={543}
      className="fixed-row-modal default-modal max-height-modal"
      footer={
        <FooterLayout>
          <Button style={{ width: '240px' }} loading={loading} onClick={onCancel}>
            <span className="paragraph-02-regular">{cancelText}</span>
          </Button>
          <Button
            style={{ width: '240px' }}
            type="primary"
            loading={loading}
            onClick={form.submit}
            disabled={disableSave}
          >
            <ButtonTextSpacing disabled={disableSave}>
              <span className="paragraph-02-regular">{cloneModalCloneText}</span>
            </ButtonTextSpacing>
          </Button>
        </FooterLayout>
      }
    >
      <AlertError error={error} />
      <Spin spinning={loading}>
        <Form form={form} onFinish={onOk} layout="vertical">
          <p className="ant-form-item-explain">{cloneModalHeadingDescriptionText}</p>
          <Form.Item
            help={cloneModalCloningFromDescriptionText}
            className="disabled-placeholder-clear helper-first"
            label={<span className="heading-06-regular">{cloneModalCloningFromText}</span>}
          >
            <Input disabled value={selectedUser.email} />
          </Form.Item>
          <Form.Item
            name="email"
            help={cloneModalCloningToDescriptionText}
            className="disabled-placeholder-clear helper-first"
            style={emailError ? { marginBottom: 0 } : {}}
            label={<span className="heading-06-regular">{cloneModalCloningToText}</span>}
          >
            <Input
              className={emailError ? 'ant-input-status-error' : ''}
              placeholder={emailText}
              onChange={handleEmailChange}
            />
          </Form.Item>
          {emailError && (
            <div className="ant-form-item-explain-error" style={{ marginTop: 5, marginBottom: 28 }}>
              {emailError}
            </div>
          )}
          <Form.Item
            help={cloneModalSitePermissionsDescriptionText}
            className="only-helper"
            label={
              <span className="heading-06-regular">{`${cloneModalSitePermissionsText} (${filteredUserPermissions.length})`}</span>
            }
          >
            <></>
          </Form.Item>
          <MemoizedEditForm
            userPermissions={userPermissions}
            loading={loading}
            allAccessPlans={allAccessPlans}
            setUserPermissions={setUserPermissions}
            setAllAccessPlans={setAllAccessPlans}
          />
        </Form>
      </Spin>
    </Modal>
  )
}

export default UserCloneForm

import { RightOutlined, DownOutlined } from '@ant-design/icons'
import { Col, Collapse, Typography } from 'antd'

import { renderFormatMessage } from 'helpers/intl'
import { theme } from 'theme'
import { CollapseDescriptions, GlossaryItem } from './types'
import { ReportDisclaimer } from 'models/reports'

interface CoreCollapseProps {
  descriptions: CollapseDescriptions[]
  activeCollapsePanelKey: string
  onCollapseChange: (key: string | string[]) => void
  onViewMore?: (glossary: GlossaryItem[], title: string, disclaimer?: ReportDisclaimer) => void
}

export const CoreCollapse: React.FC<CoreCollapseProps> = ({
  descriptions,
  activeCollapsePanelKey,
  onCollapseChange,
  onViewMore,
}) => {
  const renderCollapseHeader = (description: CollapseDescriptions) => {
    return (
      <div className="custom-panel-header body-01-regular">
        <div>{description.title}</div>
        <span className={`custom-icon ${activeCollapsePanelKey === description.id.toString() ? 'rotate-icon' : ''}`}>
          <DownOutlined />
        </span>
      </div>
    )
  }
  return (
    <>
      {descriptions.map((description) => (
        <Col span={24} key={description.id}>
          <Collapse
            accordion
            activeKey={activeCollapsePanelKey}
            className="custom-collapse"
            onChange={onCollapseChange}
            style={{ borderRadius: '5px', backgroundColor: theme.colors.white }}
          >
            <Collapse.Panel
              showArrow={false}
              header={renderCollapseHeader(description)}
              className="custom-panel"
              key={description.id}
            >
              <div className="body paragraph-03-regular panel-color-gray">{description.body}</div>
              {onViewMore && (
                <div>
                  <br />
                  <Typography.Link
                    onClick={() =>
                      description?.glossary &&
                      onViewMore(description?.glossary, description.title, description?.disclaimer)
                    }
                  >
                    {renderFormatMessage('dashboard.text.viewMore', 'View More')}
                  </Typography.Link>
                </div>
              )}
              <RightOutlined />
            </Collapse.Panel>
          </Collapse>
        </Col>
      ))}
    </>
  )
}

import styled from 'styled-components'
import { theme } from 'theme'

export const ChatContainer = styled.div`
  height: 400px;
  display: flex;
  flex-direction: column;
`

export const ChatHistory = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  background-color: #f5f5f5;
  margin-bottom: 16px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`

export const MessageBubble = styled.div<{ isUser: boolean; isTable?: boolean }>`
  max-width: 70%;
  padding: 8px 12px;
  margin: 8px 0;
  border-radius: 12px;
  background-color: ${(props) => (props.isUser ? theme.colors.primary : '#fff')};
  color: ${(props) => (props.isUser ? theme.colors.white : theme.colors.black)};
  align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  ${(props) =>
    props.isTable &&
    `
    max-width: 100%;
    overflow: scroll;
    min-height: fit-content;
  `}
`

export const LoadingDot = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  background-color: ${(props) => (props.active ? theme.colors.darkGray : theme.colors.lightGray)};
  border-radius: 50%;
`

export const InputContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px 0 0 0;
  .ant-input-affix-wrapper {
    padding: 0;
    border-radius: 20px;
    .ant-input {
      padding: 10px;
      border-radius: 20px;
    }
  }
`

export const FileLink = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${theme.colors.primary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

import { Col } from 'antd'
import { theme } from 'theme'

interface LabeledTextProps {
  label: string
  value: string
  sm?: number
  showBorderBottom?: boolean
  noMarginBottom?: boolean
}

const LabeledText: React.FC<LabeledTextProps> = ({
  label,
  value,
  sm,
  showBorderBottom = false,
  noMarginBottom = false,
}) => {
  return (
    <Col
      sm={sm}
      style={{
        padding: 0,
        borderBottom: showBorderBottom ? `1px solid ${theme.colors.lightGrey}` : 'none',
      }}
    >
      <p className="paragraph-01-regular" style={{ color: theme.colors.darkGray }}>{`${label}`}</p>
      <p className="heading-06-regular" style={noMarginBottom ? { marginBottom: 0 } : {}}>{`${value}`}</p>
    </Col>
  )
}

export default LabeledText

import { Col, Row, Space } from 'antd'

import { useAppState } from '../../../../state'
import { ButtonIcon, ButtonModal } from '../../../../atom/button'
import { LineBreak } from '../../../../atom/line-break'
import { InputsContainer, MobileInputsContainer } from '../../../../atom/reports'
import { LocationSelector } from '../../../../components/redesign/location-selector'
import { CustomDateRangePicker } from '../../../../components/redesign/datepicker'
import { MultiTenantSelector } from '../../../../components/redesign/multiple-tenant-selector'
import MultiListingTitleSelector from 'components/redesign/MultiListingIdTitleSelector'
import { magnifyBlackSVG } from '../../../../assets/svg/magnify'
import { useCalendarTransactions } from '../../../../hooks/translation/useCalendarTranslation'
import { ListingRef } from 'models/listing'
import { MasterAccountRef } from 'models/master-account'
import { useSelectorTranslation } from 'hooks/translation/useSelectorTranslation'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { ReportFilter } from 'models/filter'
import { useSelector } from 'hooks/useSelector'

interface ReportsSelectorsProps {
  loading: boolean
  startDate: moment.Moment
  endDate: moment.Moment
  filters: Omit<ReportFilter, 'startDate' | 'endDate'>
  dropdownClassName?: string
  getReports: () => void
  handleChangeDate: (dates: moment.Moment[]) => void
  handleLocation: (chosenCountries: string[], chosenProvinces: string[]) => void
  onFilters: (newFilters: Omit<ReportFilter, 'startDate' | 'endDate'>) => void
}

const ReportsSelectors: React.FC<ReportsSelectorsProps> = ({
  loading,
  startDate,
  endDate,
  filters,
  dropdownClassName,
  getReports,
  handleChangeDate,
  handleLocation,
  onFilters,
}) => {
  const { isMockUpEnabled, IsMobile, IsTablet, IsLaptop, IsDesktop } = useAppState()
  const { accountNamesText } = useSelectorTranslation()

  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  const {
    multiMasterAccountDebounceFetcher,
    multiMasterAccountHandleChange,
    multiMasterAccountHandlePlaceholder,
    multiMasterAccountHandleValue,
    renderMultiMasterAccountOption,
  } = useSelector()

  const handleMasterAccountSelection = (masterAccounts: MasterAccountRef[]) =>
    onFilters({
      masterAccounts: masterAccounts.filter((obj, index) => {
        return index === masterAccounts.findIndex((o) => obj.id === o.id)
      }),
    })

  const { calendarRanges } = useCalendarTransactions()

  return (
    <>
      {(isDesktop || isLaptop) && (
        <InputsContainer>
          <Space wrap size={[8, 8]}>
            <CustomDateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChangeDate={handleChangeDate}
              calendarRanges={calendarRanges}
            />
            <MultiTenantSelector
              onOptionsChange={(tenants) => onFilters({ tenants })}
              onClear={() => onFilters({ tenants: [] })}
              isCombineRight
              longerWidth
              dropdownClassName={dropdownClassName}
            />
            <LineBreak />
            <div className="master-account-selector">
              <ReusableSelector
                maxTagCount={0}
                showArrow={false}
                showSearch={true}
                isSingle={false}
                showMagnifySVG={false}
                isDebounceFetcher={true}
                combineType="both"
                fieldForValue="id"
                placeholder={accountNamesText}
                defaultValues={filters.masterAccounts}
                dropdownClassName={dropdownClassName}
                onClear={() => onFilters({ masterAccounts: [] })}
                onOptionsChange={(opts: MasterAccountRef[]) => handleMasterAccountSelection(opts)}
                debounceFetcher={multiMasterAccountDebounceFetcher}
                handlePlaceholder={multiMasterAccountHandlePlaceholder}
                handleValue={multiMasterAccountHandleValue}
                handleOptionChange={multiMasterAccountHandleChange}
                renderOption={renderMultiMasterAccountOption}
                isMockUpEnabled={isMockUpEnabled}
              />
            </div>
            <LineBreak />
            <div className="multi-listing-title-selector">
              <MultiListingTitleSelector
                dropdownClassName={dropdownClassName}
                onOptionChange={(listingTitle) => onFilters({ listingTitles: listingTitle.map((val) => val.title) })}
                setClear={() => onFilters({ listingTitles: [] })}
                tenants={filters.tenants}
                isCombineSelector
              />
            </div>
            <LineBreak />
            <LocationSelector reportsPage isCombineLeft removeSVG onSearch={handleLocation} loading={loading} />
            <ButtonIcon
              icon={magnifyBlackSVG}
              loading={loading}
              disabled={loading}
              fetchData={getReports}
              withinSearchBar
            />
          </Space>
        </InputsContainer>
      )}
      {(isMobile || isTablet) && (
        <MobileInputsContainer tablet={isTablet}>
          <Row gutter={[8, 8]} justify={isMobile ? 'start' : 'space-between'} align="middle">
            <Col xs={24} sm={24} md={24}>
              <CustomDateRangePicker
                startDate={startDate}
                endDate={endDate}
                onChangeDate={handleChangeDate}
                calendarRanges={calendarRanges}
              />
            </Col>
            <Col xs={24} sm={24} md={24}>
              <MultiTenantSelector
                onOptionsChange={(tenants) => onFilters({ tenants })}
                onClear={() => onFilters({ tenants: [] })}
                isCombineRight
                longerWidth
                dropdownClassName={dropdownClassName}
              />
            </Col>
            <Col xs={24} sm={24} md={24}>
              <ReusableSelector
                maxTagCount={0}
                showArrow={false}
                showSearch={true}
                isSingle={false}
                showMagnifySVG={false}
                isDebounceFetcher={true}
                combineType="both"
                fieldForValue="id"
                placeholder={accountNamesText}
                defaultValues={filters.masterAccounts}
                dropdownClassName={dropdownClassName}
                onClear={() => onFilters({ masterAccounts: [] })}
                onOptionsChange={(opts: MasterAccountRef[]) => handleMasterAccountSelection(opts)}
                debounceFetcher={multiMasterAccountDebounceFetcher}
                handlePlaceholder={multiMasterAccountHandlePlaceholder}
                handleValue={multiMasterAccountHandleValue}
                handleOptionChange={multiMasterAccountHandleChange}
                renderOption={renderMultiMasterAccountOption}
                isMockUpEnabled={isMockUpEnabled}
              />
            </Col>
            <Col xs={24} sm={24} md={24}>
              <div className="multi-listing-title-selector">
                <MultiListingTitleSelector
                  dropdownClassName={dropdownClassName}
                  onOptionChange={(listingTitle: ListingRef[]) =>
                    onFilters({ listingTitles: listingTitle.map((val) => val.title) })
                  }
                  setClear={() => onFilters({ listingTitles: [] })}
                  tenants={filters.tenants}
                  isCombineSelector
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <LocationSelector reportsPage isCombineLeft onSearch={handleLocation} loading={loading} />
            </Col>
            <div className="button-row">
              <ButtonModal size="large" click={getReports} icon={magnifyBlackSVG} text={'Search'} type="primary" />
            </div>
          </Row>
        </MobileInputsContainer>
      )}
    </>
  )
}

export default ReportsSelectors

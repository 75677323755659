// GlossaryDrawer.tsx
import { Descriptions, Drawer } from 'antd'

import { renderFormatMessage } from 'helpers/intl'
import { GlossaryItem, ReportDisclaimer } from 'models/reports'

interface GlossaryDrawerProps {
  visible: boolean
  glossary: GlossaryItem[]
  disclaimer?: ReportDisclaimer
  title: string
  onClose: () => void
}

export const GlossaryDrawer: React.FC<GlossaryDrawerProps> = ({ visible, glossary, title, onClose, disclaimer }) => {
  const renderTitle = (title: string) => {
    return (
      <>
        {renderFormatMessage('dashboard.text.glossaryFor', 'Glossary for')} {title}
      </>
    )
  }
  return (
    <Drawer width={650} visible={visible} closable={false} onClose={onClose}>
      <Descriptions title={renderTitle(title)} bordered column={1}>
        <Descriptions.Item
          label={<div className="heading-06-regular">{renderFormatMessage('dashboard.text.heading', 'Heading')}</div>}
        >
          <div className="heading-06-regular">Description</div>
        </Descriptions.Item>
        {glossary.map((item, index) => (
          <Descriptions.Item label={item.label} key={index}>
            {item.description}
          </Descriptions.Item>
        ))}
      </Descriptions>
      {disclaimer && (
        <div style={{ marginTop: '20px' }}>
          <p className="heading-06-bold-normal-opacity">{disclaimer?.title}</p>
          <p className="paragraph-06-regular">{disclaimer?.description}</p>
        </div>
      )}
    </Drawer>
  )
}
